const PDFView = ({PDFpath, modalClose}) => {
    return (
        <div onContextMenu={e => e.preventDefault()} className="main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster pt-48 lg:pt-20" style={{display: 'none', background: 'rgba(0,0,0,.7)'}}>
            <div className="border border-blue-500 shadow-lg modal-container lg:ml-72 bg-white w-11/12 lg:w-3/4 mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
                <div className="modal-content py-4 text-left px-6">
                    <div className="flex justify-between items-center pb-3">
                        <p className="text-2xl font-bold text-gray-500">Lesson PDF</p>
                        <div className="modal-close cursor-pointer z-50" onClick={() => modalClose('main-modal')}>
                            <svg className="fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                viewBox="0 0 18 18">
                                <path
                                    d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                </path>
                            </svg>
                        </div>
                    </div>
                    <div className="my-5 mx-0 flex justify-center">
                        <object data={PDFpath} type="application/pdf" width="100%" height="500">
                            <p>Alternative text - include a link <a href={PDFpath}>to the PDF!</a></p>
                        </object>
                    </div>
                    <div className="flex justify-end pt-2 space-x-14">
                        <button
                            className="px-4 bg-gray-200 p-3 rounded text-black hover:bg-gray-300 font-semibold" onClick={() => modalClose('main-modal')}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PDFView;