import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthNavbar from "../../../../components/AuthNavbar";
import Swal from "sweetalert2";
import axios from "axios";
function Courses({ _token, fetchAllCourses, _user, _allCourses, BASE_URL }) {
    const dispatch = useDispatch();

    const [axiosInMotion, setAxiosInMotion] = useState(false);
    const [coursesToDelete, setCoursesToDelete] = useState([]);

    const _userCourses = useMemo(() => {
        if (_allCourses.length > 0) {
            return _allCourses.filter(course => {
                return course.attributes.instructor.id.toString() === _user.id.toString()
            });
        } else {
            return [];
        }
    }, [_user, _allCourses]);

    const updateCoursesToDelete = courseUID => {
        let currentCoursesToDelete = [...coursesToDelete], index = currentCoursesToDelete.indexOf(courseUID);
        if (index === -1) {
            currentCoursesToDelete.splice(currentCoursesToDelete.length, 0, courseUID);
        } else {
            currentCoursesToDelete.splice(index, 1);
        }
        setCoursesToDelete(currentCoursesToDelete);
    }

    const checkAllCourses = allChecked => {
        if (allChecked) {
            setCoursesToDelete([]);
        } else {
            let currentCoursesToDelete = _userCourses.map(course => {
                return course.id
            });
            setCoursesToDelete(currentCoursesToDelete);
        }
    }

    const deleteCoursePrompt = (courseUID, courseName) => {
        Swal.fire({
            title: `Please confirm you want to delete this course - ${courseName}?`,
            showCancelButton: true,
            // confirmButtonColor: "#3085d6 !important",
            confirmButtonText: `Delete`,
            confirmButtonColor: '#002979',
        })
            .then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setAxiosInMotion(true);
                    deleteCourse(courseUID);
                }
            });
    }

    const deleteSelectedCoursesPrompt = () => {
        Swal.fire({
            title: `Please confirm you want to delete the selected courses`,
            showCancelButton: true,
            // confirmButtonColor: "#3085d6 !important",
            confirmButtonText: `Delete`,
            confirmButtonColor: '#002979',
        })
            .then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setAxiosInMotion(true);
                    deleteSelectedCourses();
                }
            });
    }

    const deleteCourse = async courseUID => {
        let FORMDATA = new FormData();
        FORMDATA.append('course_id', courseUID);
        try {
            await axios.post(`${BASE_URL}courses/delete`, FORMDATA, {
                headers: {
                    Authorization: `Bearer ${_token}`
                }
            });
            dispatch(fetchAllCourses({ token: _token, BASE_URL }));
            Swal.fire({
                title: 'Success',
                text: 'The course was deleted successfully',
                icon: 'success',
                confirmButtonColor: '#002979',
            });
            setAxiosInMotion(false);
        } catch (error) {
            let errorMsg = error.response.data ? error.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Course deletion failed',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
            setAxiosInMotion(false);
        }
    }

    const deleteSelectedCourses = () => {
        coursesToDelete.forEach((courseUID, index) => {
            deleteCourse(courseUID);
            if ((coursesToDelete.length - 1) === index) {
                setCoursesToDelete([]);
            }
        });
    }

    const viewCoverImage = (courseTitle, coverImage) => {
        Swal.fire({
            title: courseTitle,
            text: 'Cover image',
            imageUrl: coverImage,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: `${courseTitle} cover image`,
        });
    }

    useEffect(() => {
        dispatch(fetchAllCourses({ token: _token, BASE_URL }));
    });

    useEffect(() => {
        document.title = 'Courses - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthNavbar />
            <div className="pt-24 relative z-10 mx-auto">
                <div
                    className="px-0 py-2 xl:ml-72 xl:mr-10 mr-5 ml-5 z-20"
                >
                    <div className="flex flex-wrap">
                        <div className="w-1/2 lg:w-full lg:px-10">
                            <h2 className="text-lg lg:text-2xl font-bold mb-2">Your Courses</h2>
                        </div>
                        <div className="w-1/2 lg:hidden lg:w-full text-right">
                            <Link to="/dashboard/courses/add">
                                <button className="auth-orange-button py-2 px-4 text-sm">
                                    <i className="main-text far fa-plus-circle text-sm">
                                    </i> Add Course
                                </button>
                            </Link>
                        </div>
                    </div>
                    {
                        coursesToDelete.length
                            ?
                            <button onClick={() => !axiosInMotion && deleteSelectedCoursesPrompt()} className="mt-10 lg:mt-0 bg-danger text-white py-2 px-4 mb-2 text-sm">
                                <i className="far fa-trash text-sm">
                                </i> Delete Courses
                            </button>
                            :
                            null
                    }
                    <div className="my-2 lg:mr-5 relative overflow-x-scroll" style={_userCourses.length === 0 ? { height: '80vh' } : null}>
                        {
                            _userCourses.length >= 1
                            ?
                            <table className="w-full text-sm text-left">
                                <thead className="text-xs uppercase">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input checked={_userCourses.length === coursesToDelete.length && coursesToDelete.length > 0} onChange={() => checkAllCourses(_userCourses.length === coursesToDelete.length)} disabled={!!_userCourses === false || _userCourses.length === 0} id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Title
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Description
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Category
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Actual Price
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Discount Price
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Period of Completion
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Number of Subscribers
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Number of Views
                                        </th>
                                        {/* <th scope="col" className="px-6 py-3">
                                            <span className="sr-only">Image</span>
                                        </th> */}
                                        <th scope="col" className="px-6 py-3">
                                            <span className="sr-only">Lessons</span>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _userCourses.length > 0 && _userCourses.map((course, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className="bg-white border-b">
                                                    <td className="w-4 p-4">
                                                        <div className="flex items-center">
                                                            <input checked={coursesToDelete.indexOf(course.id) !== -1} onChange={() => updateCoursesToDelete(course.id)} id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                            <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {course.attributes.title}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {course.attributes.description}
                                                    </td>
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                        {course.attributes.category.name}
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        {'#' + course.attributes.actual_price}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {
                                                            !!course.attributes.discount_price
                                                                ?
                                                                '#' + course.attributes.discount_price
                                                                :
                                                                (
                                                                    <i>No discount</i>
                                                                )
                                                        }
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {course.attributes.period_of_completion}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {course.attributes.subscribers_count}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {course.attributes.view_count}
                                                    </td>
                                                    {/* <td className="px-6 py-4 text-right">
                                                        <span onClick={() => viewCoverImage(course.attributes.title, course.attributes.cover_image)} className="pointer font-medium text-blue-600 dark:text-blue-500 hover:underline">Cover image</span>
                                                    </td> */}
                                                    <td className="px-6 py-4 text-right">
                                                        <Link to={`/dashboard/courses/${course.id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                            View Lessons
                                                        </Link>
                                                    </td>
                                                    <td className="px-6 py-4 text-right">
                                                        <Link to={`/dashboard/courses/${course.id}/edit`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                            Edit
                                                        </Link>
                                                    </td>
                                                    <td className="px-6 py-4 text-right">
                                                        <span style={axiosInMotion ? { cursor: 'default', opacity: '0.3' } : null} onClick={() => !axiosInMotion && deleteCoursePrompt(course.id, course.attributes.title)} className="pointer font-medium text-red-600 dark:text-red-500 hover:underline">Delete</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <div className="absolute" style={{
                                top: '50%', 
                                left: '50%', 
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <h3>No courses added</h3>
                            </div>
                        }
                        
                    </div>

                </div>
            </div>
        </>
    );
}

export default Courses;