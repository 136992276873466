import InstructorImg from '../assets/instructor-image.png'
import InstructorCourseImg from '../assets/instructor-course.png'
import { Link } from 'react-router-dom';
function CourseCard({sub, count, userIsInstructor}) {
    return (
        (
            userIsInstructor
            ?
            <div
                className="bg-white rounded-xl lg:mx-10 my-5 relative p-6 lg:p-8"
            >
                <img 
                className="absolute rounded-full border-4 top-4 left-4 course-card-instructor-image"
                src={sub.attributes.instructor.image}
                alt="course-instructor" />
                <div className="absolute bg-white text-black rounded-md p-1 mt-2 right-0 mr-10 course-card-tagline">
                    Course No. {count}
                </div>
                <img
                className="mx-auto rounded-xl"
                style={{
                    height: 250,
                    width: '-webkit-fill-available'
                }}
                src={sub.attributes.cover_image}
                alt="instructor-course.png"
                />
                <div className="mt-4 text-center relative">
                    <div>
                        <span className="font-bold text-black text-lg">{sub.attributes.title}</span>
                    </div>
                    <div>
                        <span className="text-md card-grey-text">{
                            sub.attributes.instructor.first_name
                            + ' ' +
                            sub.attributes.instructor.last_name
                        }</span>
                    </div>
                    <div className="card-footer-text flex flex-wrap py-2 mt-2 rounded-b-xl items-center">
                        <div className="w-3/4">
                            <span className="card-grey-text">Manage Course</span>
                        </div>
                        <div className="w-1/4 cursor-pointer">
                            <Link to={`/dashboard/courses/${sub.id}`}>
                                <i className="fas fa-arrow-right text-4xl"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div
                className="bg-white rounded-xl lg:mx-10 my-5 relative p-6 lg:p-8"
            >
                <img 
                className="absolute rounded-full border-4 top-4 left-4 course-card-instructor-image"
                src={sub.attributes.instructor.image}
                alt="course-instructor" />
                <div className="absolute bg-white text-black rounded-md p-1 mt-2 right-0 mr-10 course-card-tagline">
                    Course No. {count}
                </div>
                <img
                className="mx-auto rounded-xl"
                style={{
                    height: 250,
                    width: '-webkit-fill-available'
                }}
                src={sub.attributes.course.cover_image}
                alt="instructor-course.png"
                />
                <div className="mt-4 text-center relative">
                    <div>
                        <span className="font-bold text-black text-lg">{sub.attributes.course.title}</span>
                    </div>
                    <div>
                        <span className="text-md card-grey-text">{
                            sub.attributes.instructor.first_name
                            + ' ' +
                            sub.attributes.instructor.last_name
                        }</span>
                    </div>
                    <div className="card-footer-text flex flex-wrap py-2 mt-2 rounded-b-xl items-center">
                        <div className="w-3/4">
                            <span className="card-grey-text">Resume Course</span>
                        </div>
                        <div className="w-1/4 cursor-pointer">
                            <Link to={`/dashboard/course/${sub.attributes.course.id}`}>
                                <i className="fas fa-arrow-right text-4xl"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

export default CourseCard;