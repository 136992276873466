import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllCoursesAPI, fetchCourseCategoriesAPI } from './coursesAPI';

const initialState = {
  courseCategories: localStorage.getItem('courseCategories') || '[]',
  allCourses: localStorage.getItem('allCourses') || '[]',
  status: 'idle'
};

export const fetchAllCourses = createAsyncThunk(
    'counter/fetchAllCourses',
    async (payload) => {
        const RESPONSE = await fetchAllCoursesAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return JSON.stringify(RESPONSE.data.data);
    }
);

export const fetchCourseCategories = createAsyncThunk(
    'counter/fetchCourseCategories',
    async (payload) => {
        const RESPONSE = await fetchCourseCategoriesAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return JSON.stringify(RESPONSE.data.data);
    }
);

export const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        storeLogout: (state) => {
            state.courseCategories = '[]';
            localStorage.setItem('courseCategories', '[]');
            state.allCourses = '[]';
            localStorage.setItem('allCourses', '[]');
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllCourses.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchAllCourses.fulfilled, (state, action) => {
            state.status = 'idle';
            state.allCourses = action.payload;
            localStorage.setItem('allCourses', action.payload);
        })
        .addCase(fetchCourseCategories.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchCourseCategories.fulfilled, (state, action) => {
            state.status = 'idle';
            state.courseCategories = action.payload;
            localStorage.setItem('courseCategories', action.payload);
        });
    }
});

export const { storeLogout } = coursesSlice.actions;

export const selectCourseCategories = state => state.courses.courseCategories;

export const selectAllCourses = state => state.courses.allCourses;

export default coursesSlice.reducer;
