import { useState, useEffect, useMemo } from "react";
import AuthNavbar from "../../../../components/AuthNavbar";
import PDFImage from "../../../../assets/PDF.svg"
import InstructorEllipse from "../../../../assets/Instructor-Ellipse.svg"
import Asker1 from "../../../../assets/Asker-1.svg"
import Asker2 from "../../../../assets/Asker-2.svg"
import Asker3 from "../../../../assets/Asker-3.svg"
import EmptyEllipse from "../../../../assets/Empty-Ellipse.svg"
import trophySVG from "../../../../assets/trophy.svg";
import './single-course.css';
import PDFView from "../../../../components/PDFView";
import Swal from "sweetalert2";
import axios from "axios";
import { useParams } from "react-router-dom";

function SingleCourse({_token, BASE_URL, navigate, _user}) {
    const { uid } = useParams();

    const [showingForum, setShowingForum] = useState(true);
    const [view, setView] = useState('forum');

    const [loadingEndpoint, setLoadingEndpoint] = useState(true);
    const [submittingQuiz, setSubmittingQuiz] = useState(false);
    const [submittingThread, setSubmittingThread] = useState(false);
    const [courseDetails, setCourseDetails] = useState({});
    const [currentLesson, setCurrentLesson] = useState({});
    const [courseLessons, setCourseLessons] = useState([]);
    const [courseThreads, setCourseThreads] = useState([]);
    const [quizResult, setQuizResult] = useState([]);
    const [generalQuizResult, setGeneralQuizResult] = useState([]);
    const [threadTitle, setThreadTitle] = useState('');
    const [threadBody, setThreadBody] = useState('');
    const [replyingToThread, setReplyingToThread] = useState({});
    const [replyingToTopTwoThread, setReplyingToTopTwoThread] = useState({});
    const [reply, setReply] = useState('');
    const [replies, setReplies] = useState([]);
    const [topTwoThreadsReplies, setTopTwoThreadsReplies] = useState([]);
    const [showingAddThreadView, setShowingAddThreadView] = useState(false);

    const lessonQuiz = useMemo(() => currentLesson?.attributes?.quiz);
    const correctAnswers = useMemo(() => quizResult.filter(result => result).length);
    const generalQuiz = useMemo(() => courseDetails?.attributes?.quiz);
    const generalQuizCorrectAnswers = useMemo(() => generalQuizResult.filter(result => result).length);

    const topTwoThreads = useMemo(() => {
        const threads = [...courseThreads];
        threads.sort((a, b) => (b.attributes.likes.length + b.attributes.replies.length) - (a.attributes.likes.length + a.attributes.replies.length));
        const finalVal = threads.slice(0, 2);
        return finalVal;
    });

    const modalClose = (modal) => {
        const modalToClose = document.querySelector('.'+modal);
        modalToClose.classList.remove('fadeIn');
        modalToClose.classList.add('fadeOut');
        setTimeout(() => {
            modalToClose.style.display = 'none';
        }, 500);
    }

    const openModal = (modal) => {
        const modalToOpen = document.querySelector('.'+modal);
        modalToOpen.classList.remove('fadeOut');
        modalToOpen.classList.add('fadeIn');
        modalToOpen.style.display = 'flex';
    }

    const checkForAnswer = (quizIndex, option) => {
        const newQuizResult = [...quizResult];
        if (lessonQuiz[quizIndex].answer === option) {
            newQuizResult[quizIndex] = true;
        } else {
            newQuizResult[quizIndex] = false;
        }
        setQuizResult(newQuizResult);
    }

    const checkForGeneralQuizAnswer = (quizIndex, option) => {
        const newQuizResult = [...generalQuizResult];
        if (lessonQuiz[quizIndex].answer === option) {
            newQuizResult[quizIndex] = true;
        } else {
            newQuizResult[quizIndex] = false;
        }
        setGeneralQuizResult(newQuizResult);
    }

    const startThreadResponse = thread => {
        setReplyingToThread(thread);
        getThreadReplies(thread.id)
    }

    const startTopTwoThreadResponse = thread => {
        setReplyingToTopTwoThread(thread);
        getTopTwoThreadReplies(thread.id)
    }

    const userHasLiked = likes => {
        return likes.filter(like => like.user_id == _user.id).length > 0;
    }

    const getThreadReplies = id => {
        setReplies([]);
        let FORMDATA = new FormData();
        FORMDATA.append('thread_id', id);
        axios.post(`${BASE_URL}courses/threads/replies`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setReplies(response.data.data.reverse());
        })
        .catch(() => {
            //
        })
        .then(() => {
            //
        });
    }

    const getTopTwoThreadReplies = id => {
        setTopTwoThreadsReplies([]);
        let FORMDATA = new FormData();
        FORMDATA.append('thread_id', id);
        axios.post(`${BASE_URL}courses/threads/replies`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setTopTwoThreadsReplies(response.data.data.reverse());
        })
        .catch(() => {
            //
        })
        .then(() => {
            //
        });
    }

    const getCourseDetails = () => {
        let FORMDATA = new FormData();
        FORMDATA.append('course_id', uid);
        axios.post(`${BASE_URL}courses/get`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setCourseDetails(response.data.data);
            getCourseLessons();
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Course details failed to fetch',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
            navigate('/dashboard', { replace: true });
        })
        .then(() => {
            //
        });
    }

    const getCourseLessons = () => {
        let FORMDATA = new FormData();
        FORMDATA.append('course_id', uid);
        axios.post(`${BASE_URL}courses/course-lessons`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setCourseLessons(response.data.data);
            getCurrentLesson(response.data.data[0].id)
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Course lessons failed to fetch',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
            navigate('/dashboard', { replace: true });
        })
        .then(() => {
            //
        });
    }

    const getCurrentLesson = (id, switchingLessons = false) => {
        let FORMDATA = new FormData();
        FORMDATA.append('course_lesson_id', id);
        if (switchingLessons) {
            setLoadingEndpoint(true);
        }
        axios.post(`${BASE_URL}courses/get-lesson`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setCurrentLesson(response.data.data);
            const quizRes = response.data.data.attributes.quiz.map(q => false);
            setQuizResult(quizRes);
            getCourseThreads();
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Course lesson failed to fetch',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
            navigate('/dashboard', { replace: true });
        })
        .then(() => {
            if (switchingLessons) {
                setLoadingEndpoint(false);
            }
        });
    }

    const getCourseThreads = () => {
        axios.get(`${BASE_URL}courses/${uid}/threads`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setLoadingEndpoint(false);
            setCourseThreads(response.data.data);
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Threads failed to fetch',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
            navigate('/dashboard', { replace: true });
        })
        .then(() => {
            //
        });
    }

    const submitQuiz = () => {
        setSubmittingQuiz(true);
        let FORMDATA = new FormData();
        FORMDATA.append('course_lesson_id', currentLesson.id);
        FORMDATA.append('total_correct_answers', correctAnswers);
        FORMDATA.append('total_questions', lessonQuiz.length);
        axios.post(`${BASE_URL}lesson/quiz-score`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setSubmittingQuiz(false);
            Swal.fire({
                title: response.data.percentage + '%',
                text: response.data.message,
                icon: 'success',
                confirmButtonColor: '#002979',
            });
        })
        .catch(err => {
            setSubmittingQuiz(false);
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Quiz result failed to save',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            //
        });
    }

    const submitGeneralQuiz = () => {
        setSubmittingQuiz(true);
        let FORMDATA = new FormData();
        FORMDATA.append('course_id', uid);
        FORMDATA.append('total_correct_answers', generalQuizCorrectAnswers);
        FORMDATA.append('total_questions', generalQuiz.length);
        axios.post(`${BASE_URL}courses/quiz-score`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setSubmittingQuiz(false);
            Swal.fire({
                title: response.data.percentage + '%',
                text: response.data.message,
                icon: 'success',
                confirmButtonColor: '#002979',
            });
        })
        .catch(err => {
            setSubmittingQuiz(false);
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Quiz result failed to save',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            //
        });
    }

    const submitThread = () => {
        setSubmittingThread(true);
        let FORMDATA = new FormData();
        FORMDATA.append('title', threadTitle);
        FORMDATA.append('body', threadBody);
        FORMDATA.append('course_id', uid);
        axios.post(`${BASE_URL}courses/threads/add-thread`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setSubmittingThread(false);
            setShowingAddThreadView(false);
            setThreadTitle('');
            setThreadBody('');
            getCourseThreads();
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonColor: '#002979',
            });
        })
        .catch(err => {
            setSubmittingThread(false);
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Thread failed to save',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            //
        });
    }

    const submitReply = () => {
        setSubmittingThread(true);
        let FORMDATA = new FormData();
        FORMDATA.append('thread_id', replyingToThread.id);
        FORMDATA.append('body', reply);
        axios.post(`${BASE_URL}courses/threads/add-reply`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            setReply('');
            document.getElementById('reply').value = '';
            getCourseThreads();
            getThreadReplies(replyingToThread.id);
            setSubmittingThread(false); // This serves as setSubmittingReply as well
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonColor: '#002979',
            });
        })
        .catch(err => {
            setSubmittingThread(false); // This serves as setSubmittingReply as well
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Reply failed to send',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            //
        });
    }

    const submitReplyLike = reply_id => {
        axios.post(`${BASE_URL}courses/threads/like-reply`, {
            reply_id
        }, {
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            getCourseThreads();
            getThreadReplies(replyingToThread.id);
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonColor: '#002979',
            });
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Like failed to save',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            //
        });
    }

    const submitThreadLike = thread_id => {
        axios.post(`${BASE_URL}courses/threads/like-thread`, {
            thread_id
        }, {
            headers: {
                'Accept': 'application/json',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            getCourseThreads();
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonColor: '#002979',
            });
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Like failed to save',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            //
        });
    }

    const deleteReply = id => {
        Swal.fire({
            title: `Please confirm you want to delete this reply`,
            showCancelButton: true,
            // confirmButtonColor: "#3085d6 !important",
            confirmButtonText: `Delete`,
            confirmButtonColor: '#002979',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let FORMDATA = new FormData();
                FORMDATA.append('reply_id', id);
                axios.post(`${BASE_URL}courses/threads/delete-reply`, FORMDATA, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${_token}`
                    }
                })
                .then(response => {
                    getCourseThreads();
                    getThreadReplies(replyingToThread.id);
                    Swal.fire({
                        title: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: '#002979',
                    });
                })
                .catch(err => {
                    let errorMsg = err.response.data ? err.response.data.message : null;
                    Swal.fire({
                        title: 'Error',
                        text: errorMsg || 'Reply failed to delete',
                        icon: 'error',
                        confirmButtonColor: '#002979',
                    });
                })
                .then(() => {
                    //
                });
            }
        });
    }

    useEffect(() => {
        document.title = 'Single Course - Lexstudy';
        window.scrollTo(0, 0);
        getCourseDetails();
    }, []);
    
    return (
        <>
            <AuthNavbar hideTop={false} />
            <div className="relative z-10 mx-auto">
                {
                    loadingEndpoint
                    ?
                    <div className="px-0 py-2 z-20">
                        <div>
                            <div className="flex" style={{height: '100vh', alignItems: 'center'}}>
                                <i style={{ color: '#002979' }} className="mx-auto far fa-spinner fa-spin fa-4x"></i>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="px-2 py-2 xl:ml-72 ml-0 z-20">
                        <div className="mt-20 mb-16 flex flex-wrap">
                            <div className="w-full lg:w-3/4 lg:pr-5">
                                <div className="mt-5">
                                    <nav aria-label="breadcrumb"> 
                                        <ol className="flex space-x-2">
                                        <li><a href="#" className="after:content-['/'] after:ml-2 text-gray-400 hover:text-black">Course</a></li>
                                        <li><a href="#" className="after:content-['/'] after:ml-2 text-gray-400 hover:text-black">{courseDetails.attributes.category.name}</a></li>
                                        <li className="text-black" aria-current="page">{courseDetails.attributes.title}</li> 
                                        </ol>
                                    </nav>
                                </div>
                                <div className="flex flex-wrap mt-5">
                                    <div className="w-3/4">
                                        <h2 className="text-2xl">{currentLesson?.attributes?.title}</h2>
                                        
                                        <PDFView PDFpath={currentLesson?.attributes?.pdf_note} modalClose={modalClose} />
                                    
                                    </div>
                                    <div className="w-1/4 text-right">
                                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                            <span className="hidden lg:block mr-2 text-gray-400">CHAPTER {currentLesson?.attributes?.lesson_order}/{courseLessons.length}</span>
                                            <span onClick={
                                                () => {
                                                    currentLesson.attributes.lesson_order > 1
                                                    &&
                                                    getCurrentLesson(courseLessons[currentLesson.attributes.lesson_order-2].id, true)
                                                }
                                            } className="pointer relative inline-flex items-center rounded-l-md border border-gray-300 hover:border-blue-300 bg-white px-1 text-sm font-medium text-gray-500 hover:text-white hover:bg-blue-500 focus:z-20">
                                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                                </svg>
                                            </span>
                                            <span onClick={
                                                () => {
                                                    currentLesson.attributes.lesson_order < courseLessons.length
                                                    &&
                                                    getCurrentLesson(courseLessons[currentLesson.attributes.lesson_order].id, true)
                                                }
                                            } className="pointer relative inline-flex items-center rounded-r-md border border-gray-300 hover:border-blue-300 bg-white px-1 text-sm font-medium text-gray-500 hover:text-white hover:bg-blue-500 focus:z-20">
                                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                                                </svg>
                                            </span>
                                        </nav>
                                    </div>
                                    <div className="w-full mb-5">
                                        <video className="w-full rounded-md mt-2" height="240" controls>
                                            <source poster="/logo-with-bg.png" src={currentLesson?.attributes?.video_content} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div className="w-full lg:w-3/4">
                                        <div>
                                            <div className="flex flex-wrap">
                                                <nav className="flex flex-wrap w-full lg:w-3/4">
                                                    <button onClick={() => setView('forum')} className={(currentLesson?.attributes?.lesson_order === courseLessons.length ? 'w-1/3' : 'w-1/2') + ' text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none ' + (view === 'forum' ? 'text-blue-500 border-b-2 font-bold border-red-500' : 'border-b-2 border-grey-500')}>
                                                        FORUM
                                                    </button>
                                                    <button onClick={() => setView('quiz')} className={(currentLesson?.attributes?.lesson_order === courseLessons.length ? 'w-1/3' : 'w-1/2') + ' w-1/2 text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none ' + (view === 'quiz' ? 'text-blue-500 border-b-2 font-bold border-red-500' : 'border-b-2 border-grey-500')}>
                                                        QUIZ
                                                    </button>
                                                    {
                                                        currentLesson?.attributes?.lesson_order === courseLessons.length
                                                        &&
                                                        <button onClick={() => setView('general_quiz')} className={'w-1/3 text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none ' + (view === 'general_quiz' ? 'text-blue-500 border-b-2 font-bold border-red-500' : 'border-b-2 border-grey-500')}>
                                                            GENERAL QUIZ
                                                        </button>
                                                    }
                                                </nav>
                                                <div className="mt-4 lg:mt-0 w-1/2 lg:w-1/4 text-center block lg:hidden" style={{alignSelf: 'center'}}>
                                                </div>
                                                {
                                                    view === 'forum'
                                                    &&
                                                    (
                                                        showingAddThreadView
                                                        ?
                                                        <div className="mt-4 lg:mt-0 w-1/2 lg:w-1/4 text-center" style={{alignSelf: 'center'}}>
                                                            <button onClick={() => setShowingAddThreadView(false)} type="button" className="auth-orange-button py-2 px-4 text-sm">
                                                                <i className="main-text far fa-messages text-sm">
                                                                </i> All Threads
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="mt-4 lg:mt-0 w-1/2 lg:w-1/4 text-center" style={{alignSelf: 'center'}}>
                                                            <button onClick={() => setShowingAddThreadView(true)} type="button" className="auth-orange-button py-2 px-4 text-sm">
                                                                <i className="main-text far fa-plus-circle text-sm">
                                                                </i> Add Thread
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            {
                                                view === 'forum'
                                                ?
                                                    showingAddThreadView
                                                    ?
                                                    <div className="mt-5 lg:pr-4">
                                                        <form>
                                                            <div className="flex flex-wrap mb-4 lg:mb-6">
                                                                <div className="w-full mb-4">
                                                                    <h2 className="text-xl">Create Thread</h2>
                                                                </div>
                                                                <div className="w-full flex flex-wrap mb-4">
                                                                    <div className="md:mx-4 w-full">
                                                                        <div className="w-full self-center mb-2">
                                                                            <label htmlFor="thread-title">Title </label>
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <input value={threadTitle} id="thread-title" className="w-full rounded-md indent-2 px-3 border py-2" type="text" onChange={e => setThreadTitle(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-full flex flex-wrap mb-4">
                                                                    <div className="md:mx-4 w-full">
                                                                        <div className="w-full self-center mb-2">
                                                                            <label htmlFor="thread-body">Body </label>
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <input value={threadBody} id="thread-body" className="w-full rounded-md indent-2 px-3 border py-2" type="text" onChange={e => setThreadBody(e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div className="flex flex-wrap lg:px-10">
                                                            <div className="hidden lg:w-1/3"></div>
                                                            <div className="w-full lg:w-1/3">
                                                                <button disabled={submittingThread} onClick={() => submitThread()} className={'text-uppercase auth-orange-button w-full p-2 rounded-xl text-white ' + (submittingThread && 'opacity-50')}>
                                                                    {
                                                                        submittingThread
                                                                            ?
                                                                            <i className="fas fa-loader fa-spin py-1"></i>
                                                                            :
                                                                            'Submit'
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    (
                                                        courseThreads.length > 0
                                                        ?
                                                        <div className="mt-5 lg:pr-4" style={courseThreads.length > 4 ? {height: '500px', overflowY: 'scroll'} : null}>
                                                            {
                                                                courseThreads.map((thread, index) => (
                                                                    <div id={`thread-${thread.id}`} className="flex flex-wrap mb-8 border-b-2 pb-2" key={'thread-'+index}>
                                                                        <div className="w-7/12 lg:1/2 flex flex-wrap">
                                                                            <div className="w-1/3">
                                                                                <img src={thread.attributes.user.image} style={{height: 40, width: 40}} className="w-full lg:w-3/4 rounded-full" alt="empty-ellipse" />
                                                                            </div>
                                                                            <div className="w-2/3 self-center">
                                                                                <span className="lg:text-lg">
                                                                                    {thread.attributes.user.first_name + ' ' + thread.attributes.user.last_name}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-5/12 lg:w-1/2 text-right self-center opacity-50">
                                                                            {
                                                                                thread.id === replyingToThread.id
                                                                                ?
                                                                                <div className="pointer text-xs lg:text-md" onClick={() => setReplyingToThread({})}>
                                                                                    <span>Hide Responses</span>
                                                                                    {/* <i className="fas fa-message ml-2"></i> */}
                                                                                </div>
                                                                                :
                                                                                <div className="pointer text-xs lg:text-md" onClick={() => startThreadResponse(thread)}>
                                                                                    <span>Show Responses ({thread.attributes.replies.length})</span>
                                                                                    {/* <i className="fas fa-message ml-2"></i> */}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <div>
                                                                                <span className="opacity-50 text-sm">{thread.attributes.title}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span>
                                                                                {thread.attributes.body}
                                                                                </span>
                                                                            </div>
                                                                            <div className="opacity-50 w-full text-right">
                                                                                {
                                                                                    userHasLiked(thread.attributes.likes)
                                                                                    ?
                                                                                    <i style={{
                                                                                        color: '#f00'
                                                                                    }} className="fas fa-heart pointer" onClick={() => submitThreadLike(thread.id)}></i>
                                                                                    :
                                                                                    <i className="far fa-heart pointer" onClick={() => submitThreadLike(thread.id)}></i>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {/* Replies */}
                                                                        {
                                                                            replies.length > 0
                                                                            &&
                                                                            replyingToThread.id === thread.id
                                                                            &&
                                                                            <div className="w-full border-l-4 ml-4 mt-4">
                                                                                <div className="w-full mt-0">
                                                                                    <div className="px-4" style={replies.length > 2 ? {height: '264px', overflowY: 'scroll'} : null}>
                                                                                        <div className="mb-2">
                                                                                            <span className="opacity-50 text-sm">Replies</span>
                                                                                        </div>
                                                                                            {
                                                                                                replies.map((reply, index) => (
                                                                                                    <div key={'reply-'+index} className={`${index > 0 && 'pt-2'} ${index+1 < thread.attributes.replies.length && 'border-b-2'} flex flex-wrap ml-2`}>
                                                                                                        <div className="w-full flex flex-wrap">
                                                                                                            <div className="w-1/6 self-center">
                                                                                                                <img src={reply.attributes.user.image} style={{height: 40, width: 40}} className="w-2/3 rounded-full" alt="empty-ellipse" />
                                                                                                            </div>
                                                                                                            <div className="w-5/6 self-center">
                                                                                                                <span>
                                                                                                                    {
                                                                                                                        `${reply.attributes.user.first_name} ${reply.attributes.user.last_name}`
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="w-full pr-36">
                                                                                                            <span className="opacity-50 text-sm">
                                                                                                            {reply.attributes.body}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="opacity-50 w-full text-right">
                                                                                                            {
                                                                                                                userHasLiked(reply.attributes.likes)
                                                                                                                ?
                                                                                                                <i style={{
                                                                                                                    color: '#f00'
                                                                                                                }} className="fas fa-heart pointer" onClick={() => submitReplyLike(reply.id)}></i>
                                                                                                                :
                                                                                                                <i className="far fa-heart pointer" onClick={() => submitReplyLike(reply.id)}></i>
                                                                                                            }
                                                                                                            {
                                                                                                                reply.attributes.user.id === _user.id
                                                                                                                &&
                                                                                                                <i onClick={() => deleteReply(reply.id)} className="fas fa-trash pointer ml-2"></i>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {/* Replies */}
                                                                        {
                                                                            thread.id == replyingToThread.id
                                                                            &&
                                                                            <div className="flex flex-wrap w-full">
                                                                                <div className="border-gray-200 px-4 pt-4 mb-2 sm:mb-0 w-full">
                                                                                    {
                                                                                        replyingToThread?.attributes
                                                                                        &&
                                                                                        <div>
                                                                                            <span>Replying to {replyingToThread.attributes.title}</span>
                                                                                        </div>
                                                                                    }
                                                                                    <div className="relative flex">
                                                                                        <textarea id="reply" rows={1} onKeyUp={e => setReply(e.target.value)} placeholder="Write your message!" className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-5 pr-24 bg-gray-200 rounded-md py-3"></textarea>
                                                                                        {/* <input type="text" placeholder="Write your message!" className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3" /> */}
                                                                                        <div className="absolute right-0 items-center inset-y-0">
                                                                                            
                                                                                            <button onClick={() => (submittingThread || replyingToThread?.attributes === undefined ? null : submitReply())} type="button" className="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white focus:outline-none" style={submittingThread ? {background: '#002979', opacity: 0.75} : {background: '#002979'}}>
                                                                                            <span className="hidden lg:block font-bold">
                                                                                                {
                                                                                                    submittingThread
                                                                                                    ?
                                                                                                    `Sending`
                                                                                                    :
                                                                                                    `Send`
                                                                                                }
                                                                                            </span>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`${submittingThread ? 'fa-spin' : ''} h-6 w-6 lg:ml-2 transform rotate-90`}>
                                                                                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                                                                            </svg>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        :
                                                        <div className="mt-5 lg:pr-4">
                                                            <h3 className="text-lg">
                                                                No threads added
                                                            </h3>
                                                        </div>
                                                    )
                                                :
                                                view === 'quiz'
                                                ?
                                                    <>
                                                        {
                                                            lessonQuiz.length > 0
                                                            ?
                                                            (
                                                                lessonQuiz.map((quiz, index) => (
                                                                    <div className="mt-5 lg:pr-4" key={index}>
                                                                        <h2 className="text-lg font-bold opacity-90">{`${index+1}. ${quiz.question}`}</h2>
                                                                        <fieldset className="my-4">
                                                                            <legend className="sr-only">
                                                                                {`${index+1}. ${quiz.question}`}
                                                                            </legend>

                                                                            {
                                                                                quiz.options.map((option, i) => (
                                                                                    <div className="flex items-center mb-4" key={i}>
                                                                                        <input onClick={() => checkForAnswer(index, option)} id={`option-${i+1}`} type="radio" name={`question-${index+1}`} value={option} className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300" aria-labelledby={`option-${i+1}`} aria-describedby={`option-${i+1}`} />
                                                                                        <label htmlFor={`option-${i+1}`} className="text-sm font-medium text-gray-900 ml-2 block">
                                                                                            {option}
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </fieldset>
                                                                    </div>
                                                                ))
                                                            )
                                                            :
                                                            <div className="mt-5 lg:pr-4">
                                                                <h3 className="text-lg">
                                                                    No lesson quiz
                                                                </h3>
                                                            </div>
                                                        }
                                                        
                                                        {
                                                            lessonQuiz.length > 0
                                                            &&
                                                            <div className="border-t-0 mb-5 border-gray-50 lg:pr-4">
                                                                <div className="flex flex-wrap">
                                                                    <div className="hidden lg:w-1/3"></div>
                                                                    <div className="w-full lg:w-1/3">
                                                                        <button onClick={() => submitQuiz()} className="border-2 border-blue-500 text-blue-500 py-3 px-5 rounded-lg w-full">
                                                                            {
                                                                                submittingQuiz
                                                                                ?
                                                                                <i className="fas fa-spinner fa-spin"></i>
                                                                                :
                                                                                `Submit Result`
                                                                            }
                                                                            
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                :
                                                <>
                                                    {
                                                        generalQuiz.length > 0
                                                        ?
                                                        (
                                                            generalQuiz.map((quiz, index) => (
                                                                <div className="mt-5 lg:pr-4" key={index}>
                                                                    <h2 className="text-lg font-bold opacity-90">{`${index+1}. ${quiz.question}`}</h2>
                                                                    <fieldset className="my-4">
                                                                        <legend className="sr-only">
                                                                            {`${index+1}. ${quiz.question}`}
                                                                        </legend>

                                                                        {
                                                                            quiz.options.map((option, i) => (
                                                                                <div className="flex items-center mb-4" key={i}>
                                                                                    <input onClick={() => checkForGeneralQuizAnswer(index, option)} id={`option-${i+1}`} type="radio" name={`question-${index+1}`} value={option} className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300" aria-labelledby={`option-${i+1}`} aria-describedby={`option-${i+1}`} />
                                                                                    <label htmlFor={`option-${i+1}`} className="text-sm font-medium text-gray-900 ml-2 block">
                                                                                        {option}
                                                                                    </label>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </fieldset>
                                                                </div>
                                                            ))
                                                        )
                                                        :
                                                        <div className="mt-5 lg:pr-4">
                                                            <h3 className="text-lg">
                                                                No general quiz
                                                            </h3>
                                                        </div>
                                                    }
                                                    
                                                    {
                                                        generalQuiz.length > 0
                                                        &&
                                                        <div className="border-t-0 mb-5 border-gray-50 lg:pr-4">
                                                            <div className="flex flex-wrap">
                                                                <div className="hidden lg:w-1/3"></div>
                                                                <div className="w-full lg:w-1/3">
                                                                    <button onClick={() => submitGeneralQuiz()} className="border-2 border-blue-500 text-blue-500 py-3 px-5 rounded-lg w-full">
                                                                        {
                                                                            submittingQuiz
                                                                            ?
                                                                            <i className="fas fa-spinner fa-spin"></i>
                                                                            :
                                                                            `Submit Result`
                                                                        }
                                                                        
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-1/4 mt-0 lg:mt-0">
                                        <div className="lg:mx-2">
                                            <div onClick={() => openModal('main-modal')} className="pointer py-2 px-2 bg-gray-200 flex flex-wrap">
                                                <div className="w-5/6">
                                                    <span className="opacity-70 text-sm">Course Note</span>
                                                </div>
                                                <div className="w-1/6">
                                                    <img src={PDFImage} alt="Course PDF" />
                                                </div>
                                            </div>
                                            <div className="mt-2 py-2 px-2 bg-gray-200 flex flex-wrap">
                                                <div className="w-full mb-2">
                                                    <h3 className="text-xl font-bold">About Instructor</h3>
                                                </div>
                                                <div className="w-1/4">
                                                    <img 
                                                    className="rounded-full"
                                                    src={courseDetails.attributes.instructor.image} 
                                                    alt="Instructor Ellipse" 
                                                    style={{
                                                        width: '50px',
                                                        height: '50px'
                                                    }}
                                                    />
                                                </div>
                                                <div className="w-3/4 mb-2">
                                                    <div className="text-center">
                                                        <span>{`${courseDetails.attributes.instructor.first_name} ${courseDetails.attributes.instructor.last_name}`}</span>
                                                    </div>
                                                    <div className="text-center">
                                                        <span className="opacity-50 text-sm">{courseDetails.attributes.instructor.qualification}</span>
                                                    </div>
                                                </div>
                                                <div className="w-full">
                                                    <span className="opacity-50 text-sm">
                                                        {courseDetails.attributes.instructor.introduction_brief}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/4 px-2 mt-4 lg:mt-0" style={{background: '#F8F8F8'}}>
                                <div className="lg:p-4">
                                    <div className="my-2">
                                        <h3 className="text-lg">
                                            Course: {courseDetails.attributes.title}
                                        </h3>
                                    </div>
                                    <div className="flex flex-wrap">
                                        <div className="w-3/4">
                                            <span className="mr-2 text-gray-400">{currentLesson?.attributes?.lesson_order}/{courseLessons.length} COMPLETED</span>
                                        </div>
                                        <div className="w-1/4" style={{textAlign: '-webkit-right'}}>
                                            <img src={trophySVG} alt="completion-trophy" />
                                        </div>
                                        <div className="w-full flex flex-nowrap">
                                            {
                                                courseLessons.map((lesson, index) => (
                                                    <div 
                                                    key={index}
                                                    className="inline-block" 
                                                    style={{
                                                        height: '4px', 
                                                        background: currentLesson?.attributes?.lesson_order >= index+1 ? '#13C57C' : '#C4C4C4', 
                                                        width: `${(1/courseLessons.length)*100}%`, margin: '2px'
                                                    }}></div>
                                                ))
                                            }
                                        </div>
                                        <div className="w-full mt-2">
                                            {
                                                courseLessons.map((lesson, index) => (
                                                    <div key={index} className="flex flex-wrap mt-2">
                                                        {
                                                            currentLesson.id === lesson.id
                                                            ?
                                                            (
                                                                <>
                                                                    <div className="w-1/12">
                                                                        <div style={{height: 24, borderColor: '#C4C4C4'}} className="border-2 text-sm text-center rounded-full">{index+1}</div>
                                                                    </div>
                                                                    <div className="w-10/12">
                                                                        <span style={{color: '#6568F7'}} className="ml-2 text-sm">{lesson.title}</span>
                                                                    </div>
                                                                    <div className="w-1/12">
                                                                        <i style={{color: '#6568F7'}} className="fas fa-play"></i>
                                                                    </div>
                                                                </>
                                                            )
                                                            :
                                                            currentLesson?.attributes?.lesson_order >= index+1
                                                            ?
                                                            (
                                                                <>
                                                                    <div className="w-1/12">
                                                                        <div style={{height: 24, borderColor: '#13C57C'}} className="border-2 text-sm text-center rounded-full">{index+1}</div>
                                                                    </div>
                                                                    <div className="w-10/12">
                                                                        <span className="ml-2 text-sm text-black">{lesson.title}</span>
                                                                    </div>
                                                                    <div className="w-1/12">
                                                                    </div>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <>
                                                                    <div className="w-1/12">
                                                                        <div style={{height: 24, borderColor: '#C4C4C4'}} className="border-2 text-sm text-center rounded-full">{index+1}</div>
                                                                    </div>
                                                                    <div className="w-10/12">
                                                                        <span className="text-gray-400 ml-2 text-sm">{lesson.title}</span>
                                                                    </div>
                                                                    <div className="w-1/12">
                                                                        <i className="text-gray-400 fas fa-lock-keyhole"></i>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-10">
                                        <h3 className="text-lg">
                                            Top Threads
                                        </h3>
                                    </div>

                                    {
                                        topTwoThreads.length > 0
                                        ?
                                        topTwoThreads.map((thread, index) => (
                                            <div key={'thread-'+index} className="flex flex-wrap mb-2 py-4 border-b-2">
                                                <div className="w-1/6">
                                                    <img src={thread.attributes.user.image} style={{height: 40, width: 40}} className="w-3/4 rounded-full" alt="empty-ellipse" />
                                                </div>
                                                <div className="w-5/6 self-center">
                                                    <span className="text-base">
                                                        {
                                                            `
                                                                ${thread.attributes.user.first_name} 
                                                                ${thread.attributes.user.last_name}
                                                            `
                                                        }
                                                    </span>
                                                </div>
                                                <div className="w-full">
                                                    <p className="text-gray-500 text-sm">{thread.attributes.body}</p>
                                                </div>
                                                <div className="text-gray-500 w-full flex flex-wrap pt-8">
                                                    {
                                                        thread.id === replyingToTopTwoThread.id
                                                        ?
                                                        <>
                                                            <div className="w-1/3">
                                                                <div className="self-center pointer">
                                                                    <a href={`#thread-${thread.id}`} onClick={() => startThreadResponse(thread)}>
                                                                        <i className="fas fa-reply mr-2"></i> 
                                                                        <span className="text-xs">Reply</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="w-2/3">
                                                                <div className="pointer text-right" onClick={() => setReplyingToTopTwoThread({})}>
                                                                    <span className="text-xs">Hide responses</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="w-1/3">
                                                                <div className="self-center pointer">
                                                                    <a href={`#thread-${thread.id}`} onClick={() => startThreadResponse(thread)}>
                                                                        <i className="fas fa-reply mr-2"></i> 
                                                                        <span className="text-xs">Reply</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="w-2/3">
                                                                <div className="pointer text-right" onClick={() => startTopTwoThreadResponse(thread)}>
                                                                    <span className="text-xs">Show responses ({thread.attributes.replies.length})</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                                {
                                                    topTwoThreadsReplies.length > 0
                                                    &&
                                                    replyingToTopTwoThread.id === thread.id
                                                    &&
                                                    <div className="flex flex-wrap border-l-4 ml-2 pl-4 pt-2 mt-2" style={topTwoThreadsReplies.length > 2 ? {height: '264px', overflowY: 'scroll'} : null}>
                                                        {
                                                            topTwoThreadsReplies.map((reply, index) => (
                                                                <div key={'top-two-reply-'+index} className={`${index+1 === topTwoThreadsReplies.length ? null : 'border-b-2'} w-full flex flex-wrap py-2`}>
                                                                    <div className="w-1/6">
                                                                        <img src={reply.attributes.user.image} style={{height: 40, width: 40}} className="w-3/4 rounded-full" alt="empty-ellipse" />
                                                                    </div>
                                                                    <div className="w-5/6 self-center">
                                                                        <span className="text-base ml-1">
                                                                            {
                                                                                `${reply.attributes.user.first_name} ${reply.attributes.user.last_name}`
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="w-full">
                                                                        <p className="text-gray-500 text-sm">
                                                                            {reply.attributes.body}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        ))
                                        :
                                        <div className="mt-5 lg:pr-4">
                                            <h3 className="text-md">
                                                No threads added
                                            </h3>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default SingleCourse;