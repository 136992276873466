import AuthNavbar from "../../../components/AuthNavbar";
import CourseCard from "../../../components/AuthCourseCard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
function Dashboard({BASE_URL, fetchAllCourses, _token, userIsInstructor, fetchCourseCategories, fetchSubscriptionHistory, _selectSubscriptionHistory}) {
	const dispatch = useDispatch();
	
    useEffect(() => {
        dispatch(fetchAllCourses({token: _token, BASE_URL}));
        dispatch(fetchSubscriptionHistory({token: _token, BASE_URL}));
		dispatch(fetchCourseCategories({BASE_URL}));
    });

    useEffect(() => {
        document.title = 'Dashboard - Lexstudy';
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <AuthNavbar />
            <div className="pt-16 lg:pt-24 relative z-10 mx-auto">
                <div
                className="px-0 py-2 xl:ml-72 xl:mr-10 mr-5 ml-5 z-20"
                >
                    <div className="flex flex-wrap mt-6 mb-4 lg:mr-5">
                        <div className="w-full lg:px-10">
                            <h2 className="text-2xl font-bold">Your Courses</h2>
                        </div>
                        {
                            _selectSubscriptionHistory.map((sub, index) => {
                                return (
                                    <div key={index} className="w-full sm:w-1/3">
                                        <CourseCard 
                                        userIsInstructor={userIsInstructor}
                                        sub={sub} 
                                        count={index+1} 
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;