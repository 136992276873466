import { useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
// import CarouselItem from "../../components/CarouselItem";
// import CourseCard from "../../components/CourseCard";
import youngstersImage from "../../../assets/youngsters.png";
// import OwlCarousel from 'react-owl-carousel';
import Swal from "sweetalert2";
import axios from "axios";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { useMemo, useState } from "react";
import './checkout.css';
function Checkout({
    navigate, 
    loggedIn, 
    fetchCourseCategories,
    fetchAllCourses, 
    _courseCategories, 
    _allCourses,
    _token,
    _user,
    BASE_URL,
    fetchSubscriptionHistory
}) {
    const { courseId } = useParams();
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const currentCourse = useMemo(() => {
        const filteredCourse = _allCourses.filter(course => course.id == courseId);
        const [value] = filteredCourse;
        return value;
    }, courseId);

    useEffect(() => {
        dispatch(fetchAllCourses({ BASE_URL }));
        dispatch(fetchCourseCategories({ BASE_URL }));
        document.title = 'Checkout ['+currentCourse.attributes.title+'] - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Navbar loggedIn={loggedIn} />
        <div className="pt-10 lg:pt-24 bodyBluish">
            <div className="flex flex-wrap px-10">
                <div className="w-full lg:w-1/2">
                    <div className="px-0 lg:px-10 py-20">
                        <h1 style={{fontSize: '3rem'}} className="font-bold text-white">Learn Confidently </h1>
                        <p className="text-white mt-3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Turpis gravida lectus sem ut nulla pretium platea. 
                            In orci, id felis urna, ullamcorper. 
                            Arcu risus aliquet integer feugiat dis odio netus.
                        </p>
                        <div className="relative mt-5">
                            <input onChange={e => setSearchText(e.target.value)} placeholder="Search courses" id="searchBox" className="w-full rounded px-3 border py-4 input" />
                            <i onClick={() => searchText.length && navigate(`/?search=${searchText}`, { replace: true })} style={{backgroundColor: '#FD661F'}} className="text-center rounded-md pt-2 px-4 pb-8 text-white h-8 absolute transform -translate-y-1/2 right-3 text-xl top-2 cursor-pointer fa-light fa-magnifying-glass"></i>
                            {/* <label for="password" className="label absolute mt-2 ml-3 leading-tighter font-bold text-base cursor-text">Password</label> */}
                        </div>
                    </div>
                    
                </div>
                <div className="hidden lg:block lg:w-1/2">
                    <img className="w-full" src={youngstersImage} alt="" />
                </div>
            </div>
        </div>
        
        <div className="min-w-screen min-h-screen bg-white flex flex-wrap">
            <div className="w-full px-10 lg:px-20 py-5 border-b border-grey-200">
                <h3 className="text-xl font-bold">Your Course Chosen</h3>
                {/* <p className="mt-2">
                    All the items aded into the basket or 
                    cart is listed out here! you can add more 
                    <br />
                    items or remove the one you're 
                    not conortable with
                </p> */}
            </div>
            <div className="w-full px-10 lg:px-20 py-5">
                <div className="bg-gray-300 rounded-lg shadow overflow-x-scroll">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-100">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Course 
                                    <span style={{color: '#FF0000'}} className="lg:hidden"><br />* Swipe right for more</span></th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Course category</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Period of completion</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                            </tr>
                        </thead>
                        <tbody className="bg-gray-100 divide-y divide-gray-200">
                            <tr>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <img className="h-100 rounded-lg max-w-xs" src={currentCourse.attributes.cover_image} alt="" />
                                    <p className="font-bold text-lg mt-5">{currentCourse.attributes.title}</p>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">{currentCourse.attributes.category.name}</td>
                                <td className="px-6 py-4 whitespace-nowrap">{currentCourse.attributes.period_of_completion}</td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {
                                        currentCourse.attributes.discount_price
                                        ?
                                        <div>
                                            <p className="line-through text-gray-500 text-xs">₦{currentCourse.attributes.actual_price}</p>
                                            <p>₦{currentCourse.attributes.discount_price}</p>
                                        </div>
                                        :
                                        <span>₦{currentCourse.attributes.actual_price}</span>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="w-full px-10 lg:px-20 mt-5 lg:mt-10 mb-24 text-right">
                <div>
                    <span className="text-xl">Total Amount: </span>
                    <span className="text-md ml-5">₦
                        {
                            currentCourse.attributes.discount_price
                            ?
                            currentCourse.attributes.discount_price
                            :
                            currentCourse.attributes.actual_price
                        }
                    </span>
                </div>
                <div className="mt-5 lg:mt-10">
                    <Link to={'pay'}>
                        <button className="auth-orange-button p-4 text-sm" style={{background: '#FF8A00'}}>
                            Proceed to Payment
                        </button>
                    </Link>
                </div>
            </div>
        </div>
        
        <Footer />
        </>
    );
}

export default Checkout;