import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import signupImage from "../../assets/registration-image.png";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";
function Signup({ loggedIn, navigate, BASE_URL}) {

    const [signupText, setSignupText] = useState('Sign Up');
    const [signupLoading, setSignupLoading] = useState(false);
    const [accountType, setAccountType] = useState('Student');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [qualification, setQualification] = useState('');
    const [introductionBrief, setIntroductionBrief] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleAccountTypeChange = e => setAccountType(e.target.value);
    const handleFirstNameInput = e => setFirstName(e.target.value);
    const handleLastNameInput = e => setLastName(e.target.value);
    const handleUserNameInput = e => setUserName(e.target.value);
    const handleEmailInput = e => setEmail(e.target.value);
    const handlePhoneInput = e => setPhone(e.target.value);
    const handleQualificationInput = e => setQualification(e.target.value);
    const handleIntroductionBriefInput = e => setIntroductionBrief(e.target.value);
    const handlePasswordInput = e => setPassword(e.target.value);
    const handleConfirmPasswordInput = e => setConfirmPassword(e.target.value);

    const handleSignup = () => {
        setSignupText('');
        setSignupLoading(true);
        axios
        .post(`${BASE_URL}${accountType.toLowerCase() === 'student' ? 'student' : 'instructor'}/register`, {
            username: userName,
            first_name: firstName,
            last_name: lastName,
            gender: 'female',
            password,
            password_confirmation: confirmPassword,
            email,
            phone_number: phone,
            qualification,
            introduction_brief: introductionBrief
        })
        .then(() => {
            Swal.fire({
                title: 'Success',
                text: 'Sign up was successful',
                icon: 'success',
                confirmButtonColor: '#002979',
            }).then(() => {
                navigate('/login', { replace: true });
            })
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.message : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Sign up failed',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            setSignupLoading(false);
            setSignupText('Sign Up');
        })
    }

    useEffect(() => {
        document.title = 'Sign Up - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Navbar loggedIn={loggedIn} />
        <div className="py-12 bodyGrey">
            <div className="mt-12 px-10 py-0 flex flex-wrap">
                <img className="w-full lg:w-1/2" src={signupImage} alt="Signup" />
                <div className="w-full lg:w-1/2 lg:px-10">
                    <form className="lg:px-10 py-5">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-1/6 self-center">
                                <label htmlFor="account_type">Account Type: </label>
                            </div>
                            <div className="w-full lg:w-5/6">
                                <select onChange={handleAccountTypeChange} id="account_type" className="w-full rounded-md indent-0 px-3 border py-4" type="text" autoFocus>
                                    <option value="Student">Student</option>
                                    <option value="Instructor">Instructor</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-wrap pt-4">
                            <div className="w-full lg:w-1/6 self-center">
                                <label htmlFor="username">Username: </label>
                            </div>
                            <div className="w-full lg:w-5/6">
                                <input onChange={handleUserNameInput} id="username" className="w-full rounded-md indent-0 px-3 border py-4" type="text" autoFocus />
                            </div>
                        </div>
                        <div className="flex flex-wrap pt-4">
                            <div className="w-full lg:w-1/6 self-center">
                                <label htmlFor="firstname">First Name: </label>
                            </div>
                            <div className="w-full lg:w-5/6">
                                <input onChange={handleFirstNameInput} id="firstname" className="w-full rounded-md indent-0 px-3 border py-4" type="text" />
                            </div>
                        </div>
                        <div className="flex flex-wrap pt-4">
                            <div className="w-full lg:w-1/6 self-center">
                                <label htmlFor="lastname">Last Name: </label>
                            </div>
                            <div className="w-full lg:w-5/6">
                                <input onChange={handleLastNameInput} id="lastname" className="w-full rounded-md indent-0 px-3 border py-4" type="text" />
                            </div>
                        </div>
                        <div className="flex flex-wrap pt-4">
                            <div className="w-full lg:w-1/6 self-center">
                                <label htmlFor="email">Email: </label>
                            </div>
                            <div className="w-full lg:w-5/6">
                                <input onChange={handleEmailInput} id="email" className="w-full rounded-md indent-0 px-3 border py-4" type="email" />
                            </div>
                        </div>
                        <div className="flex flex-wrap pt-4">
                            <div className="w-full lg:w-1/6 self-center">
                                <label htmlFor="phone">Phone Number: </label>
                            </div>
                            <div className="w-full lg:w-5/6">
                                <input onChange={handlePhoneInput} id="phone" className="w-full rounded-md indent-0 px-3 border py-4" type="text" />
                            </div>
                        </div>
                        {
                            accountType.toLowerCase() === 'instructor' && (
                                <>
                                    <div className="flex flex-wrap pt-4">
                                        <div className="w-full lg:w-1/6 self-center">
                                            <label htmlFor="highest_qualification">Highest Qualification: </label>
                                        </div>
                                        <div className="w-full lg:w-5/6">
                                            <select onChange={handleQualificationInput} id="highest_qualification" className="w-full rounded-md indent-0 px-3 border py-4" type="text">
                                                <option selected>Select Qualification</option>
                                                <option value="O-Level Certificate">O-Level Certificate</option>
                                                <option value="OND">OND</option>
                                                <option value="HND">HND</option>
                                                <option value="BSC">BSC</option>
                                                <option value="PGD">PGD</option>
                                                <option value="MSC">MSC</option>
                                                <option value="PHD">PHD</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap pt-4">
                                        <div className="w-full lg:w-1/6 self-center">
                                            <label htmlFor="introduction_brief">Introduction Brief: </label>
                                        </div>
                                        <div className="w-full lg:w-5/6">
                                            <input onChange={handleIntroductionBriefInput} id="introduction_brief" className="w-full rounded-md indent-0 px-3 border py-4" type="text" />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        <div className="flex flex-wrap pt-4">
                            <div className="w-full lg:w-1/6 self-center">
                                <label htmlFor="password">Password: </label>
                            </div>
                            <div className="w-full lg:w-5/6">
                                <input onChange={handlePasswordInput} id="password" className="w-full rounded-md indent-0 px-3 border py-4" type="password" />
                            </div>
                        </div>
                        <div className="flex flex-wrap pt-4">
                            <div className="w-full lg:w-1/6 self-center">
                                <label htmlFor="confirm_password">Confirm Password: </label>
                            </div>
                            <div className="w-full lg:w-5/6">
                                <input onChange={handleConfirmPasswordInput} id="confirm_password" className="w-full rounded-md indent-0 px-3 border py-4" type="password" />
                            </div>
                        </div>
                        <div className="w-full pt-8 flex flex-wrap">
                            <div className="w-full">
                                <span style={{ color: '#808080' }}>I already have an account</span>
                                <Link to={'/login'} className="text-black font-bold text-lg ml-2">Login</Link>
                            </div>
                            <div className="w-full lg:w-1/3 pt-5 lg:pt-0 text-right mt-4">
                                <button 
                                    onClick={() => handleSignup()}
                                    className="w-full inline-flex rounded-lg grey-button text-lg sm:px-12 px-4 py-4"
                                    type="button">
                                        {
                                            signupLoading
                                            ?
                                            <i className="fas fa-loader fa-spin py-1"></i>
                                            :
                                            signupText
                                        }
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}

export default Signup;