import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLessonsAPI } from './lessonsAPI';

const initialState = {
    lessons: localStorage.getItem('lessons') || '[]',
    status: 'idle'
};

export const fetchLessons = createAsyncThunk(
    'counter/fetchLessons',
    async (payload) => {
        const RESPONSE = await fetchLessonsAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return JSON.stringify(RESPONSE.data.data);
    }
);

export const lessonsSlice = createSlice({
    name: 'lessons',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        storeLogout: (state) => {
            state.lessons = '[]';
            localStorage.setItem('lessons', '[]');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLessons.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLessons.fulfilled, (state, action) => {
                state.status = 'idle';
                state.lessons = action.payload;
                localStorage.setItem('lessons', action.payload);
            });
    }
});

export const { storeLogout } = lessonsSlice.actions;

export const selectLessons = state => state.lessons.lessons;

export default lessonsSlice.reducer;
