import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logUserOut, fetchSubscriptionHistoryAPI } from './userAPI';
// import axios from 'axios';

const initialState = {
  userData: localStorage.getItem('userData') || 'null',
  token: localStorage.getItem('token') || 'null',
  subscriptionHistory: localStorage.getItem('subscriptionHistory') || '[]',
  status: 'idle'
};

export const logout = createAsyncThunk(
  'user/logout',
  async (payload) => {
      const RESPONSE = await logUserOut(payload);
      // The value we return becomes the `fulfilled` action payload
      return RESPONSE.data;
  }
);

export const fetchSubscriptionHistory = createAsyncThunk(
  'user/fetchSubscriptionHistory',
  async (payload) => {
      const RESPONSE = await fetchSubscriptionHistoryAPI(payload);
      // The value we return becomes the `fulfilled` action payload
      return JSON.stringify(RESPONSE.data.data);
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    storeLoginCredentials: (state, action) => {
        localStorage.setItem('userData', JSON.stringify(action.payload.user));
        state.userData = JSON.stringify(action.payload.user);
        localStorage.setItem('token', JSON.stringify(action.payload.token));
        state.token = JSON.stringify(action.payload.token);
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(logout.pending, (state) => {
        state.status = 'loading';
        state.userData = 'null';
        state.token = 'null';
    })
    .addCase(logout.fulfilled, (state, action) => {
        state.status = 'idle';
        localStorage.removeItem('userData');
        state.userData = 'null';
        localStorage.removeItem('token');
        state.token = 'null';
    })
    .addCase(fetchSubscriptionHistory.pending, (state) => {
        state.status = 'loading';
    })
    .addCase(fetchSubscriptionHistory.fulfilled, (state, action) => {
        state.status = 'idle';
        state.subscriptionHistory = action.payload;
        localStorage.setItem('subscriptionHistory', action.payload);
    });
  }
});

export const { storeLoginCredentials } = userSlice.actions;

export const selectUser = state => state.user.userData;
export const selectToken = state => state.user.token;
export const loggedIn = state => !!JSON.parse(state.user.token);
export const role = state => loggedIn && state.user.userData ? JSON.parse(state.user.userData).attributes.role[0] : 'Guest';

export const selectSubscriptionHistory = state => state.user.subscriptionHistory;

export default userSlice.reducer;
