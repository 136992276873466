import AuthNavbar from "../../../../components/AuthNavbar";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from "react-redux";
function AddCourse({ navigate, _token, _courseCategories, BASE_URL, fetchCourseCategories }) {
    const dispatch = useDispatch();

    const [categoryId, setCategoryId] = useState('');
    const [courseTitle, setCourseTitle] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [lessonsNumber, setLessonsNumber] = useState('');
    const [completionPeriod, setCompletionPeriod] = useState('');
    const [actualPrice, setActualPrice] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [quiz, setQuiz] = useState([
        {
            question: '',
            options: [
                '',
                '',
                '',
                ''
            ],
            answer: ''
        }
    ]);
    const handleQuestionInput = (e, index) => {
        let newQuiz = [...quiz];
        newQuiz[index].question = e.target.value;
        setQuiz(newQuiz);
    }

    const handleOptionInput = (e, index, optionIndex) => {
        let newQuiz = [...quiz];
        newQuiz[index].options[optionIndex] = e.target.value;
        setQuiz(newQuiz);
    }

    const handleAnswerInput = (e, index) => {
        let newQuiz = [...quiz];
        newQuiz[index].answer = e.target.value;
        setQuiz(newQuiz);
    }

    const addQuestion = () => {
        const newQuiz = [...quiz];
        newQuiz.splice(
            newQuiz.length,
            0,
            {
                question: '',
                options: [
                    '',
                    '',
                    '',
                    ''
                ],
                answer: ''
            }
        );
        setQuiz(newQuiz);
    }

    const removeQuestion = index => {
        const newQuiz = [...quiz];
        newQuiz.splice(
            index,
            1
        );
        setQuiz(newQuiz);
    }

    const [submitText, setSubmitText] = useState('Create');
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleCategoryIdInput = e => setCategoryId(e.target.value);
    const handleCourseTitleInput = e => setCourseTitle(e.target.value);
    const handleCourseDescriptionInput = e => setCourseDescription(e.target.value);
    const handleLessonsNumberInput = e => setLessonsNumber(e.target.value);
    const handleCompletionPeriodInput = e => setCompletionPeriod(e.target.value);
    const handleActualPriceInput = e => setActualPrice(e.target.value);
    const handleDiscountPriceInput = e => setDiscountPrice(e.target.value);

    const createCourse = () => {
        setSubmitText('');
        setSubmitLoading(true);
        let FORMDATA = new FormData();
        
        quiz.forEach((question, index) => {
            FORMDATA.append(`quiz[${index}][question]`, question.question);
            question.options.forEach((option, optionIndex) => {
                FORMDATA.append(`quiz[${index}][options][${optionIndex}]`, option);
            });
            FORMDATA.append(`quiz[${index}][answer]`, question.answer);
        });

        FORMDATA.append('category_id', categoryId);
        FORMDATA.append('title', courseTitle);
        FORMDATA.append('description', courseDescription);
        FORMDATA.append('actual_price', actualPrice);
        FORMDATA.append('discount_price', discountPrice);
        FORMDATA.append('period_of_completion', completionPeriod);
        FORMDATA.append('number_of_lessons', lessonsNumber);
        FORMDATA.append('cover_image', document.getElementById('cover-image').files[0]);
        axios
            .post(`${BASE_URL}courses/create`, FORMDATA,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${_token}`
                    }
                })
            .then(() => {
                setSubmitLoading(false);
                setSubmitText('Create');
                Swal.fire({
                    title: 'Success',
                    text: 'The course was created successfully',
                    icon: 'success',
                    confirmButtonColor: '#002979',
                });
                navigate('/dashboard/courses', { replace: true });
            })
            .catch(err => {
                setSubmitLoading(false);
                setSubmitText('Create');
                let errorMsg = err.response.data ? err.response.data.message : null;
                Swal.fire({
                    title: 'Error',
                    text: errorMsg || 'Course creation failed',
                    icon: 'error',
                    confirmButtonColor: '#002979',
                });
            })
            .then(() => {
                //
            });
    }

    useEffect(() => {
        if (_courseCategories.length) {
            setCategoryId(_courseCategories.length >= 1 ? _courseCategories[0].id : '');
            setCompletionPeriod('10 minutes');
        }
        dispatch(fetchCourseCategories({ BASE_URL }));
    }, [_courseCategories, dispatch, fetchCourseCategories, BASE_URL]);

    useEffect(() => {
        document.title = 'Add Course - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthNavbar />
            <div className="pt-16 lg:pt-24 relative z-10 mx-auto">
                <div
                    className="px-0 py-2 xl:ml-72 xl:mr-10 mr-5 ml-5 z-20"
                >
                    <div className="mt-2 mb-16 mr-5">
                        <form className="p-2 lg:p-10">
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <h2 style={{ fontSize: '30px' }}>Create Course</h2>
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="category">Course Category </label>
                                </div>
                                <div className="w-full">
                                    <select onChange={handleCategoryIdInput} id="category" className="w-full rounded-md px-3 border py-4">
                                        {
                                            _courseCategories.map((category, index) => {
                                                return <option key={index} value={category.id}>{category.attributes.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="course-title">Course Title </label>
                                </div>
                                <div className="w-full">
                                    <input onChange={handleCourseTitleInput} id="course-title" className="w-full rounded-md  px-3 border py-4" type="text" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="course-description">Course Description </label>
                                </div>
                                <div className="w-full">
                                    <textarea onChange={handleCourseDescriptionInput} id="course-description" className="w-full rounded-md  px-3 border py-4"></textarea>
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="actual-price">Actual Price </label>
                                </div>
                                <div className="w-full">
                                    <input onChange={handleActualPriceInput} id="actual-price" className="w-full rounded-md  px-3 border py-4" type="number" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="discount-price">Discount Price </label>
                                </div>
                                <div className="w-full">
                                    <input onChange={handleDiscountPriceInput} id="discount-price" className="w-full rounded-md  px-3 border py-4" type="number" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="number-of-lessons">Number of lessons </label>
                                </div>
                                <div className="w-full">
                                    <input onChange={handleLessonsNumberInput} id="number-of-lessons" className="w-full rounded-md  px-3 border py-4" type="number" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="cover-image">Cover image </label>
                                </div>
                                <div className="w-full">
                                    <input id="cover-image" className="w-full rounded-md  px-3 border py-4 bg-white" type="file" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="period-of-completion">Period of completion </label>
                                </div>
                                <div className="w-full">
                                    <select onChange={handleCompletionPeriodInput} id="period-of-completion" className="w-full rounded-md  px-3 border py-4">
                                        <option value="10 minutes">10 minutes</option>
                                        <option value="20 minutes">20 minutes</option>
                                        <option value="30 minutes">30 minutes</option>
                                        <option value="45 minutes">45 minutes</option>
                                        <option value="1 hour">1 hour</option>
                                        <option value="2 hours">2 hours</option>
                                        <option value="3 hours">3 hours</option>
                                        <option value="4 hours">4 hours</option>
                                        <option value="6 hours">6 hours</option>
                                        <option value="10 hours">10 hours</option>
                                        <option value="12 hours">12 hours</option>
                                        <option value="18 hours">18 hours</option>
                                        <option value="1 day">1 day</option>
                                        <option value="2 days">2 days</option>
                                        <option value="3 days">3 days</option>
                                        <option value="5 days">5 days</option>
                                        <option value="7 days">7 days</option>
                                        <option value="14 days">14 days</option>
                                        <option value="18 days">18 days</option>
                                        <option value="21 days">21 days</option>
                                        <option value="25 days">25 days</option>
                                        <option value="1 month">1 month</option>
                                        <option value="2 months">2 months</option>
                                        <option value="3 months">3 months</option>
                                        <option value="4 months">4 months</option>
                                        <option value="5 months">5 months</option>
                                        <option value="6 months">6 months</option>
                                        <option value="9 months">9 months</option>
                                        <option value="12 months">12 months</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <h2 style={{ fontSize: '30px' }}>Quiz Setup</h2>
                                </div>
                            </div>

                            {
                                quiz.map((q, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-full self-center mb-2">
                                                    <label htmlFor="course-title">Question {index + 1}</label>
                                                    {
                                                        quiz.length >= 2 && <span style={{float: 'right'}} className="pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => removeQuestion()}>Remove Question -</span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input onChange={(e) => handleQuestionInput(e, index)} id="course-title" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor="course-title">Option 1</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 0)} id="lesson-option-1" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor="course-title">Option 2</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 1)} id="lesson-option-2" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor="course-title">Option 3</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 2)} id="lesson-option-3" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor="course-title">Option 4</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 3)} id="lesson-option-4" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-full self-center mb-2">
                                                    <label htmlFor="category">Answer </label>
                                                </div>
                                                <div className="w-full">
                                                    <select onChange={(e) => handleAnswerInput(e, index)} id="category" className="w-full rounded-md  px-3 border py-4">
                                                        <option disabled selected>Select answer</option>
                                                        {
                                                            quiz[index].options.map((option, index) => {
                                                                return option.length >= 1 && <option key={'option-' + index} value={option}>{option}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="w-full text-right">
                                <span className="pointer font-medium text-blue-600 dark:text-blue-500 hover:underline mb-4" onClick={() => addQuestion()}>Add Question +</span>
                            </div>
                        </form>

                        <div className="flex flex-wrap px-2 lg:px-10">
                            <div className="hidden lg:block w-2/3"></div>
                            <div className="w-full lg:w-1/3">
                                <button disabled={submitLoading} onClick={() => !submitLoading && createCourse()} className={'bodyBluish w-full p-4 rounded-xl text-white ' + (submitLoading && 'opacity-50')}>
                                    {
                                        submitLoading
                                            ?
                                            <i className="fas fa-loader fa-spin py-1"></i>
                                            :
                                            submitText
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default AddCourse;