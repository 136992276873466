import { useState, useEffect } from "react";
import ContactNavbar from "../../components/ContactNavbar";
import Footer from "../../components/Footer";
import contactImage from "../../assets/contact-image.png";
import Swal from "sweetalert2";
import axios from "axios";
function Contact({BASE_URL, loggedIn}) {

    const [submitText, setSubmitText] = useState('Send Message');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [request, setRequest] = useState('');
    const [message, setMessage] = useState('');

    const handleNameInput = e => setName(e.target.value);
    const handleEmailInput = e => setEmail(e.target.value);
    const handlePhoneInput = e => setPhone(e.target.value);
    const handleRequestInput = e => setRequest(e.target.value);
    const handleMessageInput = e => setMessage(e.target.value);

    const handleSubmit = () => {
        setSubmitText('Send Message');
        setSubmitLoading(true);
        axios
        .post(`${BASE_URL}contacts`, {
            full_name: name,
            phone_number: phone,
            email,
            message: request,
            comment: message
        })
        .then(res => {
            console.log(res);
            Swal.fire({
                title: 'Success',
                text: 'Message was sent successfully',
                icon: 'success',
                confirmButtonColor: '#002979',
            });
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                title: 'Error',
                text: 'Message failed to send',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            setSubmitLoading(false);
            setSubmitText('Send Message');
        })
    }

    useEffect(() => {
        document.title = 'Contact - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div style={{background: 'radial-gradient(83.24% 83.24% at 50% 16.76%, #EDEDED 0%, rgba(149, 209, 255, 0.65) 64.24%, #F0F0F0 100%)'}}>
                <ContactNavbar loggedIn={loggedIn} />
                <div className="flex flex-wrap lg:px-10">
                    <div className="w-full lg:w-1/2">
                        <div className="px-10 pb-20">
                            <h1 style={{fontSize: '5rem', color: '#093756'}} className="hidden lg:block font-bold mt-10">
                                Hello, tell us how we can help you
                            </h1>
                            <h1 style={{fontSize: '3rem', color: '#093756'}} className="lg:hidden block font-bold mt-10">
                                Hello, tell us how we can help you
                            </h1>
                        </div>
                        
                    </div>
                    <div className="hidden lg:block w-1/2">
                        <img className="w-full" src={contactImage} alt="" />
                    </div>
                </div>
            </div>
            <div className="lg:absolute lg:top-96 lg:mt-32 xl:mx-32 md:mx-4 w-full lg:w-5/6">
                <div className="flex flex-wrap">
                    <div className="lg:w-2/3 bg-white rounded shadow-md py-10 flex flex-wrap">
                        <form className="w-full flex flex-wrap">
                            <div className="w-full px-6 mb-10">
                                <h3 className="text-xl lg:text-3xl font-bold header-text">
                                    Send us a message
                                    <svg className="ml-5 inline" width="64" height="31" viewBox="0 0 64 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M34.974 19.915C34.974 20.795 34.312 21.915 33.495 21.915H1.475C0.663 21.915 0 20.795 0 19.915C0 19.038 0.662 17.915 1.475 17.915H33.495C34.31 17.915 34.972 19.038 34.972 19.915" fill="#4394CD"/>
                                        <path d="M36.6239 11.915C36.6239 12.795 35.9619 13.915 35.1449 13.915H3.1249C2.3129 13.915 1.6499 12.795 1.6499 11.915C1.6499 11.039 2.3129 9.91504 3.1249 9.91504H35.1449C35.9619 9.91504 36.6239 11.039 36.6239 11.915Z" fill="#4394CD"/>
                                        <path d="M34.974 27.915C34.974 28.796 34.312 29.915 33.495 29.915H1.475C0.663 29.915 0 28.796 0 27.915C0 27.038 0.662 25.915 1.475 25.915H33.495C34.31 25.915 34.972 27.038 34.972 27.915" fill="#4394CD"/>
                                        <path d="M49.8238 30.555H9.69385L23.7238 0H63.8539L49.8238 30.555Z" fill="#E7E6E6"/>
                                        <path d="M55.474 4.43506C56.313 4.43506 56.703 5.06906 56.346 5.85106L53.622 11.7811C53.264 12.5661 52.288 13.1991 51.449 13.1991H46.429C45.587 13.1991 45.2 12.5651 45.556 11.7811L48.282 5.85106C48.64 5.06906 49.613 4.43506 50.453 4.43506H55.483" fill="#BE1E2D"/>
                                        <path d="M52.1241 5.77502C52.5391 5.77502 52.7271 6.08702 52.5541 6.46602L51.2221 9.36502C51.0471 9.74802 50.5711 10.057 50.1591 10.057H47.7051C47.2971 10.057 47.1041 9.74702 47.2801 9.36502L48.6101 6.46602C48.7861 6.08702 49.2631 5.77502 49.6731 5.77502H52.1241Z" fill="#F15A29"/>
                                        <path d="M42.0842 16.515C41.9132 16.888 41.4782 17.186 41.1052 17.186H25.5192C25.1492 17.186 24.9862 16.888 25.1562 16.515C25.3252 16.147 25.7652 15.844 26.1352 15.844H41.7212C42.0922 15.844 42.2532 16.147 42.0842 16.515Z" fill="#243438"/>
                                        <path d="M38.353 19.983C38.184 20.352 37.785 20.651 37.461 20.651H23.846C23.526 20.651 23.4 20.352 23.57 19.983C23.739 19.611 24.142 19.311 24.462 19.311H38.077C38.4 19.311 38.524 19.611 38.353 19.983Z" fill="#243438"/>
                                        <path d="M41.5518 23.515C41.3798 23.885 40.8958 24.186 40.4658 24.186H22.4158C21.9848 24.186 21.7758 23.885 21.9448 23.515C22.1148 23.144 22.5998 22.844 23.0308 22.844H41.0808C41.5108 22.844 41.7218 23.143 41.5518 23.515Z" fill="#243438"/>
                                    </svg>
                                </h3>
                            </div>
                            <div className="w-full lg:w-1/2 px-6 mb-10">
                                <label htmlFor="name" className="label mt-2 leading-tighter text-base cursor-text text-lg">Your name</label>
                                <input onChange={handleNameInput} id="name" className="w-full rounded px-3 border py-4 input" type="text" autoFocus />
                            </div>
                            <div className="w-full lg:w-1/2 px-6 mb-10">
                                <label htmlFor="email" className="label mt-2 leading-tighter text-base cursor-text text-lg">Your email</label>
                                <input onChange={handleEmailInput} id="email" className="w-full rounded px-3 border py-4 input" type="email" />
                            </div>
                            <div className="w-full lg:w-1/2 px-6 mb-10">
                                <label htmlFor="phone" className="label mt-2 leading-tighter text-base cursor-text text-lg">Your phone no.</label>
                                <input onChange={handlePhoneInput} id="phone" className="w-full rounded px-3 border py-4 input" type="text" />
                            </div>
                            <div className="w-full lg:w-1/2 px-6 mb-10">
                                <label htmlFor="company" className="label mt-2 leading-tighter text-base cursor-text text-lg">Your request</label>
                                <input onChange={handleRequestInput} id="company" className="w-full rounded px-3 border py-4 input" type="text" />
                            </div>
                            <div className="w-full lg:w-full px-6 mb-10">
                                <label htmlFor="message" className="label mt-2 leading-tighter text-base cursor-text text-lg">Comment</label>
                                <textarea onChange={handleMessageInput} className="w-full rounded px-3 border py-4 input" id="message" cols="30" rows="4"></textarea>
                            </div>
                            <div className="md:w-1/3"></div>
                            <div className="md:w-1/3 w-full justify-center px-6">
                                <button 
                                onClick={() => submitLoading ? null : handleSubmit()}
                                style={{background: '#0D69A8'}}
                                className="w-full inline-flex main-button text-lg sm:px-6 px-4 py-4"
                                type="button">
                                    { submitText }
                                    <i className="fa-solid fa-paper-plane text-white ml-2"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="lg:w-1/3 w-full contact-box rounded shadow-md py-6">
                        <div className="w-full px-6 mb-8">
                            <h3 className="text-lg font-bold text-white">Contact Information</h3>
                        </div>
                        <div className="w-full px-6 mb-10">
                            <span className="text-white text-md"><i className="fal fa-map-marker-alt text-white fa-2x mr-5 relative top-2"></i>760 Mkt Str, floor 10 Texas, HO, 94102</span>
                        </div>
                        <div className="w-full px-6 mb-10">
                            <span className="text-white text-md"><i className="fal fa-phone text-white fa-2x mr-5 relative top-2"></i>+977-981234567</span>
                        </div>
                        <div className="w-full px-6 mb-10">
                            <span className="text-white text-md"><i className="fal fa-envelope text-white fa-2x mr-5 relative top-2"></i>Sales@analyticasoft.com</span>
                        </div>
                        <div className="w-full px-6 pt-16 text-center">
                            <a href="https://facebook.com" target="_blank" rel="noreferrer">
                                <i className="w-1/6 mr-2 inline-block justify-center duration-300 hover:shadow-xl fab fa-facebook fa-2x py-2 px-2 text-white"></i>
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noreferrer">
                                <i className="w-1/6 mr-2 inline-block justify-center duration-300 hover:shadow-xl fab fa-twitter fa-2x py-2 px-2 text-white"></i>
                            </a>
                            <a href="mailto:info@lexstudy.com" target="_blank" rel="noreferrer">
                                <i className="w-1/6 mr-2 inline-block justify-center duration-300 hover:shadow-xl fab fa-envelope fa-2x py-2 px-2 text-white"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rounded-md mr-5 ml-5 z-20 pt-3 pb-10 lg:mt-96 lg:pt-56">
                <div className="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen">
                    <div className="flex flex-col items-center">
                        <h2 className="font-bold text-5xl mt-5 tracking-tight">
                            FAQs
                        </h2>
                        <p className="text-neutral-500 text-xl mt-3">
                            Frequenty asked questions
                        </p>
                    </div>
                    <div className="grid divide-y divide-neutral-200 mx-auto mt-8">
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> What is a SAAS platform?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    SAAS platform is a cloud-based software service that allows users to access and use a variety of tools and functionality.
                                </p>
                            </details>
                        </div>
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> How does billing work?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    We offers a variety of billing options, including monthly and annual subscription plans, as well as pay-as-you-go pricing for certain services. Payment is typically made through a credit card or other secure online
                                    payment method.
                                </p>
                            </details>
                        </div>
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> Can I get a refund for my subscription?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    We offers a 30-day money-back guarantee for most of its subscription plans. If you are not satisfied with your subscription within the first 30 days, you can request a full refund. Refunds for subscriptions that have
                                    been active for longer than 30 days may be considered on a case-by-case basis.
                                </p>
                            </details>
                        </div>
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> How do I cancel my subscription?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    To cancel your We subscription, you can log in to your account and navigate to the subscription management page. From there, you should be able to cancel your subscription and stop future billing.
                                </p>
                            </details>
                        </div>
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> Can I try this platform for free?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    We offers a free trial of its platform for a limited time. During the trial period, you will have access to a limited set of features and functionality, but you will not be charged.
                                </p>
                            </details>
                        </div>
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> How do I access documentation?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    Documentation is available on the company's website and can be accessed by logging in to your account. The documentation provides detailed information on how to use the , as well as code examples and other resources.
                                </p>
                            </details>
                        </div>
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> How do I contact support?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    If you need help with the platform or have any other questions, you can contact the company's support team by submitting a support request through the website or by emailing support@We.com.
                                </p>
                            </details>
                        </div>
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> Do you offer any discounts or promotions?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    We may offer discounts or promotions from time to time. To stay up-to-date on the latest deals and special offers, you can sign up for the company's newsletter or follow it on social media.
                                </p>
                            </details>
                        </div>
                        <div className="py-5">
                            <details className="group">
                                <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                    <span> How do we compare to other similar services?</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                    This platform is a highly reliable and feature-rich service that offers a wide range of tools and functionality. It is competitively priced and offers a variety of billing options to suit different needs and budgets.
                                </p>
                            </details>
                        </div>
                    </div>
                </div>
                {/* <div className="relative z-10 mx-auto mt-56">
                    <h2 className="text-center title-font pt-12 text-4xl mb-4 font-bold leading-tight">FAQss</h2>
                    <p className="text-center mx-56">
                        Get all type of anwsers to any question you need to know or solution to any challenge you have been facing. Do well to consult them bellow
                    </p>

                    <div className="shadow-md group outline-none accordion-section mb-6 bg-white mx-48" tabIndex="1">
                        <div
                            className="mt-5 rounded-md group-focus:bg-main-color transform transition ease duration-500 flex justify-between sm:px-16 sm:py-8 p-4 items-center cursor-pointer relative z-0"
                        >
                            <div className="header-text font-bold text-xl">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.?
                            </div>
                            <div
                            className="h-8 w-8 -full items-center inline-flex justify-center transform transition ease duration-500 group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-7 mr-2"
                            >
                            <i className="fas fa-plus fa-2x main-color"></i>
                            </div>
                        </div>
                        <div className="rounded-md shadow-md duration-500 hover:shadow-lg group-focus:bg-main-color group-focus:max-h-screen max-h-0 sm:px-14 px-2 overflow-hidden ease duration-500 cursor-pointer">
                            <p className="px-2 pb-8 main-text text-justify">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.?
                            </p>
                        </div>
                    </div>
                    <div className="shadow-md group outline-none accordion-section mb-6 bg-white mx-48" tabIndex="1">
                        <div
                            className="mt-5 rounded-md group-focus:bg-main-color transform transition ease duration-500 flex justify-between sm:px-16 sm:py-8 p-4 items-center cursor-pointer relative z-0"
                        >
                            <div className="header-text font-bold text-xl">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.?
                            </div>
                            <div
                            className="h-8 w-8 -full items-center inline-flex justify-center transform transition ease duration-500 group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-7 mr-2"
                            >
                            <i className="fas fa-plus fa-2x main-color"></i>
                            </div>
                        </div>
                        <div className="rounded-md shadow-md duration-500 hover:shadow-lg group-focus:bg-main-color group-focus:max-h-screen max-h-0 sm:px-14 px-2 overflow-hidden ease duration-500 cursor-pointer">
                            <p className="px-2 pb-8 main-text text-justify">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.?
                            </p>
                        </div>
                    </div>
                    <div className="shadow-md group outline-none accordion-section mb-6 bg-white mx-48" tabIndex="1">
                        <div
                            className="mt-5 rounded-md group-focus:bg-main-color transform transition ease duration-500 flex justify-between sm:px-16 sm:py-8 p-4 items-center cursor-pointer relative z-0"
                        >
                            <div className="header-text font-bold text-xl">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.?
                            </div>
                            <div
                            className="h-8 w-8 -full items-center inline-flex justify-center transform transition ease duration-500 group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-7 mr-2"
                            >
                            <i className="fas fa-plus fa-2x main-color"></i>
                            </div>
                        </div>
                        <div className="rounded-md shadow-md duration-500 hover:shadow-lg group-focus:bg-main-color group-focus:max-h-screen max-h-0 sm:px-14 px-2 overflow-hidden ease duration-500 cursor-pointer">
                            <p className="px-2 pb-8 main-text text-justify">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.?
                            </p>
                        </div>
                    </div>
                    <div className="shadow-md group outline-none accordion-section mb-6 bg-white mx-48" tabIndex="1">
                        <div
                            className="mt-5 rounded-md group-focus:bg-main-color transform transition ease duration-500 flex justify-between sm:px-16 sm:py-8 p-4 items-center cursor-pointer relative z-0"
                        >
                            <div className="header-text font-bold text-xl">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.?
                            </div>
                            <div
                            className="h-8 w-8 -full items-center inline-flex justify-center transform transition ease duration-500 group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-7 mr-2"
                            >
                            <i className="fas fa-plus fa-2x main-color"></i>
                            </div>
                        </div>
                        <div className="rounded-md shadow-md duration-500 hover:shadow-lg group-focus:bg-main-color group-focus:max-h-screen max-h-0 sm:px-14 px-2 overflow-hidden ease duration-500 cursor-pointer">
                            <p className="px-2 pb-8 main-text text-justify">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.?
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </>
    );
}

export default Contact;