import { useEffect } from "react";
import AboutNavbar from "../../components/AboutNavbar";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import aboutUsImg from '../../assets/about-us-image.jpg';
import instructorImg from '../../assets/instructor-image.png';
function About({loggedIn}) {
    useEffect(() => {
        document.title = 'About Us - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    const numbers = [1,2,3,4,5,6,7];

    return (
        <>
        <AboutNavbar loggedIn={loggedIn} />
        <div className="pt-10 lg:pt-24 bg-white">
            <div className="flex flex-wrap px-5 lg:px-10">
                <div className="w-full lg:w-1/3">
                    <div className="px-5 lg:px-10 pb-8 pt-16 lg:pb-16">
                        <h1 style={{fontSize: '3rem'}} className="font-bold text-black">About <br />LEXSTUDY</h1>
                    </div>
                    
                </div>
                <div className="w-full lg:w-2/3">
                    <p className="px-5 lg:px-10 pb-10 lg:py-20">
                    Discover a world of legal education at your fingertips with our comprehensive e-learning platform. Whether you're a law student, legal professional, or simply interested in understanding the complexities of the legal system, LexStudy is your go-to destination for high-quality online legal courses.
                    </p>
                </div>
            </div>
        </div>
        <div className="hidden lg:block relative pb-72">
            <div className="px-10 absolute">
                <img src={aboutUsImg} alt="about-us" />
            </div>
        </div>
        {/* <div className="pt-10 lg:pt-56 pb-40 bodyBluish"> */}
        <div className="pt-10 lg:pt-56 bodyBluish">
            <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2">
                    <div className="px-10 lg:px-20 text-white">
                        <h2 className="text-2xl mb-5 font-bold">Unlock Your Potential with Expert Instructors</h2>
                        <p>
                        Our courses are designed and delivered by experienced legal professionals who are passionate about imparting their knowledge. Learn from leading experts in various legal domains, including contract law, criminal law, intellectual property, and more. Benefit from their practical insights and real-world examples, ensuring that you gain valuable skills that can be applied in your legal career.
                        </p>
                        <div className="flex flex-wrap py-16">
                            <div className="w-1/2">
                                <h2 className="text-2xl font-bold">2,500+</h2>
                                <p>
                                    Total Students
                                </p>
                            </div>
                            <div className="w-1/2">
                                <h2 className="text-2xl font-bold">1,500+</h2>
                                <p>
                                    Graduated
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/2">
                    <div className="px-10 lg:px-20 text-white">
                        <h2 className="text-2xl mb-5 font-bold">Flexible Learning, Anytime, Anywhere</h2>
                        <p>
                        We understand the demands of a busy schedule, which is why our e-learning platform offers flexibility like no other. Access courses 24/7 from the comfort of your home or on the go. Learn at your own pace, rewind and revisit lessons whenever you need, and take quizzes to reinforce your understanding. With our mobile-friendly platform, your legal education is always within reach.
                        </p>
                        <div className="flex flex-wrap py-16">
                            <div className="w-1/2">
                                <h2 className="text-2xl font-bold">6,000+</h2>
                                <p>
                                    Visitors on website
                                </p>
                            </div>
                            <div className="w-1/2">
                                <h2 className="text-2xl font-bold">1,000+</h2>
                                <p>
                                    Total jobs given
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="bg-white py-10">
            <div className="flex flex-wrap px-20">
                <div className="w-1/2 relative">
                    <div className="w-2/3 absolute -bottom-1">
                        <div className="py-10 px-24 bg-white">
                            <h1 style={{fontSize: '3rem'}} className="mb-5">Talk to a <br />mentor</h1>
                            <div className="mt-5" style={{borderLeft: '2px solid #000'}}>
                                <p className="ml-5">
                                    <span className="font-bold">7 in total</span>
                                    <br />
                                    They are experts in their domain
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="py-5">
                        <h2 className="text-xl mb-5">Mentorship</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra diam sed erat 
                            phasellus lectus quam. Etiam nibh sagittis enim sed in cum duis.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div id="about-instructors" style={{background: '#EBEBEB'}} className="px-20">
            <div className="flex flex-wrap">
                {
                    numbers.map(n => {
                        return (
                            <div key={n} className="w-1/4">
                                <div id="about-card" className="shadow-md mx-2 mb-5 mt-0">
                                    <div className="p-5 text-center">
                                        <img className="rounded-full mx-auto mt-10" style={{ width: '100px', height: '100px' }} src={instructorImg} alt="instructor" />
                                        <div className="pt-3 pb-12">
                                            <h3 className="text-black font-bold text-md">Habib Brans</h3>
                                            <span className="text-black text-md">Python expert</span>
                                        </div>
                                        <div className="flex flex-wrap mx-10 my-10">
                                            <div className="w-1/3">
                                                <div className="px-2">
                                                    <i className="fab fa-twitter-square fa-2x"></i>
                                                </div>
                                            </div>
                                            <div className="w-1/3">
                                                <div className="px-2">
                                                    <i className="fab fa-facebook-square fa-2x"></i>
                                                </div>
                                            </div>
                                            <div className="w-1/3">
                                                <div className="px-2">
                                                    <i className="fab fa-linkedin fa-2x"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div> */}
        {/* <div className="relative mb-72"> */}
        <div className="mb-96 lg:mb-72">
            <div className="bg-white lg:py-10 absolute">
                <div className="flex flex-wrap px-10 lg:px-20">
                    <div className="w-full lg:w-1/3">
                        <div className="my-10 lg:my-12">
                            <h3 className="text-black font-bold text-md mb-8">Have you decide to Enroll for <br />a course with us?</h3>
                            <Link to="/signup" className="py-3 px-4 true-orange-button text-lg main-button">
                                Enroll Now
                            </Link>
                        </div>
                    </div>
                    <div className="w-full lg:w-2/3">
                        <div className="lg:my-12">
                            <h3 className="text-black font-bold text-md mb-3">Diverse Course Catalog</h3>
                            <p>
                            Whether you're looking to dive into a specific legal topic or broaden your legal expertise, our extensive course catalog has you covered. From introductory courses for beginners to advanced seminars for seasoned professionals, we offer a wide range of topics that cater to all levels of legal knowledge. Stay ahead in your legal career with cutting-edge courses that reflect the latest developments and trends in the legal industry.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}

export default About;