import React, { useMemo, useEffect } from 'react';
import {
  loggedIn as loggedInStatus,
  selectToken,
  selectUser,
  selectSubscriptionHistory,
  fetchSubscriptionHistory,
} from './app/reducers/user/userSlice';
import {
  selectAllCourses,
  selectCourseCategories,
  fetchAllCourses,
  fetchCourseCategories
} from './app/reducers/courses/coursesSlice';
import {
  selectLessons,
  fetchLessons
} from './app/reducers/lessons/lessonsSlice';
import {
  selectAllGeneralThreads,
  fetchGeneralThreads
} from './app/reducers/generalForums/threadsSlice';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import './App.css';

// GUEST PAGES
import Login from './pages/guest/login';
import ForgotPassword from './pages/guest/forgotpassword';
import VerifyAccount from './pages/guest/verifyaccount';
import PasswordReset from './pages/guest/passwordreset';
import Contact from './pages/guest/contact';
import Signup from './pages/guest/signup';
import About from './pages/guest/about';
import FAQs from './pages/guest/faqs';
import Checkout from './pages/guest/checkout';
import Pay from './pages/guest/checkout/pay';

// NOT SO GUEST PAGES
import Index from './pages/guest/index';
import Forum from './pages/guest/forum';
import Thread from './pages/guest/forum/single-thread';
// GUEST PAGES

// INSTRUCTOR DASHBOARD PAGES
import InstructorDashboard from './pages/dashboard/instructor/index';
import Courses from './pages/dashboard/instructor/courses';
import AddCourse from './pages/dashboard/instructor/courses/add';
import EditCourse from './pages/dashboard/instructor/courses/edit';
import CourseLessons from './pages/dashboard/instructor/courses/lessons/index';
import AddCourseLesson from './pages/dashboard/instructor/courses/lessons/add';
import Profile from './pages/dashboard/instructor/profile';
// INSTRUCTOR DASHBOARD PAGES

// STUDENT DASHBOARD PAGES
import StudentDashboard from './pages/dashboard/student/index';
import SingleCourse from './pages/dashboard/student/single-course';
import StudentPurchases from './pages/dashboard/student/purchases';
// STUDENT DASHBOARD PAGES

function App() {
  // const BASE_URL = 'http://lex-study-api.herokuapp.com/api/v1/';
  const BASE_URL = 'https://lex-study-api.herokuapp.com/api/v1/';
  const navigate = useNavigate();
  const location = useLocation();
  const loggedIn = useSelector(loggedInStatus);

  // PARSED JSON
  const _selectCourseCategories = JSON.parse(useSelector(selectCourseCategories));
  const _selectToken = JSON.parse(useSelector(selectToken));
  const _selectAllCourses = JSON.parse(useSelector(selectAllCourses));
  const _selectUser = JSON.parse(useSelector(selectUser));
  const _selectLessons = JSON.parse(useSelector(selectLessons));
  const _selectAllGeneralThreads = JSON.parse(useSelector(selectAllGeneralThreads));
  const _selectSubscriptionHistory = JSON.parse(useSelector(selectSubscriptionHistory));
  // PARSED JSON

  // MEMOS
  const _courseCategories = useMemo(() => _selectCourseCategories, [_selectCourseCategories]);
  const _token = useMemo(() => _selectToken, [_selectToken]);
  const _allCourses = useMemo(() => _selectAllCourses, [_selectAllCourses]);
  const _user = useMemo(() => _selectUser, [_selectUser]);
  const _lessons = useMemo(() => _selectLessons, [_selectLessons]);
  const _allGeneralThreads = useMemo(() => _selectAllGeneralThreads, [_selectAllGeneralThreads]);
  const userIsInstructor = useMemo(() => {
    if (_user) {
      return _user.attributes.role[0] === 'Instructor'
    }
    return false;
  }, [_user]);
  // MEMOS

  useEffect(() => {
    if (loggedIn === true) {
      if (location.pathname.indexOf('dashboard/course/') !== -1) {
        document.body.classList.remove("bg-theme-grey");
        document.body.classList.add("bg-theme-white");
      } else {
        document.body.classList.remove("bg-theme-white");
        document.body.classList.add("bg-theme-grey");
      }
    } else {
      document.body.classList.remove("bg-theme-grey");
    }
  });
  
  return (
    <div className={'App'}>
      <Routes>
        
        <Route 
        path="/login" 
        element={
          loggedIn 
          ? 
          <Navigate to="/dashboard" replace /> 
          : 
          <Login loggedIn={loggedIn} BASE_URL={BASE_URL} navigate={navigate} />} 
        />

        <Route 
        path="/forgotpassword" 
        element={
          loggedIn 
          ? 
          <Navigate to="/dashboard" replace /> 
          : 
          <ForgotPassword loggedIn={loggedIn} BASE_URL={BASE_URL} />} 
        />

        <Route 
        path="/verify-account" 
        element={
          loggedIn 
          ? 
          <Navigate to="/dashboard" replace /> 
          : 
          <VerifyAccount loggedIn={loggedIn} BASE_URL={BASE_URL} navigate={navigate} />} 
        />

        <Route 
        path="/passwordreset" 
        element={
          loggedIn 
          ? 
          <Navigate to="/dashboard" replace /> 
          : 
          <PasswordReset loggedIn={loggedIn} BASE_URL={BASE_URL} />} 
        />

        <Route 
        path="/contact" 
        element={<Contact loggedIn={loggedIn} BASE_URL={BASE_URL} />} 
        />

        <Route 
        path="/signup" 
        element={
          loggedIn 
          ? 
          <Navigate to="/dashboard" replace /> 
          : 
          <Signup loggedIn={loggedIn} BASE_URL={BASE_URL} navigate={navigate} />}
        />

        <Route 
        path="/about" 
        element={<About loggedIn={loggedIn} />} 
        />

        <Route 
        path="/faqs" 
        element={<FAQs loggedIn={loggedIn} />} 
        />

        <Route 
        path="/general-forum" 
        element={ 
          loggedIn
          ?
          <Forum 
          loggedIn={loggedIn} 
          navigate={navigate} 
          BASE_URL={BASE_URL}
          _token={_token}
          fetchGeneralThreads={fetchGeneralThreads}
          _allGeneralThreads={_allGeneralThreads} />
          :
          <Navigate navigate={navigate} to="/login" replace />
        } 
        />

        <Route 
        path="/general-forum/:threadId" 
        element={ 
          loggedIn
          ?
          <Thread 
          loggedIn={loggedIn} 
          navigate={navigate} 
          BASE_URL={BASE_URL}
          _user={_user}
          _token={_token} />
          :
          <Navigate navigate={navigate} to="/login" replace />
        } 
        />

        <Route 
        path="/checkout/:courseId" 
        element={
          userIsInstructor === false
          ?
          <Checkout 
          loggedIn={loggedIn} 
          navigate={navigate} 
          BASE_URL={BASE_URL}
          _courseCategories={_courseCategories} 
          fetchAllCourses={fetchAllCourses}
          fetchCourseCategories={fetchCourseCategories}
          _user={_user}
          fetchSubscriptionHistory={fetchSubscriptionHistory}
          _token={_token}
          _allCourses={_allCourses} />
          :
          <Navigate navigate={navigate} to="/" replace />
        }  
        />

        <Route 
        path="/checkout/:courseId/pay" 
        element={
          loggedIn
          ?
            userIsInstructor === false
            ?
            <Pay 
            loggedIn={loggedIn} 
            navigate={navigate} 
            BASE_URL={BASE_URL}
            _courseCategories={_courseCategories} 
            fetchAllCourses={fetchAllCourses}
            fetchCourseCategories={fetchCourseCategories}
            _user={_user}
            fetchSubscriptionHistory={fetchSubscriptionHistory}
            _token={_token}
            _allCourses={_allCourses} />
            :
            <Navigate navigate={navigate} to="/" replace />
          :
          <Navigate navigate={navigate} to="/login" replace />
        }  
        />

        {/* AUTH ROUTES */}
        {/* <Route path="/dashboard/course-categories/add" element={ loggedIn ? <AddCourseCategory navigate={navigate} BASE_URL={BASE_URL} _user={_user} _token={_token} /> : <Navigate navigate={navigate} to="/login" replace /> } />
        <Route path="/dashboard/course-categories" element={ loggedIn ? <CourseCategories BASE_URL={BASE_URL} _user={_user} _courseCategories={_courseCategories} fetchCourseCategories={fetchCourseCategories} /> : <Navigate navigate={navigate} to="/login" replace /> } /> */}
        <Route 
        path="/dashboard/courses/:uid/edit" 
        element={
          loggedIn 
          ? 
          <EditCourse 
          BASE_URL={BASE_URL} 
          _allCourses={_allCourses} 
          _user={_user} 
          navigate={navigate} 
          _token={_token} 
          _courseCategories={_courseCategories} 
          fetchCourseCategories={fetchCourseCategories} 
          fetchAllCourses={fetchAllCourses} /> 
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />

        <Route 
        path="/dashboard/courses/:uid/add" 
        element={
          loggedIn 
          ? 
          <AddCourseLesson 
          BASE_URL={BASE_URL} 
          fetchLessons={fetchLessons} 
          _lessons={_lessons} 
          _allCourses={_allCourses} 
          _user={_user} 
          navigate={navigate} 
          _token={_token} 
          _courseCategories={_courseCategories} 
          fetchCourseCategories={fetchCourseCategories} 
          fetchAllCourses={fetchAllCourses} /> 
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />
        
        <Route 
        path="/dashboard/courses/:uid" 
        element={
          loggedIn 
          ? 
          <CourseLessons 
          BASE_URL={BASE_URL} 
          fetchLessons={fetchLessons} 
          _lessons={_lessons} 
          _allCourses={_allCourses} 
          _user={_user} 
          navigate={navigate} 
          _token={_token} 
          _courseCategories={_courseCategories} 
          fetchCourseCategories={fetchCourseCategories} 
          fetchAllCourses={fetchAllCourses} /> 
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />
        
        <Route 
        path="/dashboard/courses/add" 
        element={
          loggedIn 
          ? 
          <AddCourse 
          BASE_URL={BASE_URL} 
          _user={_user} 
          navigate={navigate} 
          _token={_token} 
          _courseCategories={_courseCategories} 
          fetchCourseCategories={fetchCourseCategories} /> 
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />
        
        <Route 
        path="/dashboard/courses" 
        element={
          loggedIn 
          ? 
          <Courses 
          BASE_URL={BASE_URL} 
          fetchAllCourses={fetchAllCourses} 
          _allCourses={_allCourses} 
          _user={_user} 
          _token={_token} /> 
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />
        
        <Route 
        path="/dashboard/profile" 
        element={
          loggedIn 
          ? 
          <Profile 
          BASE_URL={BASE_URL} 
          fetchAllCourses={fetchAllCourses} 
          fetchCourseCategories={fetchCourseCategories} 
          _token={_token} /> 
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />
        
        <Route 
        path="/dashboard/course/:uid" 
        element={
          loggedIn 
          ? 
          (
            userIsInstructor
            ?
            <Navigate navigate={navigate} to="/login" replace />
            :
            <SingleCourse 
            BASE_URL={BASE_URL} 
            fetchAllCourses={fetchAllCourses} 
            fetchCourseCategories={fetchCourseCategories}
            navigate={navigate} 
            _user={_user}
            _token={_token} />
          )
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />

        <Route 
        path="/dashboard/purchases" 
        element={
          loggedIn 
          ? 
          (
            userIsInstructor
            ?
            <Navigate navigate={navigate} to="/login" replace />
            :
            <StudentPurchases 
            BASE_URL={BASE_URL} 
            _selectSubscriptionHistory={_selectSubscriptionHistory}
            fetchSubscriptionHistory={fetchSubscriptionHistory}
            _token={_token} />
          )
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />
        
        <Route 
        path="/dashboard" 
        element={
          loggedIn 
          ?
          ( 
          userIsInstructor
          ?
          <InstructorDashboard 
          BASE_URL={BASE_URL} 
          _allCourses={_allCourses} 
          _user={_user}
          fetchAllCourses={fetchAllCourses} 
          fetchCourseCategories={fetchCourseCategories} 
          _selectSubscriptionHistory={_selectSubscriptionHistory}
          fetchSubscriptionHistory={fetchSubscriptionHistory}
          userIsInstructor={userIsInstructor}
          _token={_token} /> 
          :
          <StudentDashboard 
          BASE_URL={BASE_URL} 
          fetchAllCourses={fetchAllCourses} 
          fetchCourseCategories={fetchCourseCategories} 
          _selectSubscriptionHistory={_selectSubscriptionHistory}
          fetchSubscriptionHistory={fetchSubscriptionHistory}
          userIsInstructor={userIsInstructor}
          _token={_token} />
          )
          : 
          <Navigate navigate={navigate} to="/login" replace />} 
        />

        {/* AUTH ROUTES */}

        {/* <Route 
        path="/" 
        element={
          loggedIn 
          ? 
          <Navigate navigate={navigate} to="/dashboard" replace /> 
          : 
          <Index navigate={navigate} />} 
        /> */}
        <Route 
        path="/" 
        element={ 
        <Index 
        loggedIn={loggedIn} 
        navigate={navigate} 
        BASE_URL={BASE_URL}
        _courseCategories={_courseCategories} 
        fetchCourseCategories={fetchCourseCategories}
        fetchAllCourses={fetchAllCourses}
        _allCourses={_allCourses} />} 
        />
      </Routes>
    </div>
  );
}

export default App;
