function CarouselItem({course}) {
    return (
        <div className="duration-300">
            <div style={{
                    height: 200,
                    background: `url(${course.attributes.cover_image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }} className="items-center flex-wrap relative rounded-lg">
                {/* <img alt="recommended-course" src={course.attributes.cover_image} className="rounded-lg object-contain object-center w-full mx-auto" /> */}
                <div className="flex-grow flex flex-col sm:text-left sm:w-1/2 absolute bottom-2 left-2">
                    <span className="text-left ml-2 text-md text-white title-font font-medium main-text mb-3">
                        {course.attributes.title}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default CarouselItem;