import axios from 'axios';

export async function fetchAllCoursesAPI({BASE_URL, token}) {
    try {
        const RESPONSE = await axios.get(`${BASE_URL}courses`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return RESPONSE;
    } catch (error) {
        //
    }
}

export async function fetchCourseCategoriesAPI({BASE_URL}) {
    try {
        const RESPONSE = await axios.get(`${BASE_URL}categories`);
        return RESPONSE;
    } catch (error) {
        //
    }
}