import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGeneralThreadsAPI } from './threadsAPI';

const initialState = {
  allThreads: localStorage.getItem('allThreads') || '[]',
  status: 'idle'
};

export const fetchGeneralThreads = createAsyncThunk(
    'threads/fetchGeneralThreads',
    async (payload) => {
        const RESPONSE = await fetchGeneralThreadsAPI(payload);
        // The value we return becomes the `fulfilled` action payload
        return JSON.stringify(RESPONSE.data.data);
    }
);

export const generalThreadsSlice = createSlice({
    name: 'generalThreads',
    initialState,
    reducers: {
        storeLogout: (state) => {
            state.allThreads = '[]';
            localStorage.setItem('allThreads', '[]');
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchGeneralThreads.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchGeneralThreads.fulfilled, (state, action) => {
            state.status = 'idle';
            state.allThreads = action.payload;
            localStorage.setItem('allThreads', action.payload);
        });
    }
});

export const { storeLogout } = generalThreadsSlice.actions;

export const selectAllGeneralThreads = state => state.generalThreads.allThreads;

export default generalThreadsSlice.reducer;
