import { Link, useLocation } from "react-router-dom";
import { selectToken, logout } from '../app/reducers/user/userSlice';
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { storeLogout as logoutCourses } from "../app/reducers/courses/coursesSlice";

function AboutNavbar({loggedIn}) {
    const location = useLocation();
    const dispatch = useDispatch();
    const BASE_URL = 'http://lex-study-api.herokuapp.com/api/v1/';
    const _selectToken = JSON.parse(useSelector(selectToken));
    const _token = useMemo(() => _selectToken, [_selectToken]);

    const handleLogout = () => {
        dispatch(logout({BASE_URL, token: _token})).then(() => {
            dispatch(logoutCourses());
        })
    }

    const closeNav = () => {
        document.querySelector('.navbar-close').click();
    }

    useEffect(() => {
        // Burger menus
        // open
        const burger = document.querySelectorAll('.navbar-burger');
        const menu = document.querySelectorAll('.navbar-menu');
        if (burger.length && menu.length) {
            for (var i = 0; i < burger.length; i++) {
                burger[i].addEventListener('click', function() {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }
        // close
        const close = document.querySelectorAll('.navbar-close');
        const backdrop = document.querySelectorAll('.navbar-backdrop');
        if (close.length) {
            for (var i = 0; i < close.length; i++) {
                close[i].addEventListener('click', function() {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }
        if (backdrop.length) {
            for (var i = 0; i < backdrop.length; i++) {
                backdrop[i].addEventListener('click', function() {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }
    }, [])

    return (
        <div>
            <nav style={{background: '#FFFFFF'}} className="guest-nav fixed w-full z-20 lg:px-10 px-4 py-4 flex justify-between items-center">
                <Link to="/" className="text-3xl font-bold leading-none">
                    <img className="main-logo" src="/logo.png" alt="" />
                </Link>
                <div className="xl:hidden">
                    <button className="navbar-burger flex items-center second-color p-3">
                        <svg style={ { opacity: 1 } } fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6 block h-4 w-4 fill-current">
                            <title>Mobile menu</title>
                            <path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                            <path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" style={ { display: 'none' } }></path>
                        </svg>
                    </button>
                </div>
                <ul className="hidden xl:mx-auto xl:flex xl:items-center xl:w-auto xl:space-x-6">
                    {
                        loggedIn
                        &&
                        (
                            <>
                                <li><Link to="/dashboard">Dashboard</Link></li>
                                <li className="text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                    </svg>
                                </li>
                            </>
                        )

                    }
                    <li><Link className={ location.pathname === '/' ? 'active text-lg' : 'text-lg' } to="/">Home</Link></li>
                    <li className="text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </li>
                    <li><Link className="text-lg" to="/">Forum</Link></li>
                    <li className="text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </li>
                    {
                        loggedIn === false
                        &&
                        (
                            <>
                                <li><Link to="/about"  className={ location.pathname === '/about' ? 'active text-lg' : 'text-lg' }>About Us</Link></li>
                                <li className="text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                    </svg>
                                </li>
                                <li><Link to="/faqs" className={ location.pathname === '/faqs' ? 'active text-lg' : 'text-lg' }>FAQs</Link></li>
                                <li className="text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                    </svg>
                                </li>
                                <li><Link to="/contact" className={ location.pathname === '/contact' ? 'active text-lg' : 'text-lg' }>Contact Us</Link></li>
                                <li className="text-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" className="w-4 h-4 current-fill" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                                    </svg>
                                </li>
                            </>
                        )
                    }
                </ul>
                {
                    loggedIn
                    ?
                    <span onClick={() => handleLogout()} className="hidden xl:inline-block xl:ml-auto xl:mr-3 text-lg px-5 py-3 pointer">
                        Sign Out
                    </span>
                    :
                    <>
                        <Link to="/login" className="hidden xl:inline-block xl:ml-auto xl:mr-3 text-lg px-5 py-3">
                            Sign In
                        </Link>
                        <Link to="/signup" className="hidden xl:inline-block py-2 px-3 main-color text-lg main-button">
                            Create Account
                        </Link>
                    </>
                }
            </nav>
            <div className="navbar-menu relative z-50 hidden">
                <div className="xl:hidden navbar-backdrop fixed inset-0 bg-gray-800 opacity-25 xl:opacity-0"></div>
                <nav className="border-radius-sm rounded-tr-lg rounded-br-lg fixed top-0 left-0 bottom-0 flex flex-col xl:w-1/6 w-5/6 max-w-sm pt-6 pb-6 xl:pb-0 bg-white border-r overflow-y-auto">
                    <div className="flex items-center mb-8 px-6">
                        <div className="mr-auto" onClick={() => closeNav()}>
                            <Link to={'/'} className="mr-auto text-3xl font-bold leading-none">
                                <img className="w-3/4"src="/logo.png" alt="" />    
                            </Link>
                        </div>
                        <button className="navbar-close xl:hidden">
                            <i className="fas fa-times second-color"></i>
                        </button>
                    </div>
                    <div>
                        <div className="mb-6 px-1 mt-4 opacity-0">
                            <span className="main-color pl-5">menu</span>
                        </div>
                        <ul className="mobile-menu">
                            <li className={`${location.pathname === '/' ? 'active font-bold' : null} mb-1 px-1`}>
                                <Link to={'/'} className="block p-4 text-sm font-semibold main-text rounded flex flex-wrap">
                                    <span className="w-1/6">
                                        <i className={`${location.pathname === '/' ? 'fas' : 'far'} fa-home`}></i>
                                    </span>
                                    <span className="w-5/6">
                                        Home
                                    </span>
                                </Link>
                            </li>
                            {
                                loggedIn
                                &&
                                <li className={`${location.pathname === '/dashboard' ? 'active font-bold' : null} mb-1 px-1`}>
                                    <Link to={'/dashboard'} className="block p-4 text-sm font-semibold main-text rounded flex flex-wrap">
                                        <span className="w-1/6">
                                            <i className={`${location.pathname === '/dashboard' ? 'fas' : 'far'} fa-gauge`}></i>
                                        </span>
                                        <span className="w-5/6">
                                            Dashboard
                                        </span>
                                    </Link>
                                </li>
                            }
                            <li className={`${location.pathname === '/general-forum' ? 'active font-bold' : null} mb-1 px-1`}>
                                <Link to={'/general-forum'} className="block p-4 text-sm font-semibold main-text rounded flex flex-wrap">
                                    <span className="w-1/6">
                                        <i className={`${location.pathname === '/general-forum' ? 'fas' : 'far'} fa-comments`}></i>
                                    </span>
                                    <span className="w-5/6">
                                        Forum
                                    </span>
                                </Link>
                            </li>
                            {
                                loggedIn
                                ?
                                <>
                                        <li onClick={() => closeNav()} className="mb-1 px-1">
                                        <a href="#" onClick={() => handleLogout()} className="block p-4 text-sm font-semibold main-text rounded flex flex-wrap items-center">
                                            <span className="w-1/6">
                                                <i className="far fa-power-off"></i>
                                            </span>
                                            <span className="w-5/6">
                                                Logout
                                            </span>
                                        </a>
                                    </li>
                                </>
                                :
                                <>
                                    <li className={`${location.pathname === '/about' ? 'active font-bold' : null} mb-1 px-1`}>
                                        <Link to={'/about'} className="block p-4 text-sm font-semibold main-text rounded flex flex-wrap">
                                            <span className="w-1/6">
                                                <i className={`${location.pathname === '/about' ? 'fas' : 'far'} fa-address-card`}></i>
                                            </span>
                                            <span className="w-5/6">
                                                About
                                            </span>
                                        </Link>
                                    </li>
                                    <li className={`${location.pathname === '/contact' ? 'active font-bold' : null} mb-1 px-1`}>
                                        <Link to={'/contact'} className="block p-4 text-sm font-semibold main-text rounded flex flex-wrap">
                                            <span className="w-1/6">
                                                <i className={`${location.pathname === '/contact' ? 'fas' : 'far'} fa-headset`}></i>
                                            </span>
                                            <span className="w-5/6">
                                                Contact
                                            </span>
                                        </Link>
                                    </li>
                                    <li className={`${location.pathname === '/login' ? 'active font-bold' : null} mb-1 px-1`}>
                                        <Link to={'/login'} className="block p-4 text-sm font-semibold main-text rounded flex flex-wrap">
                                            <span className="w-1/6">
                                                <i className={`${location.pathname === '/login' ? 'fas' : 'far'} fa-right-to-bracket`}></i>
                                            </span>
                                            <span className="w-5/6">
                                                Sign In
                                            </span>
                                        </Link>
                                    </li>
                                    <li className={`${location.pathname === '/signup' ? 'active font-bold' : null} mb-1 px-1`}>
                                        <Link to={'/signup'} className="block p-4 text-sm font-semibold main-text rounded flex flex-wrap">
                                            <span className="w-1/6">
                                                <i className={`${location.pathname === '/signup' ? 'fas' : 'far'} fa-user-plus`}></i>
                                            </span>
                                            <span className="w-5/6">
                                                Create Account
                                            </span>
                                        </Link>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default AboutNavbar;