import AuthNavbar from "../../../../components/AuthNavbar";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
function EditCourse({ navigate, _token, _courseCategories, _user, BASE_URL, fetchCourseCategories, fetchAllCourses, _allCourses }) {
    const dispatch = useDispatch();
    const { uid } = useParams();

    const [currentCourse, setCurrentCourse] = useState(null);
    const [categoryId, setCategoryId] = useState('');
    const [courseTitle, setCourseTitle] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [lessonsNumber, setLessonsNumber] = useState('');
    const [completionPeriod, setCompletionPeriod] = useState('');
    const [actualPrice, setActualPrice] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');

    const [quiz, setQuiz] = useState([
        {
            question: '',
            options: [
                '',
                '',
                '',
                ''
            ],
            answer: ''
        }
    ]);
    const handleQuestionInput = (e, index) => {
        let newQuiz = [...quiz];
        newQuiz[index].question = e.target.value;
        setQuiz(newQuiz);
    }

    const handleOptionInput = (e, index, optionIndex) => {
        let newQuiz = [...quiz];
        newQuiz[index].options[optionIndex] = e.target.value;
        setQuiz(newQuiz);
    }

    const handleAnswerInput = (e, index) => {
        let newQuiz = [...quiz];
        newQuiz[index].answer = e.target.value;
        setQuiz(newQuiz);
    }

    const addQuestion = () => {
        const newQuiz = [...quiz];
        newQuiz.splice(
            newQuiz.length,
            0,
            {
                question: '',
                options: [
                    '',
                    '',
                    '',
                    ''
                ],
                answer: ''
            }
        );
        setQuiz(newQuiz);
    }

    const removeQuestion = index => {
        const newQuiz = [...quiz];
        newQuiz.splice(
            index,
            1
        );
        setQuiz(newQuiz);
    }

    const [submitText, setSubmitText] = useState('Update');
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleCategoryIdInput = e => setCategoryId(e.target.value);
    const handleCourseTitleInput = e => setCourseTitle(e.target.value);
    const handleCourseDescriptionInput = e => setCourseDescription(e.target.value);
    const handleLessonsNumberInput = e => setLessonsNumber(e.target.value);
    const handleCompletionPeriodInput = e => setCompletionPeriod(e.target.value);
    const handleActualPriceInput = e => setActualPrice(e.target.value);
    const handleDiscountPriceInput = e => setDiscountPrice(e.target.value);

    const updateCourse = () => {
        setSubmitText('');
        setSubmitLoading(true);
        let FORMDATA = new FormData();

        quiz.forEach((question, index) => {
            FORMDATA.append(`quiz[${index}][question]`, question.question);
            question.options.forEach((option, optionIndex) => {
                FORMDATA.append(`quiz[${index}][options][${optionIndex}]`, option);
            });
            FORMDATA.append(`quiz[${index}][answer]`, question.answer);
        });

        FORMDATA.append('course_id', currentCourse.id);
        FORMDATA.append('category_id', categoryId);
        FORMDATA.append('title', courseTitle);
        FORMDATA.append('description', courseDescription);
        FORMDATA.append('actual_price', actualPrice);
        FORMDATA.append('discount_price', discountPrice);
        FORMDATA.append('period_of_completion', completionPeriod);
        FORMDATA.append('number_of_lessons', lessonsNumber);
        FORMDATA.append('cover_image', document.getElementById('cover-image').files[0]);
        axios
            .post(`${BASE_URL}courses/update`, FORMDATA,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${_token}`
                    }
                })
            .then(() => {
                setSubmitLoading(false);
                setSubmitText('Update');
                Swal.fire({
                    title: 'Success',
                    text: 'The course was updated successfully',
                    icon: 'success',
                    confirmButtonColor: '#002979',
                });
                navigate('/dashboard/courses', { replace: true });
            })
            .catch(err => {
                setSubmitLoading(false);
                setSubmitText('Update');
                let errorMsg = err.response.data ? err.response.data.message : null;
                Swal.fire({
                    title: 'Error',
                    text: errorMsg || 'Course update failed',
                    icon: 'error',
                    confirmButtonColor: '#002979',
                });
            })
            .then(() => {
                //
            });
    }

    useEffect(() => {
        let course = _allCourses.filter(course => {
            return course.id.toString() === uid.toString()
        });
        if (course.length) {
            setCurrentCourse(course[0]);

            dispatch(fetchCourseCategories({ BASE_URL }));
            dispatch(fetchAllCourses({ BASE_URL }));

            setCategoryId(course[0].attributes.category.id);
            setCourseTitle(course[0].attributes.title);
            setCourseDescription(course[0].attributes.description);
            setActualPrice(course[0].attributes.actual_price);
            setDiscountPrice(course[0].attributes.discount_price);
            setLessonsNumber(10);
            setCompletionPeriod(course[0].attributes.period_of_completion);
            setQuiz(course[0].attributes.quiz);
            setTimeout(() => {
                course[0].attributes.quiz.forEach((q, i) => {
                    document.getElementById('question-title-'+i).value = q.question;
                    document.getElementById('question-title-option-1-'+i).value = q.options[0];
                    document.getElementById('question-title-option-2-'+i).value = q.options[1];
                    document.getElementById('question-title-option-3-'+i).value = q.options[2];
                    document.getElementById('question-title-option-4-'+i).value = q.options[3];
                    document.getElementById('question-title-answer-'+i).value = q.answer;
                });
            }, 1000);
        } else {
            setCurrentCourse(null);
            navigate('/dashboard/courses', { replace: true });
        }
    },  [_allCourses, uid, dispatch, fetchCourseCategories, BASE_URL, fetchAllCourses, navigate]);

    useEffect(() => {
        document.title = 'Edit Course - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthNavbar />
            <div className="pt-16 lg:pt-24 relative z-10 mx-auto">
                <div
                    className="px-0 py-2 xl:ml-72 xl:mr-10 mr-5 ml-5 z-20"
                >
                    <div className="mt-2 mb-16 mr-5">
                        <form className="p-2 lg:p-10">
                            <div className="flex flex-wrap mb-6">
                                <div className="flex flex-wrap mb-6">
                                    <div className="w-full self-center mb-2">
                                        <h2 style={{ fontSize: '30px' }}>Edit Course</h2>
                                    </div>
                                </div>
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="category">Course Category </label>
                                </div>
                                <div className="w-full">
                                    <select value={categoryId} onChange={handleCategoryIdInput} id="category" className="w-full rounded-md  px-3 border py-4">
                                        {
                                            _courseCategories.map((category, index) => {
                                                return <option key={index} value={category.id}>{category.attributes.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="course-title">Course Title </label>
                                </div>
                                <div className="w-full">
                                    <input value={courseTitle} onChange={handleCourseTitleInput} id="course-title" className="w-full rounded-md  px-3 border py-4" type="text" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="course-description">Course Description </label>
                                </div>
                                <div className="w-full">
                                    <textarea value={courseDescription} onChange={handleCourseDescriptionInput} id="course-description" className="w-full rounded-md  px-3 border py-4"></textarea>
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="actual-price">Actual Price </label>
                                </div>
                                <div className="w-full">
                                    <input value={actualPrice} onChange={handleActualPriceInput} id="actual-price" className="w-full rounded-md  px-3 border py-4" type="number" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="discount-price">Discount Price </label>
                                </div>
                                <div className="w-full">
                                    <input value={discountPrice} onChange={handleDiscountPriceInput} id="discount-price" className="w-full rounded-md  px-3 border py-4" type="number" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="number-of-lessons">Number of lessons </label>
                                </div>
                                <div className="w-full">
                                    <input value={lessonsNumber} onChange={handleLessonsNumberInput} id="number-of-lessons" className="w-full rounded-md  px-3 border py-4" type="number" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="cover-image">Cover image </label>
                                </div>
                                <div className="w-full">
                                    <input id="cover-image" className="w-full rounded-md  px-3 border py-4 bg-white" type="file" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="period-of-completion">Period of completion </label>
                                </div>
                                <div className="w-full">
                                    <select value={completionPeriod} onChange={handleCompletionPeriodInput} id="period-of-completion" className="w-full rounded-md  px-3 border py-4">
                                        <option value="10 minutes">10 minutes</option>
                                        <option value="20 minutes">20 minutes</option>
                                        <option value="30 minutes">30 minutes</option>
                                        <option value="45 minutes">45 minutes</option>
                                        <option value="1 hour">1 hour</option>
                                        <option value="2 hours">2 hours</option>
                                        <option value="3 hours">3 hours</option>
                                        <option value="4 hours">4 hours</option>
                                        <option value="6 hours">6 hours</option>
                                        <option value="10 hours">10 hours</option>
                                        <option value="12 hours">12 hours</option>
                                        <option value="18 hours">18 hours</option>
                                        <option value="1 day">1 day</option>
                                        <option value="2 days">2 days</option>
                                        <option value="3 days">3 days</option>
                                        <option value="5 days">5 days</option>
                                        <option value="7 days">7 days</option>
                                        <option value="14 days">14 days</option>
                                        <option value="18 days">18 days</option>
                                        <option value="21 days">21 days</option>
                                        <option value="25 days">25 days</option>
                                        <option value="1 month">1 month</option>
                                        <option value="2 months">2 months</option>
                                        <option value="3 months">3 months</option>
                                        <option value="4 months">4 months</option>
                                        <option value="5 months">5 months</option>
                                        <option value="6 months">6 months</option>
                                        <option value="9 months">9 months</option>
                                        <option value="12 months">12 months</option>
                                    </select>
                                </div>
                            </div>

                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <h2 style={{ fontSize: '30px' }}>Quiz Setup</h2>
                                </div>
                            </div>
                            
                            {
                                quiz.map((q, index) => {
                                    return (
                                        <div id={'quiz-'+index} key={index}>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-full self-center mb-2">
                                                    <label htmlFor={'question-title-'+index}>Question {index + 1}</label>
                                                    {
                                                        quiz.length >= 2 && <span style={{float: 'right'}} className="pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => removeQuestion()}>Remove Question -</span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input onChange={(e) => handleQuestionInput(e, index)} id={'question-title-'+index} className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor={'question-title-'+index}>Option 1</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 0)} id={'question-title-option-1-'+index} className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor={'question-title-'+index}>Option 2</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 1)} id={'question-title-option-2-'+index} className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor={'question-title-'+index}>Option 3</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 2)} id={'question-title-option-3-'+index} className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor={'question-title-'+index}>Option 4</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 3)} id={'question-title-option-4-'+index} className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-full self-center mb-2">
                                                    <label htmlFor={'question-title-'+index}>Answer </label>
                                                </div>
                                                <div className="w-full">
                                                    <select onChange={(e) => handleAnswerInput(e, index)} id={'question-title-answer-'+index} className="w-full rounded-md  px-3 border py-4">
                                                        <option disabled selected>Select answer</option>
                                                        {
                                                            quiz[index].options.map((option, index) => {
                                                                return option.length >= 1 && <option key={'option-' + index} value={option}>{option}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="w-full text-right">
                                <span className="pointer font-medium text-blue-600 dark:text-blue-500 hover:underline mb-4" onClick={() => addQuestion()}>Add Question +</span>
                            </div>
                        </form>

                        <div className="flex flex-wrap px-2 lg:px-10">
                            <div className="hidden lg:w-2/3"></div>
                            <div className="w-full lg:w-1/3">
                                <button disabled={submitLoading} onClick={() => !submitLoading && updateCourse()} className={'bodyBluish w-full p-4 rounded-xl text-white ' + (submitLoading && 'opacity-50')}>
                                    {
                                        submitLoading
                                            ?
                                            <i className="fas fa-loader fa-spin py-1"></i>
                                            :
                                            submitText
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default EditCourse;