import AuthNavbar from "../../../components/AuthNavbar";
import { useState, useMemo, useEffect } from "react";
import { selectUser } from '../../../app/reducers/user/userSlice';
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import defaultUserIcon from '../../../assets/default-user-icon.png';
import { useDispatch } from 'react-redux';
import { storeLoginCredentials } from '../../../app/reducers/user/userSlice';
function Profile({ _token, BASE_URL }) {

    const dispatch = useDispatch();

    const _selectUser = JSON.parse(useSelector(selectUser));
    const _user = useMemo(() => _selectUser, [_selectUser]);
    
    const [submitText, setSubmitText] = useState('UPDATE');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [introductionBrief, setIntroductionBrief] = useState('');
    const [qualification, setQualification] = useState('');
    const [tempUploadedFile, setTempUploadedFile] = useState('');

    const handleFirstNameInput = e => setFirstName(e.target.value);
    const handleLastNameInput = e => setLastName(e.target.value);
    const handlePhoneNumberInput = e => setPhoneNumber(e.target.value);
    const handleIntroductionBriefInput = e => setIntroductionBrief(e.target.value);
    const handleQualificationInput = e => setQualification(e.target.value);

    const updateProfile = () => {
        setSubmitText('');
        setSubmitLoading(true);
        let FORMDATA = new FormData();
        FORMDATA.append('first_name', firstName);
        FORMDATA.append('last_name', lastName);
        if (document.getElementById('profile-picture').files.length >= 1) {
            FORMDATA.append('image', document.getElementById('profile-picture').files[0]);
        }
        FORMDATA.append('phone_number', phoneNumber);
        FORMDATA.append('introduction_brief', introductionBrief);
        FORMDATA.append('qualification', qualification);

        axios
            .post(`${BASE_URL}users/update-profile`, FORMDATA,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${_token}`
                    }
                })
            .then(res => {
                console.log(res.data.user);
                dispatch(storeLoginCredentials({ user: res.data.user, token: _token }));
                setSubmitLoading(false);
                setSubmitText('UPDATE');
                Swal.fire({
                    title: 'Success',
                    text: 'Profile updated successfully',
                    icon: 'success',
                    confirmButtonColor: '#002979',
                });
                // navigate('/dashboard/courses', { replace: true });
            })
            .catch(err => {
                setSubmitLoading(false);
                setSubmitText('UPDATE');
                let errorMsg = err.response.data ? err.response.data.message : null;
                Swal.fire({
                    title: 'Error',
                    text: errorMsg || 'Profile update failed',
                    icon: 'error',
                    confirmButtonColor: '#002979',
                });
            })
            .then(() => {
                //
            });
    }

    const selectImage = () => {
        document.getElementById('profile-picture').click();
    }

    const updateProfilePicture = async () => {
        const imageFile = document.getElementById('profile-picture').files[0];
        const reader = new FileReader();
        try {
            await reader.readAsDataURL(imageFile);
            setTimeout(() => {
                setTempUploadedFile(reader.result);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        document.title = 'Profile - Lexstudy';
        window.scrollTo(0, 0);
        setFirstName(_user.attributes.first_name);
        setLastName(_user.attributes.last_name);
        setPhoneNumber(_user.attributes.phone_number);
        setIntroductionBrief(_user.attributes.introduction_brief);
        setQualification(_user.attributes.qualification);
    }, []);

    return (
        <>
            <AuthNavbar />
            <div className="pt-10 lg:pt-24 relative z-10 mx-auto">
                <div
                    className="px-0 py-2 xl:ml-72 xl:mr-10 lg:mx-5 z-20"
                >
                    <div className="mt-2 mb-16 lg:mr-5">
                        <form className="p-10">
                            <div className="text-center mb-6">
                                <div className="mb-2" style={{display: 'inline-flex'}}>
                                    <img style={{background: '#FFFFFF', width: 56, height: 56 }} className="rounded-full" src={tempUploadedFile || _user.attributes.image || defaultUserIcon} alt="default user icon" />
                                    <span className="pointer" onClick={selectImage} style={{alignSelf: 'center'}}><i className="fal fa-edit ml-2"></i></span>
                                </div>
                                <div className="mb-2">
                                    <span className="font-bold text-black text-lg">
                                        {`${_user.attributes.first_name} ${_user.attributes.last_name}`}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-wrap lg:mb-6">
                                <div className="w-full lg:w-1/2 flex flex-wrap mb-4">
                                    <div className="md:mx-4 w-full">
                                        <div className="w-full self-center mb-2">
                                            <label htmlFor="course-firstname">First Name </label>
                                        </div>
                                        <div className="w-full">
                                            <input value={firstName} id="course-firstname" className="w-full rounded-md indent-2.5 px-3 border py-4" type="text" onChange={handleFirstNameInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2 flex flex-wrap mb-4">
                                    <div className="md:mx-4 w-full">
                                        <div className="w-full self-center mb-2">
                                            <label htmlFor="course-lastname">Last Name </label>
                                        </div>
                                        <div className="w-full">
                                            <input value={lastName} id="course-lastname" className="w-full rounded-md indent-2.5 px-3 border py-4" type="text" onChange={handleLastNameInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2 flex flex-wrap mb-4">
                                    <div className="md:mx-4 w-full">
                                        <div className="w-full self-center mb-2">
                                            <label htmlFor="course-phone">Phone Number </label>
                                        </div>
                                        <div className="w-full">
                                            <input value={phoneNumber} id="course-phone" className="w-full rounded-md indent-2.5 px-3 border py-4" type="text" onChange={handlePhoneNumberInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2 flex flex-wrap mb-4">
                                    <div className="md:mx-4 w-full">
                                        <div className="w-full self-center mb-2">
                                            <label htmlFor="course-intro-brief">Introduction Brief </label>
                                        </div>
                                        <div className="w-full">
                                            <input value={introductionBrief} id="course-intro-brief" className="w-full rounded-md indent-2.5 px-3 border py-4" type="text" onChange={handleIntroductionBriefInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2 flex flex-wrap mb-4">
                                    <div className="md:mx-4 w-full">
                                        <div className="w-full self-center mb-2">
                                            <label htmlFor="course-qualification">Qualification </label>
                                        </div>
                                        <div className="w-full">
                                            <select onChange={handleQualificationInput} id="highest_qualification" className="w-full rounded-md indent-2.5 px-3 border py-4" type="text">
                                                <option value="O-Level Certificate">O-Level Certificate</option>
                                                <option value="OND">OND</option>
                                                <option value="HND">HND</option>
                                                <option value="BSC">BSC</option>
                                                <option value="PGD">PGD</option>
                                                <option value="MSC">MSC</option>
                                                <option value="PHD">PHD</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <input onChange={updateProfilePicture} className="d-none" type="file" id="profile-picture" />
                            </div>

                        </form>

                        <div className="flex flex-wrap px-10">
                            <div className="hidden lg:block w-1/3"></div>
                            <div className="w-full lg:w-1/3">
                                <button disabled={submitLoading} onClick={() => updateProfile()} className={'text-uppercase auth-orange-button w-full p-4 rounded-xl text-white ' + (submitLoading && 'opacity-50')}>
                                    {
                                        submitLoading
                                            ?
                                            <i className="fas fa-loader fa-spin py-1"></i>
                                            :
                                            submitText
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Profile;