import { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import userIcon from "../../../assets/user.png";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
function Forum({
    loggedIn,
    fetchGeneralThreads,
    _allGeneralThreads,
    BASE_URL,
    _token
}) {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        showAllThreads: true,
        submitting: false,
        title: '',
        body: ''
    })

    const changeView = showingThreads => {
        setState({
            ...state,
            showAllThreads: showingThreads
        });
    }

    const handleTitleInput = e => {
        setState({
            ...state,
            title: e.target.value
        });
    }

    const handleBodyInput = e => {
        setState({
            ...state,
            body: e.target.value
        });
    }

    const submitThread = () => {
        setState({
            ...state,
            submitting: true
        });

        let FORMDATA = new FormData();
        FORMDATA.append('title', state.title);
        FORMDATA.append('body', state.body);
        axios.post(`${BASE_URL}threads/add-thread`, FORMDATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
            .then(() => {
                setState({
                    ...state,
                    showAllThreads: true,
                    submitting: false
                });
                Swal.fire({
                    title: 'Success',
                    text: 'The thread was created successfully',
                    icon: 'success',
                    confirmButtonColor: '#002979',
                });
                dispatch(fetchGeneralThreads({ BASE_URL, _token }));
            })
            .catch(() => {
                setState({
                    ...state,
                    submitting: false
                });
            })
            .then(() => {
                //
            });
    }

    useEffect(() => {
        dispatch(fetchGeneralThreads({ BASE_URL, _token }));
        document.title = 'General Forum - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar loggedIn={loggedIn} />

            <div className="pt-24 pb-5 lg:py-6 lg:pt-32" style={{ background: '#ffffffa6' }}>
                {
                    state.showAllThreads
                        ?
                        <div className="max-w-6xl mx-auto px-6 lg:px-8">
                            <div className="flex flex-wrap pb-4">
                                <div className="w-1/2 lg:px-10">
                                    <h2 className="text-lg lg:text-2xl font-bold mb-2">General Forum</h2>
                                </div>
                                <div className="w-1/2 text-right">
                                    <button onClick={() => changeView(false)} type="button" className="auth-orange-button py-2 px-4 text-sm">
                                        <i className="main-text far fa-plus-circle text-sm">
                                        </i> Add Thread
                                    </button>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                                {
                                    _allGeneralThreads.length > 0
                                        ?
                                        _allGeneralThreads.map((thread, index) => (
                                            <div key={'thread-' + index} className="bg-white overflow-hidden shadow rounded-lg">
                                                <div className="px-4 py-5 sm:p-6">
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex items-center">
                                                            <img className="h-10 w-10 rounded-full mr-2" src={thread.attributes.user.image} alt="" />
                                                            <Link to={`${thread.id}`}>
                                                                <h2 className="text-lg font-medium text-gray-900 hover:underline">{thread.attributes.title}</h2>
                                                            </Link>
                                                        </div>
                                                        <div className="flex items-center">
                                                            <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                                {thread.attributes.likes.length} {thread.attributes.likes.length === 1 ? 'Like' : 'Likes'}
                                                            </span>
                                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                                {thread.attributes.replies.length} {thread.attributes.replies.length === 1 ? 'Reply' : 'Replies'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <p className="text-sm text-gray-500">{thread.attributes.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div>
                                            <h3 className="text-2xl">No threads found</h3>
                                        </div>
                                }

                            </div>
                        </div>
                        :
                        <div className="max-w-6xl mx-auto px-6 lg:px-8">
                            <div className="pb-4">
                                <div className="flex flex-wrap">
                                    <div className="w-1/2">
                                        <h2 className="text-lg lg:text-2xl font-bold mb-2">Add Thread</h2>
                                    </div>
                                    <div className="w-1/2 text-right">
                                        <button onClick={() => changeView(true)} type="button" className="grey-button rounded-md py-2 px-4 text-sm">
                                            Show All Threads
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:px-10 py-6 flex flex-wrap">
                                <div className="w-full sm:px-10">
                                    <form className="sm:p-10">
                                        <div className="flex flex-wrap pt-4">
                                            <div className="w-1/6 self-center">
                                                <label htmlFor="title">Title: </label>
                                            </div>
                                            <div className="w-5/6">
                                                <input onChange={handleTitleInput} id="title" className="w-full rounded-md indent-0 px-3 border py-4" type="text" autoFocus />
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap pt-4">
                                            <div className="w-1/6 self-center">
                                                <label htmlFor="body">Body: </label>
                                            </div>
                                            <div className="w-5/6">
                                                <textarea rows={3} onChange={handleBodyInput} id="body" className="w-full rounded-md indent-0 px-3 border py-4" type="text"></textarea>
                                            </div>
                                        </div>
                                        <div className="w-full pt-8 flex flex-wrap">
                                            <div className="w-1/6 self-center"></div>
                                            <div className="w-5/6 flex flex-wrap text-right">
                                                <button
                                                    onClick={() => submitThread()}
                                                    className="w-full sm:w-1/3 rounded-lg auth-orange-button text-lg py-2"
                                                    type="button">
                                                    {
                                                        state.submitting
                                                            ?
                                                            <i className="fas fa-loader fa-spin py-1"></i>
                                                            :
                                                            <span>Submit</span>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                }
            </div>



            <Footer />
        </>
    );
}

export default Forum;