import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CarouselItem from "../../components/CarouselItem";
import CourseCard from "../../components/CourseCard";
import happyPerson from "../../assets/happy-person.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
function Index({
    navigate,
    loggedIn,
    fetchCourseCategories,
    fetchAllCourses,
    _courseCategories,
    _allCourses,
    BASE_URL
}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const options = {
        autoplay: true,
        loop: true,
        margin: 10,
        center: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            1024: {
                items: 5
            }
        }
    }
    const [loadingPage, setLoadingPage] = useState(true);
    const [searchMode, setSearchMode] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedCourses, setSearchedCourses] = useState([]);

    const searchForCourse = searchString => {
        const searchResult = _allCourses.filter(course => {
            const isTrue = course.attributes.title.toLowerCase().indexOf(searchString) !== -1;
            return isTrue;
        });
        setSearchedCourses(searchResult);
        setSearchMode(true);
    }

    const cancelSearch = () => {
        document.getElementById('searchBox').value = '';
        setSearchText('');
        setSearchedCourses([]);
        setSearchMode(false);
    }

    const getCoursesByCategory = categoryId => _allCourses.filter(course => course.attributes.category.id == categoryId);

    useEffect(() => {
        dispatch(fetchAllCourses({ BASE_URL })).then(() => {
            setTimeout(() => {
                setLoadingPage(false);
            }, 500);
        })
        dispatch(fetchCourseCategories({ BASE_URL }))
        document.title = 'Home - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (searchText.length === 0 && loadingPage === false) {
            cancelSearch();
        }
    }, [searchText])

    useEffect(() => {
        if (loadingPage === false) {
            const searchVal = searchParams.get('search');
            if (searchVal) {
                setTimeout(() => {
                    document.getElementById('searchBox').value = searchVal;
                    setSearchText(searchVal);
                    searchForCourse(searchVal);
                }, 250);
            }
        }
    }, [loadingPage])

    return (
        <>
            <Navbar loggedIn={loggedIn} />
            {
                loadingPage
                    ?
                    <div className="px-0 py-2 z-20">
                        <div>
                            <div className="flex" style={{ height: '100vh', alignItems: 'center' }}>
                                <i style={{ color: '#002979' }} className="mx-auto far fa-spinner fa-spin fa-4x"></i>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="pt-10 lg:pt-24 bodyBluish">
                            <div className="flex flex-wrap px-10">
                                <div className="w-full lg:w-1/2">
                                    <div className="px-0 lg:px-10 py-20">
                                        <h1 style={{ fontSize: '3rem' }} className="font-bold text-white">Learn Confidently </h1>
                                        <p className="text-white mt-3">
                                            Enhance Your Legal Knowledge with Engaging Online Courses
                                        </p>
                                        <div className="relative mt-5">
                                            <input onChange={e => setSearchText(e.target.value)} placeholder="Search courses" id="searchBox" className="w-full rounded px-3 border py-4 input" />
                                            <i
                                                onClick={() => searchForCourse(searchText)}
                                                style={{ backgroundColor: '#FD661F' }}
                                                className={
                                                    `${searchText.length > 0 ? 'mr-14' : null}
                                        text-center 
                                        rounded-md 
                                        pt-2 
                                        px-4 
                                        pb-8 
                                        text-white 
                                        h-8 
                                        absolute 
                                        transform 
                                        -translate-y-1/2 
                                        right-3 
                                        text-xl 
                                        top-2 
                                        cursor-pointer 
                                        fa-light 
                                        fa-magnifying-glass`
                                                }
                                            ></i>
                                            {
                                                searchText.length > 0
                                                &&
                                                <i
                                                    onClick={() => cancelSearch()}
                                                    style={{ backgroundColor: '#ccc' }}
                                                    className="
                                            text-center 
                                            rounded-md 
                                            pt-2 
                                            px-4 
                                            pb-8 
                                            text-black 
                                            h-8 
                                            absolute 
                                            transform 
                                            -translate-y-1/2 
                                            right-3 
                                            text-xl 
                                            top-2 
                                            cursor-pointer 
                                            fa-light 
                                            fa-times
                                        "
                                                ></i>
                                            }

                                        </div>
                                    </div>

                                </div>
                                <div className="hidden lg:block lg:w-1/2">
                                    <img className="w-1/2 mx-auto" src={happyPerson} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="py-5 lg:py-10">
                            <h3 className="px-10 lg:px-20 text-2xl">Recommended Courses</h3>
                            <OwlCarousel
                                style={{ backgroundColor: '#F8F8F8' }}
                                className='px-10 lg:px-20 py-5 owl-theme'
                                {...options}
                            >
                                {
                                    _allCourses.map((course, index) => (
                                        <Link key={'carousel-course' + index} to={`/checkout/${course.id}`}>
                                            <div className='c-item item cursor-pointer'>
                                                <CarouselItem className="text-center" course={course} />
                                            </div>
                                        </Link>
                                    ))
                                }
                            </OwlCarousel>
                        </div>
                        {
                            searchMode && searchText.length > 0
                                ?
                                <div className="px-10 lg:px-20 pb-10 flex flex-wrap">
                                    <div className="w-full px-0 lg:px-5 pb-2">
                                        <h2 className="font-bold text-xl">
                                            {
                                                searchedCourses.length === 0
                                                    ?
                                                    `Course like '${searchText}' not found`
                                                    :
                                                    `Search Results`
                                            }
                                        </h2>
                                    </div>
                                    {
                                        searchedCourses.map((course, index) => (
                                            <div key={'course-' + index} className="w-full lg:w-1/3 lg:px-5">
                                                <CourseCard course={course} />
                                            </div>
                                        ))
                                    }
                                </div>
                                :
                                (
                                    _courseCategories.map((category, index) => (
                                        <div key={'category-' + index} className="px-10 lg:px-20 pb-10 flex flex-wrap">
                                            <div className="w-full px-0 lg:px-5 pb-2">
                                                <h2 className="font-bold text-xl">{category.attributes.name}</h2>
                                            </div>
                                            {
                                                getCoursesByCategory(category.id).map((course, index) => (
                                                    <div key={'course-' + index} className="w-full lg:w-1/3 lg:px-5">
                                                        <CourseCard course={course} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                )
                        }

                        <div className="mx-auto px-5 bg-white min-h-sceen mb-20">
                            <div className="flex flex-col items-center" style={{ marginLeft: 20, marginRight: 20 }}>
                                <h2 className="font-bold text-5xl mt-5 tracking-tight">
                                    Embark on Your Legal Learning Journey Today
                                </h2>
                                {/* <h3 className="font-bold text-3xl ">Embark on Your Legal Learning Journey Today</h3> */}
                                <div className="text-neutral-500 text-lg mt-5">
                                    <ul style={{
                                        listStyle: 'auto'
                                    }}>
                                        <li>Stay Ahead in the Legal Arena with Cutting-Edge Online Courses</li>
                                        <li>Empower Your Legal Journey with Interactive E-Learning</li>
                                        <li>Master the Law at Your Own Pace with Our Self-Paced Online Courses</li>
                                        <li>Broaden Your Legal Horizons with a Diverse Range of Course Offerings</li>
                                        <li>Ignite Your Legal Career with Expert-Led Online Training</li>
                                        <li>Achieve Legal Excellence with Practical and Actionable Course Content</li>
                                        <li>Enhance Your Legal Skills with Real-World Case Studies and Simulations</li>
                                        <li>Accessible Legal Education for All: Learn Anytime, Anywhere</li>
                                        <li>Join a Community of Passionate Learners and Legal Professionals</li>
                                        <li>Unlock the Secrets of Legal Success through Online Learning</li>
                                        <li>Gain In-Depth Understanding of Complex Legal Concepts through Engaging Modules</li>
                                        <li>Elevate Your Legal Expertise with Specialized Continuing Education Courses</li>
                                        <li>Explore the Intersections of Law and Technology with Innovative Online Classes</li>
                                        <li>Acquire Practical Legal Skills that Set You Apart in a Competitive Industry</li>
                                        <li>Invest in Your Legal Future: Choose from a Range of Membership Options</li>
                                        <li>Unleash Your Legal Potential with Dynamic and Interactive Course Materials</li>
                                        <li>Learn from Legal Luminaries: Our Instructors Are Renowned in Their Fields</li>
                                        <li>Access Exclusive Resources, Case Studies, and Legal Research Materials</li>
                                        <li>Tailor Your Legal Education to Your Specific Interests and Career Goals</li>
                                        <li>Discover the Joy of Lifelong Learning with Legal LexStudy</li>
                                        <br />
                                        <br />
                                        Start exploring today and embrace the world of legal education with LexStudy!
                                        <br />
                                        <Link to={'/signup'} className="font-bold ">Sign up</Link> now for a free trial or contact us for any inquiries.
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen">
                            <div className="flex flex-col items-center">
                                <h2 className="font-bold text-5xl mt-5 tracking-tight">
                                    FAQs
                                </h2>
                                <p className="text-neutral-500 text-xl mt-3">
                                    Frequently asked questions
                                </p>
                            </div>
                            <div className="grid divide-y divide-neutral-200 mx-auto mt-8">
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> What is a SAAS platform?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            SAAS platform is a cloud-based software service that allows users to access and use a variety of tools and functionality.
                                        </p>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> How does billing work?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            We offers a variety of billing options, including monthly and annual subscription plans, as well as pay-as-you-go pricing for certain services. Payment is typically made through a credit card or other secure online
                                            payment method.
                                        </p>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> Can I get a refund for my subscription?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            We offers a 30-day money-back guarantee for most of its subscription plans. If you are not satisfied with your subscription within the first 30 days, you can request a full refund. Refunds for subscriptions that have
                                            been active for longer than 30 days may be considered on a case-by-case basis.
                                        </p>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> How do I cancel my subscription?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            To cancel your We subscription, you can log in to your account and navigate to the subscription management page. From there, you should be able to cancel your subscription and stop future billing.
                                        </p>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> Can I try this platform for free?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            We offers a free trial of its platform for a limited time. During the trial period, you will have access to a limited set of features and functionality, but you will not be charged.
                                        </p>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> How do I access documentation?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            Documentation is available on the company's website and can be accessed by logging in to your account. The documentation provides detailed information on how to use the , as well as code examples and other resources.
                                        </p>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> How do I contact support?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            If you need help with the platform or have any other questions, you can contact the company's support team by submitting a support request through the website or by emailing support@We.com.
                                        </p>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> Do you offer any discounts or promotions?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            We may offer discounts or promotions from time to time. To stay up-to-date on the latest deals and special offers, you can sign up for the company's newsletter or follow it on social media.
                                        </p>
                                    </details>
                                </div>
                                <div className="py-5">
                                    <details className="group">
                                        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                            <span> How do we compare to other similar services?</span>
                                            <span className="transition group-open:rotate-180">
                                                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                                    <path d="M6 9l6 6 6-6"></path>
                                                </svg>
                                            </span>
                                        </summary>
                                        <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                                            This platform is a highly reliable and feature-rich service that offers a wide range of tools and functionality. It is competitively priced and offers a variety of billing options to suit different needs and budgets.
                                        </p>
                                    </details>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
            }

        </>
    );
}

export default Index;