import { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import resetImage from "../../assets/data-security.png";
import Swal from "sweetalert2";
import axios from "axios";
import defaultUserIcon from '../../assets/default-user-icon.png';
function PasswordReset({loggedIn, BASE_URL}) {

    const useQuery = () => {
        const { search } = useLocation();
      
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const token = useQuery().get('token');

    const [submitText, setSubmitText] = useState('Reset');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const handlePasswordInput = e => setPassword(e.target.value);
    const handlePasswordInputConfirm = e => setPasswordConfirm(e.target.value);

    const handleSubmit = () => {
        setSubmitText('');
        setSubmitLoading(true);
        axios
        .post(`${BASE_URL}password/reset`, {
            password,
            password_confirmation: passwordConfirm,
            token
        })
        .then(res => {
            console.log(res);
            Swal.fire({
                title: 'Success',
                text: 'Password reset was successful',
                icon: 'success',
                confirmButtonColor: '#002979',
            });
        })
        .catch(err => {
            console.log(err);
            Swal.fire({
                title: 'Error',
                text: 'Password reset failed',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            setSubmitLoading(false);
            setSubmitText('Reset');
        })
    }

    useEffect(() => {
        document.title = 'Password Reset - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Navbar loggedIn={loggedIn} />
        <div className="py-12 bodyGrey">
            <div className="mt-10 lg:mt-16 px-5 lg:px-10 py-0 flex flex-wrap">
                <div className="w-full lg:w-5/12 bg-white">
                    <form className="p-5 lg:p-10 flex flex-wrap">
                        <div className="w-full">
                            <div className="m-auto" style={ { width: '100px', height: '100px', background: '#e5e5e5', borderRadius: '100%' } }>
                                <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                                    <img className="mx-auto" src={defaultUserIcon} alt="default user icon" />
                                </div>
                            </div>
                        </div>
                        <div className="w-full pt-8">
                            <input placeholder="Enter new password" id="password" className="w-full rounded-full indent-2.5 px-3 border py-4" type="password" onChange={handlePasswordInput} />
                        </div>
                        <div className="w-full pt-8">
                            <input placeholder="Confirm new password" id="password2" className="w-full rounded-full indent-2.5 px-3 border py-4" type="password" onChange={handlePasswordInputConfirm} />
                        </div>
                        <div className="w-full pt-12">
                            <button 
                                onClick={() => handleSubmit()}
                                className="w-full inline-flex rounded-full orange-button text-lg sm:px-12 px-4 py-4"
                                type="button">
                                    {
                                        submitLoading
                                        ?
                                        <i className="fas fa-loader fa-spin py-1"></i>
                                        :
                                        submitText
                                    }
                            </button>
                        </div>
                        <div className="w-full pt-8 flex flex-wrap">
                            <div className="w-2/3">
                                <span style={{ color: '#808080' }}>I remember my password</span>
                            </div>
                            <div className="w-1/3 text-right">
                                <Link to={'/login'} className="text-black font-bold text-lg">Login</Link>
                            </div>
                        </div>
                        {/* <div className="w-full pt-8 flex flex-wrap">
                            <div className="w-5/12 text-center relative">
                                <svg className="absolute top-2 w-full" width="200" height="2" viewBox="0 0 187 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line y1="0.5" x2="187" y2="0.5" stroke="black"/>
                                </svg>
                            </div>
                            <div className="w-2/12 text-center">
                                <i className="font-bold">Or</i>
                            </div>
                            <div className="w-5/12 text-center relative">
                                <svg className="absolute top-2 w-full" width="200" height="2" viewBox="0 0 187 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line y1="0.5" x2="187" y2="0.5" stroke="black"/>
                                </svg>
                            </div>
                        </div> */}
                    </form>
                    {/* <div className="px-10 flex flex-wrap">
                        <div className="w-5/12 text-center">
                            <svg className="mx-auto mb-3" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 0C11.1927 0 0 11.1927 0 25C0 38.8073 11.1927 50 25 50C38.8073 50 50 38.8073 50 25C50 11.1927 38.8073 0 25 0ZM23.5599 35.7318C21.9896 36.4948 20.2995 36.5755 19.6458 36.5755H19.4505H19.3099C18.2891 36.5755 13.2083 36.3411 13.2083 31.7135C13.2083 27.1667 18.7422 26.8099 20.4375 26.8099H20.4818C19.5026 25.5026 19.7057 24.1849 19.7057 24.1849C19.5843 24.192 19.4627 24.1955 19.3411 24.1953C18.7031 24.1953 17.4714 24.0938 16.4141 23.4115C15.1172 22.5781 14.4609 21.1589 14.4609 19.1953C14.4609 13.6458 20.5208 13.4219 20.5807 13.4167H26.6328V13.5495C26.6328 14.2266 25.4167 14.3594 24.5885 14.4714C24.3073 14.513 23.7422 14.5677 23.5833 14.6484C25.1172 15.4687 25.3646 16.7552 25.3646 18.6719C25.3646 20.8542 24.5104 22.0078 23.6042 22.8177C23.0417 23.3203 22.6016 23.7135 22.6016 24.2422C22.6016 24.7604 23.2057 25.2891 23.9089 25.9062C25.0573 26.9193 26.6328 28.2969 26.6328 30.6224C26.6328 33.026 25.599 34.7448 23.5599 35.7318ZM36.7188 25H32.8125V28.9062H30.2083V25H26.3021V22.3958H30.2083V18.4896H32.8125V22.3958H36.7188V25ZM20.3724 27.9948C20.236 27.9948 20.0996 28.0001 19.9635 28.0104C18.8574 28.0741 17.8039 28.5038 16.9687 29.2318C16.2031 29.9245 15.8125 30.7995 15.8672 31.6823C15.9844 33.5339 17.9714 34.6172 20.3854 34.4453C22.7604 34.2734 24.3438 32.9036 24.2292 31.0521C24.1198 29.3125 22.6068 27.9948 20.3724 27.9948ZM22.6484 17.9297C22.0182 15.7161 21.0026 15.0599 19.4245 15.0599C19.2537 15.0611 19.0839 15.0848 18.9193 15.1302C18.2344 15.3255 17.6901 15.8958 17.388 16.7448C17.0781 17.6042 17.0599 18.5 17.3255 19.5208C17.8073 21.3464 19.1042 22.6693 20.4115 22.6693C20.5824 22.6709 20.7527 22.6472 20.9167 22.599C22.3464 22.1979 23.2422 20.0156 22.6484 17.9297Z" fill="#005AE6"/>
                            </svg>
                            <span>Login with Google</span>
                        </div>
                        <div className="w-2/12 text-center"></div>
                        <div className="w-5/12 text-center">
                            <svg className="mx-auto mb-3" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 0C11.1927 0 0 11.1927 0 25C0 38.8073 11.1927 50 25 50C38.8073 50 50 38.8073 50 25C50 11.1927 38.8073 0 25 0ZM30.9219 17.276H27.1641C26.7188 17.276 26.224 17.862 26.224 18.6406V21.3542H30.9245L30.2135 25.224H26.224V36.8411H21.7891V25.224H17.7656V21.3542H21.7891V19.0781C21.7891 15.8125 24.0547 13.1589 27.1641 13.1589H30.9219V17.276Z" fill="#005AE6"/>
                            </svg>
                            <span>Login with Facebook</span>
                        </div>
                    </div> */}
                </div>
                <img className="hidden lg:block w-full sm:w-7/12" src={resetImage} alt="reset" />
            </div>
        </div>
        <Footer />
        </>
    );
}

export default PasswordReset;