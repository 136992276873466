import googlePlay from '../assets/google-play.png';
import appleStore from '../assets/apple-store.png';
import { Link } from "react-router-dom";
function Footer() {
    return (
        <>
            <div style={{ background: '#313131' }} className="text-white p-10">
                <div className='flex flex-wrap'>
                    <div className='w-full lg:w-3/4 flex flex-wrap'>
                        <div className='w-full lg:w-1/4 lg:mt-1 hidden lg:block'>
                            <div className="pb-4 opacity-0">123</div>
                            <img className="rounded-lg w-2/3" src="/logo-with-bg.png" alt="" />
                        </div>
                        <div className='w-full lg:w-2/4 lg:mt-1'>
                            <div className="mt-2 pb-2">Subscribe to get the best learning</div>
                            <div className="mr-0 lg:mr-2">
                                <input placeholder="Enter your email address" id="newsletterEmail" className="w-full rounded-lg indent-2.5 px-3 border py-4 text-black" type="email" />
                            </div>
                        </div>
                        <div className='w-full lg:w-1/4 lg:mt-1'>
                            <div className="pb-4 opacity-0 hidden lg:block">123</div>
                            <button className="footer-request py-4 px-4 rounded-lg mt-4 lg:mt-0 w-full">
                                SUBSCRIBE
                            </button>
                        </div>
                    </div>
                    <div className='w-full lg:w-1/4 mt-10 lg:mt-0 lg:pl-4'>
                        <div className="pb-0 lg:pb-2">DOWNLOAD OUR FREE APP</div>
                        <div className="pb-2 text-grey">Get access to exclusive training</div>
                        <div className="flex flex-wrap">
                            <div className="w-1/2 lg:w-1/3">
                                <a download={true} href='/downloads/app-release.apk'>
                                    <img className='' src={googlePlay} alt="" />
                                </a>
                            </div>
                            <div className="w-1/2 lg:w-1/3">
                                <img className='ml-2' src={appleStore} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ background: '#000' }} className="text-grey p-10">
                <div className='flex flex-wrap'>
                    <div className='w-full lg:w-1/4 px-4 pb-10 text-left'>
                        <h3 className="font-bold text-md mb-3">Join Our Thriving Community</h3>
                        <span>At LexStudy, we believe that learning is not just an individual journey but a collective experience. Engage with fellow learners, participate in discussions, and exchange ideas in our vibrant community forums. Collaborate with like-minded individuals, network with legal professionals, and expand your horizons through meaningful interactions.</span>
                    </div>
                    <div className='w-1/2 lg:w-1/4 pl-4 lg:pl-10 pb-10'>
                        <div className='text-white font-bold'>Menu</div>
                        <ul>
                            <li className='mt-3'><Link to={'/'}>Home</Link></li>
                            <li className='mt-3'><Link to={'/about'}>About</Link></li>
                            <li className='mt-3'><Link to={'/'}>Events</Link></li>
                            <li className='mt-3'><Link to={'/contact'}>Contact</Link></li>
                        </ul>
                    </div>
                    <div className='w-1/2 lg:w-1/4 pl-4 lg:pl-10 pb-10'>
                        <div className='text-white font-bold'>Quick Links</div>
                        <ul>
                            <li className='mt-3'><Link to={'/'}>Course</Link></li>
                            <li className='mt-3'><Link to={'/forum'}>Forum</Link></li>
                        </ul>
                    </div>
                    <div className='w-full lg:w-1/4 pl-4 lg:pl-10 pb-10'>
                        <div className='text-white font-bold'>Follow Us</div>
                        <div className='flex flex-wrap mt-3'>
                            <div className='w-1/6'>
                                <i className='fab fa-facebook fa-inverse'></i>
                            </div>
                            <div className='w-1/6'>
                                <i className='fab fa-twitter fa-inverse'></i>
                            </div>
                            <div className='w-1/6'>
                                <i className='fab fa-instagram fa-inverse'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;