import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { selectUser, selectToken, logout } from '../app/reducers/user/userSlice';
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import defaultUserIcon from '../assets/default-user-icon.png';
import { storeLogout as logoutCourses } from "../app/reducers/courses/coursesSlice";
// import axios from 'axios';
function AuthNavbar({hideTop}) {
    const location = useLocation();
    const dispatch = useDispatch();
    const {uid} = useParams();
    const navigate = useNavigate();

    const BASE_URL = 'http://lex-study-api.herokuapp.com/api/v1/';
    const _selectToken = JSON.parse(useSelector(selectToken));
    const _token = useMemo(() => _selectToken, [_selectToken]);

    const closeNav = () => {
        document.querySelector('.navbar-close').click();
    }

    const handleLogout = () => {
        dispatch(logout({BASE_URL, token: _token})).then(() => {
            dispatch(logoutCourses());
            navigate('/login', { replace: true });
        })
    }


    const _selectUser = JSON.parse(useSelector(selectUser));
    const _user = useMemo(() => _selectUser, [_selectUser]);
    
    const userIsInstructor = useMemo(() => {
        if (_user) {
          return _user.attributes.role[0] === 'Instructor'
        }
        return false;
    }, [_user]);

    const [viewDropdown, setViewDropdown] = useState(false);

    useEffect(() => {
        // Burger menus
        // open
        const burger = document.querySelectorAll('.navbar-burger');
        const menu = document.querySelectorAll('.navbar-menu');
        if (burger.length && menu.length) {
            for (var i = 0; i < burger.length; i++) {
                burger[i].addEventListener('click', function() {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }
        // close
        const close = document.querySelectorAll('.navbar-close');
        const backdrop = document.querySelectorAll('.navbar-backdrop');
        if (close.length) {
            for (var i = 0; i < close.length; i++) {
                close[i].addEventListener('click', function() {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }
        if (backdrop.length) {
            for (var i = 0; i < backdrop.length; i++) {
                backdrop[i].addEventListener('click', function() {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }
    }, [])

    return (
        <div className="authNavbar">
            <nav className={`drop-shadow-md fixed w-full z-20 lg:px-10 px-4 py-3 flex justify-between items-center bg-white ${hideTop && 'd-none'}`}>
                <Link to="/dashboard" className="text-3xl font-bold leading-none">
                    <img src="/logo-with-bg.png" alt="" className="w-1/2" />
                </Link>
                <div className="opacity-0 flex xl:ml-auto xl:mr-3 sm:pl-6 sm:pr-6 py-3 pl-6 pr-0">
                    <div className="w-1/2">
                        <span className="pointer">
                            <i className="fal fa-bell"></i>
                        </span>
                    </div>
                    <div className="w-1/2 ml-8">
                        <span className="pointer">
                            <i className="fal fa-envelope"></i>
                        </span>
                    </div>
                </div>
                <div className="hidden xl:inline-block">
                    {
                        userIsInstructor
                        ?
                        (
                            location.pathname.indexOf('/dashboard/courses/') !== -1
                            ?
                            (
                                location.pathname !== `/dashboard/courses/${uid}/add` 
                                &&
                                <Link to={`/dashboard/courses/${uid}/add`} className="mr-10">
                                    <button className="auth-orange-button py-2 px-4 text-sm">
                                        <i className="main-text far fa-plus-circle text-sm">
                                        </i> Add Lesson
                                    </button>
                                </Link>
                            )
                            :
                            <Link to="/dashboard/courses/add" className="mr-10">
                                <button className="auth-orange-button py-2 px-4 text-sm">
                                    <i className="main-text far fa-plus-circle text-sm">
                                    </i> Add Course
                                </button>
                            </Link>
                        )
                        :
                        null
                    }
                    {/* <span className="pointer mr-10">
                        <span className="main-text far fa-bell text-xl">
                        </span>
                    </span> */}
                    <span onClick={() => navigate('/dashboard/profile')} className="pointer mr-10">
                        <span className="main-text far fa-cog text-xl">
                        </span>
                    </span>
                </div>
                <div
                    onMouseEnter={() => setViewDropdown(true)}
                    onMouseLeave={() => setViewDropdown(false)} 
                    className="group hidden xl:inline-block">
                    <button
                        className="outline-none focus:outline-none p-0 bg-white rounded-sm flex items-center"
                    >
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <img style={{background: '#EAEEFD', height: 50, width: 50}} className="mx-auto rounded-full p-1" src={_user.attributes.image || defaultUserIcon} alt="default user icon" />
                            </div>
                        </div>
                    </button>
                    <ul className={(viewDropdown ? 'd-block' : 'd-none') + ' bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-[15%] right-10'}>
                        <li className="rounded-sm hover:bg-gray-100 border-b-2 menu-dropdown">
                            <span className="pointer block px-3 py-3" onClick={() => navigate('/dashboard')}>
                                Dashboard
                            </span>
                        </li>
                        {
                            userIsInstructor
                            &&
                            <li className="rounded-sm hover:bg-gray-100 border-b-2 menu-dropdown">
                                <span className="pointer block px-3 py-3" onClick={() => navigate('/dashboard/courses')}>
                                    Courses
                                </span>
                            </li>
                        }
                        {
                            userIsInstructor === false
                            &&
                            <li className="rounded-sm hover:bg-gray-100 border-b-2 menu-dropdown">
                                <span className="pointer block px-3 py-3" onClick={() => navigate('/dashboard/purchases')}>
                                    Purchases
                                </span>
                            </li>
                        }
                        <li className="rounded-sm hover:bg-gray-100 border-b-2 menu-dropdown">
                            <span className="pointer block px-3 py-3" onClick={() => navigate('/dashboard/profile')}>
                                Profile
                            </span>
                        </li>
                        <li className="rounded-sm hover:bg-gray-100 menu-dropdown">
                            <span className="pointer block px-3 py-3" onClick={() => handleLogout()}>
                                <i className="fas fa-power-off self-center text-md"></i> Logout
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="xl:hidden">
                    <button className="navbar-burger flex items-center second-color p-3">
                        <svg style={{opacity: 1}} fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6 block h-4 w-4 fill-current">
                            <title>Mobile menu</title>
                            <path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                            <path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" style={{display: 'none'}}></path>
                        </svg>
                    </button>
                </div>
            </nav>
            <div className="navbar-menu relative z-50 hidden lg:block">
                <div className="xl:hidden navbar-backdrop fixed inset-0 bg-gray-800 opacity-25 xl:opacity-0"></div>
                <nav className="side-nav rounded-tr-lg rounded-br-lg fixed top-0 left-0 bottom-0 flex flex-col xl:w-1/6 w-5/6 max-w-sm pt-6 pb-6 xl:pb-0 bg-white border-r overflow-y-auto">
                    <div className="flex items-center mb-8 px-6">
                        <div className="mr-auto" onClick={() => closeNav()}>
                            <Link to="/dashboard" className="mr-auto text-3xl font-bold leading-none">
                                <img className="w-3/4 rounded-md" src="/logo-with-bg.png" alt="" />    
                            </Link>
                        </div>
                        <button className="navbar-close xl:hidden">
                            <i className="fas fa-times second-color"></i>
                        </button>
                    </div>
                    <div>
                        <div className="mb-6 px-1 mt-4 opacity-0">
                            <span className="main-color pl-5">menu</span>
                        </div>
                        <ul>
                            {
                                userIsInstructor === false
                                &&
                                <li onClick={() => closeNav()} className={' mb-1 px-1'}>
                                    <Link to="/" className="block p-4 text-sm main-text rounded flex flex-wrap">
                                        <span className="w-1/6">
                                            <i className={`${location.pathname === '/' ? 'fas' : 'far'} fa-home`}></i>
                                        </span>
                                        <span className="w-5/6">
                                            Site Home
                                        </span>
                                    </Link>
                                </li>
                            }
                            <li onClick={() => closeNav()} className={(location.pathname === '/dashboard' ? 'active' : '') + ' mb-1 px-1'}>
                                <Link to="/dashboard" className="block p-4 text-sm main-text rounded flex flex-wrap">
                                    <span className="w-1/6">
                                        <i className={`${location.pathname === '/dashboard' ? 'fas' : 'far'} fa-gauge`}></i>
                                    </span>
                                    <span className="w-5/6">
                                        Dashboard
                                    </span>
                                </Link>
                            </li>
                            {
                            userIsInstructor 
                            ? 
                            <li onClick={() => closeNav()} className={(location.pathname === '/dashboard/courses' ? 'active' : '') + ' mb-1 px-1'}>
                                <Link to="/dashboard/courses" className="block p-4 text-sm main-text rounded flex flex-wrap">
                                    <span className="w-1/6">
                                        <i className={`${location.pathname === '/dashboard/courses' ? 'fas' : 'far'} fa-books`}></i>
                                    </span>
                                    <span className="w-5/6">
                                        Courses
                                    </span>
                                </Link>
                            </li>
                            : 
                            <li onClick={() => closeNav()} className={(location.pathname === '/dashboard/purchases' ? 'active' : '') + ' mb-1 px-1'}>
                                <Link to="/dashboard/purchases" className="block p-4 text-sm main-text rounded flex flex-wrap">
                                    <span className="w-1/6">
                                        <i className={`${location.pathname === '/dashboard/purchases' ? 'fas' : 'far'} fa-money-check`}></i>
                                    </span>
                                    <span className="w-5/6">
                                        Purchases
                                    </span>
                                </Link>
                            </li>
                            }
                            <li onClick={() => closeNav()} className={'mb-1 px-1'}>
                                <Link to="/general-forum" className="block p-4 text-sm main-text rounded flex flex-wrap">
                                    <span className="w-1/6">
                                        <i className={`${location.pathname === '/general-forum' ? 'fas' : 'far'} fa-comments`}></i>
                                    </span>
                                    <span className="w-5/6">
                                        General Forum
                                    </span>
                                </Link>
                            </li>
                            <li onClick={() => closeNav()} className={(location.pathname === '/dashboard/profile' ? 'active' : '') + ' mb-1 px-1'}>
                                <Link to="/dashboard/profile" className="block p-4 text-sm main-text rounded flex flex-wrap">
                                    <span className="w-1/6">
                                        <i className={`${location.pathname === '/dashboard/profile' ? 'fas' : 'far'} fa-id-card`}></i>
                                    </span>
                                    <span className="w-5/6">
                                        Profile
                                    </span>
                                </Link>
                            </li>
                            {/* LOGOUT BUTTON ON SIDEBAR */}
                            <li onClick={() => closeNav()} className="mb-1 px-1">
                                <span onClick={() => handleLogout()} className="pointer block p-4 text-sm main-text rounded flex flex-wrap items-center">
                                    <span className="w-1/6">
                                        <i className="far fa-power-off"></i>
                                    </span>
                                    <span className="w-5/6">
                                        Logout
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-auto px-6">
                        <div className="my-4 text-xs text-left main-text flex flex-wrap">
                            <div className="w-full mb-2">
                                <span className="my-4 main-text">ACCOUNT</span>
                            </div>
                            <div className="w-2/7 self-center">
                            <img className="mx-auto rounded-full bg-white" style={{height: 50, width: 50}} src={_user.attributes.image || defaultUserIcon} alt="default user icon" />
                            </div>
                            <div className="w-5/7 pl-2 self-center">
                                <span className="header-text font-bold block">
                                    {
                                        `${_user.attributes.first_name} ${_user.attributes.last_name}`
                                    }
                                </span>
                                <span className="main-text block">
                                    {
                                        userIsInstructor
                                        ?
                                        'Course Instructor'
                                        :
                                        'Student'
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}
 
export default AuthNavbar;


