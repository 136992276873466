import AuthNavbar from "../../../../../components/AuthNavbar";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
function AddCourseLesson({ navigate, _token, BASE_URL, fetchLessons, fetchAllCourses }) {
    const dispatch = useDispatch();
    const { uid } = useParams();

    const [title, setTitle] = useState('');
    const [lessonOrder, setLessonOrder] = useState('1');
    const [quiz, setQuiz] = useState([
        {
            question: '',
            options: [
                '',
                '',
                '',
                ''
            ],
            answer: ''
        }
    ]);
    const [submitText, setSubmitText] = useState('Create');
    const [submitLoading, setSubmitLoading] = useState(false);

    const handleTitleInput = e => setTitle(e.target.value);
    const handleLessonOrderInput = e => setLessonOrder(e.target.value);

    const handleQuestionInput = (e, index) => {
        let newQuiz = [...quiz];
        newQuiz[index].question = e.target.value;
        setQuiz(newQuiz);
    }

    const handleOptionInput = (e, index, optionIndex) => {
        let newQuiz = [...quiz];
        newQuiz[index].options[optionIndex] = e.target.value;
        setQuiz(newQuiz);
    }

    const handleAnswerInput = (e, index) => {
        let newQuiz = [...quiz];
        newQuiz[index].answer = e.target.value;
        setQuiz(newQuiz);
    }

    const addQuestion = () => {
        const newQuiz = [...quiz];
        newQuiz.splice(
            newQuiz.length,
            0,
            {
                question: '',
                options: [
                    '',
                    '',
                    '',
                    ''
                ],
                answer: ''
            }
        );
        setQuiz(newQuiz);
    }

    const removeQuestion = index => {
        const newQuiz = [...quiz];
        newQuiz.splice(
            index,
            1
        );
        setQuiz(newQuiz);
    }

    const createLesson = () => {
        setSubmitText('');
        setSubmitLoading(true);
        const FORMDATA = new FormData();
        FORMDATA.append('course_id', uid);
        FORMDATA.append('title', title);
        FORMDATA.append('lesson_order', lessonOrder);

        quiz.forEach((question, index) => {
            FORMDATA.append(`quiz[${index}][question]`, question.question);
            question.options.forEach((option, optionIndex) => {
                FORMDATA.append(`quiz[${index}][options][${optionIndex}]`, option);
            });
            FORMDATA.append(`quiz[${index}][answer]`, question.answer);
        });

        FORMDATA.append('pdf_note', document.getElementById('pdf_content').files[0]);
        FORMDATA.append('audio_content', document.getElementById('audio_content').files[0]);
        FORMDATA.append('video_content', document.getElementById('video_content').files[0]);
        axios
            .post(`${BASE_URL}courses/add-lesson`, FORMDATA,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${_token}`
                    }
                })
            .then(() => {
                setSubmitLoading(false);
                setSubmitText('Create');
                Swal.fire({
                    title: 'Success',
                    text: 'The lesson was created successfully',
                    icon: 'success',
                    confirmButtonColor: '#002979',
                });
                navigate('/dashboard/courses/' + uid, { replace: true });
            })
            .catch(err => {
                setSubmitLoading(false);
                setSubmitText('Create');
                let errorMsg = err.response.data ? err.response.data.message : null;
                Swal.fire({
                    title: 'Error',
                    text: errorMsg || 'Lesson creation failed',
                    icon: 'error',
                    confirmButtonColor: '#002979',
                });
            })
            .then(() => {
                //
            });
    }

    useEffect(() => {
        dispatch(fetchAllCourses({ BASE_URL, token: _token }));
        dispatch(fetchLessons({ id: uid, BASE_URL, token: _token }));
    });

    useEffect(() => {
        document.title = 'Add Lesson - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthNavbar />
            <div className="pt-16 lg:pt-24 relative z-10 mx-auto">
                <div
                    className="px-0 py-2 xl:ml-72 xl:mr-10 mr-5 ml-5 z-20"
                >
                    <div className="mt-2 mb-16 mr-5">
                        <form className="p-2 lg:p-10">
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <h2 style={{ fontSize: '30px' }}>Create Lesson</h2>
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="course-title">Title </label>
                                </div>
                                <div className="w-full">
                                    <input onChange={handleTitleInput} id="course-title" className="w-full rounded-md  px-3 border py-4" type="text" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="course-title">Lesson Order </label>
                                </div>
                                <div className="w-full">
                                    <input onChange={handleLessonOrderInput} id="course-title" className="w-full rounded-md  px-3 border py-4" type="number" />
                                </div>
                            </div>

                            <div className="w-full text-right">
                                <span className="pointer font-medium text-blue-600 dark:text-blue-500 hover:underline mb-4" onClick={() => addQuestion()}>Add Question +</span>
                            </div>

                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="pdf_content">PDF Content </label>
                                </div>
                                <div className="w-full">
                                    <input id="pdf_content" className="w-full rounded-md  px-3 border py-4 bg-white" type="file" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="audio_content">Audio Content </label>
                                </div>
                                <div className="w-full">
                                    <input id="audio_content" className="w-full rounded-md  px-3 border py-4 bg-white" type="file" />
                                </div>
                            </div>
                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <label htmlFor="video_content">Video Content </label>
                                </div>
                                <div className="w-full">
                                    <input id="video_content" className="w-full rounded-md  px-3 border py-4 bg-white" type="file" />
                                </div>
                            </div>

                            <div className="flex flex-wrap mb-6">
                                <div className="w-full self-center mb-2">
                                    <h2 style={{ fontSize: '30px' }}>Quiz Setup</h2>
                                </div>
                            </div>

                            {
                                quiz.map((q, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-full self-center mb-2">
                                                    <label htmlFor="course-title">Question {index + 1}</label>
                                                    {
                                                        quiz.length >= 2 && <span style={{float: 'right'}} className="pointer font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => removeQuestion()}>Remove Question -</span>
                                                    }
                                                </div>
                                                <div className="w-full">
                                                    <input onChange={(e) => handleQuestionInput(e, index)} id="course-title" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor="course-title">Option 1</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 0)} id="lesson-option-1" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor="course-title">Option 2</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 1)} id="lesson-option-2" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor="course-title">Option 3</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 2)} id="lesson-option-3" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                                <div className="w-1/2 mb-2">
                                                    <label htmlFor="course-title">Option 4</label>
                                                    <input onChange={(e) => handleOptionInput(e, index, 3)} id="lesson-option-4" className="w-full rounded-md  px-3 border py-4" type="text" />
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap mb-6">
                                                <div className="w-full self-center mb-2">
                                                    <label htmlFor="category">Answer </label>
                                                </div>
                                                <div className="w-full">
                                                    <select onChange={(e) => handleAnswerInput(e, index)} id="category" className="w-full rounded-md  px-3 border py-4">
                                                        <option disabled selected>Select answer</option>
                                                        {
                                                            quiz[index].options.map((option, index) => {
                                                                return option.length >= 1 && <option key={'option-' + index} value={option}>{option}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </form>

                        <div className="flex flex-wrap px-2 lg:px-10">
                            <div className="hidden lg:block w-2/3"></div>
                            <div className="w-full lg:w-1/3">
                                <button disabled={submitLoading} onClick={() => !submitLoading && createLesson()} className={'bodyBluish w-full p-4 rounded-xl text-white ' + (submitLoading && 'opacity-50')}>
                                    {
                                        submitLoading
                                            ?
                                            <i className="fas fa-loader fa-spin py-1"></i>
                                            :
                                            submitText
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default AddCourseLesson;