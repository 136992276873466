import { useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import userIcon from "../../../assets/user.png";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useState } from "react";
function Thread({
    navigate, 
    loggedIn, 
    BASE_URL,
    _user,
    _token
}) {
    const dispatch = useDispatch();
    const { threadId } = useParams();

    const [state, setState] = useState({
        thread: {},
        likes: [],
        replies: [],
        loadingEndpoint: true,
        submitting: false,
        reply: ''
    });

    const getThread = () => {
        const FORM_DATA = new FormData();
        FORM_DATA.append('thread_id', threadId);
        
        axios.post(`${BASE_URL}threads/get-thread`, FORM_DATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            const responseReplies = response.data.replies;
            responseReplies.reverse();
            setState({
                ...state,
                thread: response.data.thread,
                likes: response.data.likes,
                replies: responseReplies,
                loadingEndpoint: false
            })
            document.title = response.data.thread.attributes.title + ' - Lexstudy';
        })
        .catch(() => {
            let errorMsg = null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Thread failed to fetch',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
            navigate('/general-forum', { replace: true });
        })
        .then(() => {
            //
        });
    }

    const submitReply = e => {
        e.preventDefault();
        setState({
            ...state,
            submitting: true
        });

        const FORM_DATA = new FormData();
        FORM_DATA.append('thread_id', threadId);
        FORM_DATA.append('body', state.reply);
        
        axios.post(`${BASE_URL}replies/add-reply`, FORM_DATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(() => {
            Swal.fire({
                title: 'Success',
                text: 'Reply added successfully',
                icon: 'success',
                confirmButtonColor: '#002979',
            });
            setState({
                ...state,
                reply: ''
            });
            document.getElementById('reply').value = '';
            getThread();
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.body : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Reply failed to save',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
            setState({
                ...state,
                submitting: false
            });
        })
        .then(() => {
            //
        });
    }

    const likeThread = () => {
        const FORM_DATA = new FormData();
        FORM_DATA.append('thread_id', threadId);
        
        axios.post(`${BASE_URL}threads/like-thread`, FORM_DATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonColor: '#002979',
            });
            getThread();
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.body : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Like failed to save',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            //
        });
    }

    const likeReply = replyId => {
        const FORM_DATA = new FormData();
        FORM_DATA.append('reply_id', replyId);
        
        axios.post(`${BASE_URL}replies/like-reply`, FORM_DATA, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${_token}`
            }
        })
        .then(response => {
            Swal.fire({
                title: 'Success',
                text: response.data.message,
                icon: 'success',
                confirmButtonColor: '#002979',
            });
            getThread();
        })
        .catch(err => {
            let errorMsg = err.response.data ? err.response.data.body : null;
            Swal.fire({
                title: 'Error',
                text: errorMsg || 'Like failed to save',
                icon: 'error',
                confirmButtonColor: '#002979',
            });
        })
        .then(() => {
            //
        });
    }

    const deleteReply = id => {
        Swal.fire({
            title: `Please confirm you want to delete this reply`,
            showCancelButton: true,
            // confirmButtonColor: "#3085d6 !important",
            confirmButtonText: `Delete`,
            confirmButtonColor: '#002979',
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let FORMDATA = new FormData();
                FORMDATA.append('reply_id', id);
                axios.post(`${BASE_URL}replies/delete-reply`, FORMDATA, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${_token}`
                    }
                })
                .then(response => {
                    Swal.fire({
                        title: 'Success',
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: '#002979',
                    });
                    getThread();
                })
                .catch(err => {
                    let errorMsg = err.response.data ? err.response.data.message : null;
                    Swal.fire({
                        title: 'Error',
                        text: errorMsg || 'Reply failed to delete',
                        icon: 'error',
                        confirmButtonColor: '#002979',
                    });
                })
                .then(() => {
                    //
                });
            }
        });
    }

    const handleReplyInput = e => {
        setState({
            ...state,
            reply: e.target.value
        })
    }

    const userHasLiked = likes => {
        return likes.filter(like => like.attributes.user.id == _user.id).length > 0;
    }

    const userHasLikedReply = likes => {
        return likes.filter(like => like.user_id == _user.id).length > 0;
    }

    useEffect(() => {
        getThread();
        document.title = 'Thread - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
        <Navbar loggedIn={loggedIn} />

        <div className="py-6 lg:pt-32" style={{background: '#ffffffa6'}}>
            {
                state.loadingEndpoint
                ?
                // <div className="px-0 py-2 z-20">
                //     <div style={{position: 'relative', height: '100vh'}}>
                //         <i style={{ color: '#002979', position: 'absolute', top: '30%', left: '47%' }} className="far fa-spinner fa-spin fa-4x"></i>
                //     </div>
                // </div>
                <div className="px-0 py-2 z-20">
                    <div>
                        <div className="flex" style={{height: '100vh', alignItems: 'center'}}>
                            <i style={{ color: '#002979' }} className="mx-auto far fa-spinner fa-spin fa-4x"></i>
                        </div>
                    </div>
                </div>
                :
                <div className="max-w-6xl mx-auto sm:px-6 lg:px-8 pt-16 lg:pt-0">
                    <div className="bg-white p-4 rounded-lg shadow-md">
                        <div className="flex items-center mb-4">
                            <img src={state.thread?.attributes.user.image} alt="User Avatar" className="w-10 h-10 rounded-full mr-4" />
                            <div>
                                <h2 className="font-bold text-lg">{state.thread?.attributes.title}</h2>
                                <p className="text-gray-600 text-sm">Posted by {state.thread?.attributes.user.first_name + ' ' + state.thread?.attributes.user.last_name} on {new Date(state.thread?.attributes.created_at).toDateString()}</p>
                            </div>
                        </div>
                        <p className="text-gray-600 mb-4">{state.thread?.attributes.body}</p>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <button onClick={() => likeThread()} className="text-gray-600 mr-2">
                                    {
                                        userHasLiked(state.likes)
                                        ?
                                        <i className="fas fa-thumbs-up"></i>
                                        :
                                        <i className="far fa-thumbs-up"></i>
                                    } {state.likes.length}
                                </button>
                                <button className="text-gray-600"><i className="fas fa-comment-alt"></i> {state.replies.length}</button>
                            </div>
                            {/* <button className="text-gray-600"><i className="fas fa-share-alt"></i> Share</button> */}
                        </div>

                        <ul className="mt-8">
                            {
                                state.replies.map((reply, index) => (
                                    <li key={'reply-'+index} className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
                                        <div className="flex items-center justify-between mb-2">
                                            <div className="flex items-center mb-2">
                                                <img src={reply.attributes.user.image} alt="User Avatar" className="w-8 h-8 rounded-full mr-2" />
                                                <h3 className="font-bold text-lg">{reply.attributes.user.first_name + ' ' + reply.attributes.user.last_name}</h3>
                                            </div>
                                            <div className="flex items-center">
                                                <button onClick={() => likeReply(reply.id)} className="text-gray-600 mr-2">
                                                    {
                                                        userHasLikedReply(reply.attributes.likes)
                                                        ?
                                                        <i className="fas fa-thumbs-up"></i>
                                                        :
                                                        <i className="far fa-thumbs-up"></i>
                                                    } {reply.attributes.likes.length}
                                                </button>
                                                {
                                                    reply.attributes.user.id === _user.id
                                                    &&
                                                    <button onClick={() => deleteReply(reply.id)} className="text-gray-600 mr-2">
                                                        <i className="fas fa-trash"></i>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                        <p className="text-gray-600">{reply.attributes.body}</p>
                                        <p className="text-gray-600 text-sm mt-2">Posted on {new Date(reply.attributes.created_at).toDateString()}</p>
                                    </li>
                                ))
                            }
                        </ul>
                        
                        <form onSubmit={submitReply} className="mt-8">
                            <div className="flex items-center mb-4">
                                <img src={_user.attributes.image} alt="User Avatar" className="w-10 h-10 rounded-full mr-4" />
                                <h3 className="font-bold text-lg">Your Name</h3>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="reply" className="text-gray-600">Add a reply</label>
                                <textarea onChange={handleReplyInput} id="reply" name="reply" className="block w-full h-32 rounded-lg border-2 border-gray-300 p-2 mt-2"></textarea>
                            </div>
                            <button type="submit" className="auth-orange-button text-white font-bold py-2 px-4 w-full md:w-1/6">
                                {
                                    state.submitting
                                    ?
                                    <i className="fas fa-loader fa-spin py-1"></i>
                                    :
                                    <span>Post Reply</span>
                                }
                            </button>
                        </form>

                    </div>
                </div>
            }
        </div>        
        <Footer />
        </>
    );
}

export default Thread;