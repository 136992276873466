import AuthNavbar from "../../../../../components/AuthNavbar";
import { Link } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
function CourseLessons({ _token, _lessons, BASE_URL, _allCourses, fetchAllCourses, fetchLessons }) {
    const dispatch = useDispatch();
    const { uid } = useParams();

    const [axiosInMotion, setAxiosInMotion] = useState(false);
    const [lessonsToDelete, setLessonsToDelete] = useState([]);

    const currentCourse = useMemo(() => {
        const filteredCourse = _allCourses.filter(course => course.id == uid);
        return filteredCourse.length > 0 ? filteredCourse[0] : null;
    })

    const checkAllLessons = allChecked => {
        if (allChecked) {
            setLessonsToDelete([]);
        } else {
            let currentLessonsToDelete = _lessons.map(lesson => {
                return lesson.id
            });
            setLessonsToDelete(currentLessonsToDelete);
        }
    }

    const deleteSelectedLessonsPrompt = () => {
        Swal.fire({
            title: `Please confirm you want to delete the selected lessons?`,
            showCancelButton: true,
            confirmButtonText: `Delete`,
            confirmButtonColor: '#002979',
        })
            .then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setAxiosInMotion(true);
                    deleteSelectedLessons();
                }
            });
    }

    const deleteSelectedLessons = () => {
        lessonsToDelete.forEach((lessonID, index) => {
            deleteLesson(lessonID);
            if ((lessonsToDelete.length - 1) === index) {
                setLessonsToDelete([]);
            }
        });
    }

    const deleteLesson = async courseUID => {
        // let FORMDATA = new FormData();
        // FORMDATA.append('course_id', courseUID);
        // try {
        //     await axios.post(`${BASE_URL}courses/delete`, FORMDATA, {
        //         headers: {
        //             Authorization: `Bearer ${_token}`
        //         }
        //     });
        //     dispatch(fetchAllCourses({ token: _token, BASE_URL }));
        //     Swal.fire(
        //         'Success',
        //         'The course was deleted successfully',
        //         'success'
        //     );
        //     setAxiosInMotion(false);
        // } catch (error) {
        //     let errorMsg = error.response.data ? error.response.data.message : null;
        //     Swal.fire(
        //         'Error',
        //         errorMsg || 'Course deletion failed',
        //         'error'
        //     );
        //     setAxiosInMotion(false);
        // }
    }

    const deleteLessonPrompt = (lessonID, lessonName) => {
        Swal.fire({
            title: `Please confirm you want to delete this lesson - ${lessonName}?`,
            showCancelButton: true,
            // confirmButtonColor: "#3085d6 !important",
            confirmButtonText: `Delete`,
            confirmButtonColor: '#002979',
        })
            .then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    setAxiosInMotion(true);
                    deleteLesson(lessonID);
                }
            });
    }

    const updateLessonsToDelete = lessonID => {
        let currentLessonsToDelete = [...lessonsToDelete], index = currentLessonsToDelete.indexOf(lessonID);
        if (index === -1) {
            currentLessonsToDelete.splice(currentLessonsToDelete.length, 0, lessonID);
        } else {
            currentLessonsToDelete.splice(index, 1);
        }
        setLessonsToDelete(currentLessonsToDelete);
    }

    useEffect(() => {
        dispatch(fetchAllCourses({ BASE_URL, token: _token }));
        dispatch(fetchLessons({ id: uid, BASE_URL, token: _token }));
    });

    useEffect(() => {
        document.title = 'Lessons - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthNavbar />
            <div className="pt-24 relative z-10 mx-auto">
                <div
                    className="px-0 py-2 xl:ml-72 xl:mr-10 mr-5 ml-5 z-20"
                >
                    <div className="flex flex-wrap">
                        <div className="w-1/2 lg:w-full lg:px-10">
                            <h2 className="text-lg lg:text-2xl font-bold mb-2">Your Lessons</h2>
                        </div>
                        <div className="w-1/2 lg:hidden lg:w-full text-right">
                            <Link to="add">
                                <button className="auth-orange-button py-2 px-4 text-sm">
                                    <i className="main-text far fa-plus-circle text-sm">
                                    </i> Add Lesson
                                </button>
                            </Link>
                        </div>
                    </div>
                    {
                        lessonsToDelete.length
                            ?
                            <button onClick={() => !axiosInMotion && deleteSelectedLessonsPrompt()} className="mt-10 lg:mt-0 bg-danger text-white py-2 px-4 mb-2 text-sm">
                                <i className="far fa-trash text-sm">
                                </i> Delete Lessons
                            </button>
                            :
                            null
                    }
                    <div className="my-2 lg:mr-5 relative overflow-x-scroll" style={_lessons.length === 0 ? { height: '80vh' } : null}>
                        {
                            _lessons.length >= 1
                            ?
                            <table className="w-full text-sm text-left">
                                <thead className="text-xs uppercase">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input checked={_lessons.length === lessonsToDelete.length && lessonsToDelete.length > 0} onChange={() => checkAllLessons(_lessons.length === lessonsToDelete.length)} disabled={_lessons.length > 0 === false || _lessons.length === 0} id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Course
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Title
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Lesson Order
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Video Content
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Audio Content
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            PDF Note
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Date Created
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _lessons.length > 0 && _lessons.map((lesson, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className="bg-white border-b">
                                                    <td className="w-4 p-4">
                                                        <div className="flex items-center">
                                                            <input checked={lessonsToDelete.indexOf(lesson.id) !== -1} onChange={() => updateLessonsToDelete(lesson.id)} id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                            <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {
                                                            currentCourse
                                                            ?
                                                            currentCourse.attributes.title
                                                            :
                                                            <i>Course not found</i>
                                                        }
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {lesson.title}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {lesson.lesson_order}
                                                    </td>
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                        <a href={lesson.video_public_id} target="_blank" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                            Course Video
                                                        </a>
                                                    </th>
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                        <a href={lesson.audio_public_id} target="_blank" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                            Course Audio
                                                        </a>
                                                    </th>
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                        <a href={lesson.pdf_note} target="_blank" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                                            PDF Note
                                                        </a>
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        {
                                                            `
                                                            ${new Date(lesson.created_at).toDateString()} 
                                                            ${new Date(lesson.created_at).toLocaleTimeString()}
                                                            `
                                                        }
                                                    </td>
                                                    <td className="px-6 py-4 text-right">
                                                        <span style={axiosInMotion ? { cursor: 'default', opacity: '0.3' } : null} onClick={() => !axiosInMotion && deleteLessonPrompt(lesson.id, lesson.title)} className="pointer font-medium text-red-600 dark:text-red-500 hover:underline">Delete</span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <div className="absolute" style={{
                                top: '50%', 
                                left: '50%', 
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <h3>No lessons added</h3>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </>
    );
}

export default CourseLessons;