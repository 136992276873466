import axios from 'axios';

export async function logUserOut({BASE_URL, token}) {
    // tereg10391@wifame.com/tereg10391@wifame.com - INSTRUCTOR
    // laurettaeriaku@gmail.com/laurettaeriaku@gmail.com - STUDENT
    try {
        const RESPONSE = await axios.post(`${BASE_URL}logout`, null, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return RESPONSE;
    } catch (error) {
        //
    }
}

export async function fetchSubscriptionHistoryAPI({BASE_URL, token}) {
    try {
        const RESPONSE = await axios.get(`${BASE_URL}subscriptions/history`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return RESPONSE;
    } catch (error) {
        if (error.response.status === 401) {
            logUserOut({BASE_URL, token});
        }
    }
}