import axios from 'axios';

export async function fetchLessonsAPI({ BASE_URL, token, id }) {
    try {
        const RESPONSE = await axios.post(`${BASE_URL}courses/course-lessons`, {
            course_id: id
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return RESPONSE;
    } catch (error) {
        //
    }
}