import { useEffect } from "react";
import { useDispatch } from "react-redux";
import AuthNavbar from "../../../components/AuthNavbar";
function StudentPurchases({ 
    BASE_URL, 
    _selectSubscriptionHistory,
    fetchSubscriptionHistory, 
    _token
}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSubscriptionHistory({ token: _token, BASE_URL }));
    });

    useEffect(() => {
        document.title = 'Purchases History - Lexstudy';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthNavbar />
            <div className="pt-24 relative z-10 mx-auto">
                <div
                    className="px-0 py-2 xl:ml-72 xl:mr-10 mr-5 ml-5 z-20"
                >
                    <div className="flex flex-wrap">
                        <div className="w-full lg:px-10">
                            <h2 className="text-lg lg:text-2xl font-bold mb-2">Your Purchases</h2>
                        </div>
                    </div>
                    <div className="my-2 lg:mr-5 relative overflow-x-scroll" style={_selectSubscriptionHistory.length === 0 ? { height: '80vh' } : null}>
                        {
                            _selectSubscriptionHistory.length >= 1
                            ?
                            <table className="w-full text-sm text-left">
                                <thead className="text-xs uppercase">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Instructor
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Course Name
                                        </th>
                                        {/* <th scope="col" className="px-6 py-3">
                                            Description
                                        </th> */}
                                        <th scope="col" className="px-6 py-3">
                                            Actual Price
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Discount Price
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Number of Lessons
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Period of Completion
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Payment Reference
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Expiry Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        _selectSubscriptionHistory.length > 0 && _selectSubscriptionHistory.map((sub, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className="bg-white border-b">
                                                    <td className="px-6 py-4">
                                                        {
                                                            `${sub.attributes.instructor.first_name} ${sub.attributes.instructor.last_name}`
                                                        }
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {sub.attributes.course.title}
                                                    </td>
                                                    {/* <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                        {sub.attributes.course.description}
                                                    </th> */}
                                                    <td className="px-6 py-4">
                                                        {'#' + sub.attributes.course.actual_price}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {
                                                            !!sub.attributes.course.discount_price
                                                                ?
                                                                '#' + sub.attributes.course.discount_price
                                                                :
                                                                (
                                                                    <i>No discount</i>
                                                                )
                                                        }
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {sub.attributes.course.number_of_lessons}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {sub.attributes.course.period_of_completion}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {sub.attributes.payment_ref}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {
                                                            new Date() < new Date(sub.attributes.expires_at)
                                                            ?
                                                            <span className="px-2 py-1 text-xs font-normal text-teal-700 uppercase bg-teal-200 rounded-md dark:text-teal-800 dark:bg-teal-400 bg-opacity-40">ACTIVE</span>
                                                            :
                                                            <span className="px-2 py-1 text-xs font-normal text-red-700 uppercase bg-red-200 rounded-md dark:text-red-800 dark:bg-red-400 bg-opacity-40">INACTIVE</span>
                                                        }
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {
                                                            `
                                                            ${new Date(sub.attributes.expires_at).toDateString()} 
                                                            ${new Date(sub.attributes.expires_at).toLocaleTimeString()}
                                                            `
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <div className="absolute" style={{
                                top: '50%', 
                                left: '50%', 
                                transform: 'translate(-50%, -50%)'
                            }}>
                                <h3>No purchases</h3>
                            </div>
                        }
                        
                    </div>

                </div>
            </div>
        </>
    );
}

export default StudentPurchases;