import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user/userSlice';
import coursesReducer from './reducers/courses/coursesSlice';
import lessonsReducer from './reducers/lessons/lessonsSlice';
import generalThreadsReducer from './reducers/generalForums/threadsSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    courses: coursesReducer,
    lessons: lessonsReducer,
    generalThreads: generalThreadsReducer
  },
});
