import { Link } from "react-router-dom";
function CourseCard({course}) {
    return (
        <div className="bg-white duration-300 hover:bg-white hover:shadow-xl cursor-pointer shadow-md mb-5">
            <div className="inline-flex items-center py-5 px-5 flex-wrap">
                <div style={{
                    height: 300,
                    background: `url(${course.attributes.cover_image})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }} className="block w-full rounded-xl"></div>
                {/* <img style={{height: 300}} alt="testimonial" src={course.attributes.cover_image} className="rounded-xl object-contain object-center w-full sm:w-auto mx-auto" /> */}
                <div className="flex-grow flex flex-wrap px-1 mt-4 sm:text-left sm:w-1/2">
                    <div className='w-1/2 rounded-md py-2 px-2' style={{backgroundColor: '#ffc34485'}}>
                        <span className='text-black'>{course.attributes.title}</span>
                    </div>
                    <div className='w-1/2 self-center text-right'>
                        <span className='text-lg font-bold' style={{color: '#FD661F'}}>₦{course.attributes.actual_price}</span>
                    </div>
                    <div className='w-full py-4'>
                        <p>
                            <span className='font-bold'>{course.attributes.title} | </span>
                            <span>{course.attributes.description}</span>
                        </p>
                    </div>
                    <div className='w-1/2 self-center'>
                        <i style={{color: '#ffc344'}} className="fa-solid fa-circle-dot"></i>
                        <span className='ml-2 text-sm'>2 lessons</span>
                    </div>
                    <div className='w-1/2 self-center'>
                        <i style={{color: '#FD661F'}} className="fa-solid fa-circle-dot"></i>
                        <span className='ml-2 text-sm'>Online class</span>
                    </div>
                    <div className="w-full text-center relative">
                        <svg className="absolute top-2 w-full" width="200" height="2" viewBox="0 0 187 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line y1="0.5" x2="187" y2="0.5" stroke="black"/>
                        </svg>
                    </div>
                    <div className='w-1/2 self-center mt-4'>
                        <i style={{color: '#FD661F'}} className="fa-solid fa-users"></i>
                        <span className='ml-2 text-sm'>{course.attributes.subscribers_count} students</span>
                    </div>
                    <div className='w-1/2 self-center mt-4'>
                        <Link to={`/checkout/${course.id}`}>
                            <div className='text-right'>
                                <i style={{backgroundColor: '#002979'}} className="rounded-full py-4 px-4 text-white fa-solid fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseCard;